import "./Style.css";
import "./Exam.css";
import React, { Component } from "react";
import axios from "axios";
import swal from "sweetalert";
import { toast } from "react-toastify";

import $ from "jquery";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

export default class Post extends Component {
  constructor(props) {
    super(props);
    // this.onChangeDeselect = this.onChangeDeselect.bind(this);
    this.state = {
      loading: true,
      timer: 0,
      qLength: 0,
      examDetails: { instructions: {} },
      questions: [],
      questionNo: 0,
      questionData: [],
      examId: "",
      i: 0,
      currentQuestion: {
        review: false, // Set the default value to false
      },
      newQA: {
        question_number: 0,
        domain: "",
        question: "",
        a: "",
        b: "",
        c: "",
        d: "",
        e: "",
        answer: "",
        review: false,
      },
      newQASet: [],
      time: 0,
      enrollment_id: "",
      answer_set: [],
      pauseSet: [],
      isPaused: false,
      minutes: 0,
      seconds: 0,
      search: null,
      showExplanation: false,
      isPractice: false,
      //isChecked: props.isChecked,
    };
  }

  disableF5(e) {
    if ((e.which || e.keyCode) === 116) e.preventDefault();
    if (e.keyCode === 123) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.keyCode === "E".charCodeAt(0)) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode === "I".charCodeAt(0)) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode === "J".charCodeAt(0)) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.keyCode === "U".charCodeAt(0)) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.keyCode === "S".charCodeAt(0)) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.keyCode === "H".charCodeAt(0)) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.keyCode === "A".charCodeAt(0)) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.keyCode === "F".charCodeAt(0)) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.keyCode === "E".charCodeAt(0)) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.keyCode === "R".charCodeAt(0)) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.keyCode === "V".charCodeAt(0)) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.keyCode === "C".charCodeAt(0)) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.keyCode === "L".charCodeAt(0)) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.keyCode === "T".charCodeAt(0)) {
      e.preventDefault();
    }
    if (e.ctrlKey) {
      e.preventDefault();
    }
    $(document).bind("contextmenu", function (e) {
      e.preventDefault();
    });
  }

  QuestionListNext = () => {
    // alert(this.state.seconds);
    // alert(this.state.minutes);
    this.pauseExamSet(this.state.pauseSet);
    this.setState({ showExplanation: false });
    if (this.state.isPaused) {
      this.setState({ isPaused: false });
    }
    let b = this.state.currentQuestion["question_number"];
    let Length = this.state.newQASet.length;
    if (Length > b) {
      let quest = this.state.newQASet[b];
      this.setState({ currentQuestion: quest });
    }
  };

  QuestionListPrev = () => {
    this.pauseExamSet(this.state.pauseSet);
    this.setState({ showExplanation: false });
    if (this.state.isPaused) {
      this.setState({ isPaused: false });
    }
    let b = this.state.currentQuestion["question_number"] - 2;
    if (b >= 0) {
      let quest = this.state.newQASet[b];
      this.setState({ currentQuestion: quest });
    }
  };

  startExam() {
    this.setState({ isPaused: false });
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  }

  fetchResumeData(enrollmentId) {
    let url = `/api/students/runhist/enrollments/${enrollmentId}`;
    axios({
      method: "GET",
      headers: {
        ContentType: "application/json",
      },
      url: url,
    })
      .then((response) => {
        const savedHistory = response.data.saved_history;
        const attemptedQuestions = savedHistory.answerset;

        // Log the attempted questions to the console
        console.log("Attempted Questions:", attemptedQuestions);

        // Call the new method to update the pauseSet with attempted questions
        this.populatePauseSetWithAttemptedQuestions(attemptedQuestions);
      })
      .catch((error) => {
        console.error(error);
        console.log(error.response.data.errors[0].message);
      });
  }

  populatePauseSetWithAttemptedQuestions(attemptedQuestions, correctAnswers) {
    let previousArr = this.state.pauseSet;

    attemptedQuestions.forEach((attemptedQuestion) => {
      const { question_number, selected_answer, review } = attemptedQuestion;

      let flag = false;
      previousArr.forEach((element) => {
        if (element.question_number === question_number) {
          element.selected_answer = selected_answer; // Update with the attempted answer
          element.review = review;

          // If you have correct answers available, you can compare and update
          const correctAnswer = correctAnswers.find(
            (ca) => ca.question_number === question_number
          );

          if (correctAnswer) {
            element.correct_answer = correctAnswer.answer; // Add correct_answer property

            if (typeof correctAnswer.review !== "undefined") {
              // If the answer is undefined, update it to "none"
              element.review = correctAnswer.review;
            }
          }

          flag = true;
        }
      });

      if (!flag) {
        if (typeof review !== "undefined") {
          // If the answer is undefined, update it to "none"
          let review = review;
        }
        previousArr.push({
          question_number: question_number,
          selected_answer: selected_answer,
          review: review,
        });
      }
    });

    this.setState({ pauseSet: previousArr }, () => {
      console.log("Updated pauseSet with attempted questions:", previousArr);
      // Optionally, call your questionDataChange method to update the UI
      // this.questionDataChange(); // Pass appropriate arguments if needed
    });
    this.setState({ newQASet: previousArr });
    console.log(
      "Updated newQASet with attempted questions:",
      this.state.newQASet
    );
  }

  componentDidMount() {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
    this.checkUser();
    if (this.props.location) {
      let search = new URLSearchParams(this.props.location.search);
      let isPractice = this.props.location.state?.isPractice || false;
      this.setState({ search });
      console.log("isPractice", isPractice);

      //let search = new URLSearchParams(this.props.location.search);
      //console.log("fdsffdf" + search);
      if (search) {
        let enrollment_id = search.get("enrollment");
        let type = search.get("type");

        let urlForCall = "/api/exams/enrollments/" + enrollment_id;
        if (isPractice) {
          urlForCall = "/api/exams/enrollments/" + enrollment_id + "?key=yes";
        }
        if (enrollment_id && type) {
          this.setState({ enrollment_id });
          // Call the new method for type "resume"
          if (type === "resume") {
            this.fetchResumeData(enrollment_id);
          }

          axios({
            method: "GET",
            headers: {
              ContentType: "application/json",
            },
            url: urlForCall,
          })
            .then((response) => {
              this.setState({ loading: false });
              this.setState({ examId: response.data.exam_id });
              this.setState({ examDetails: response.data });
              this.setState({ questions: response.data.exam_questions });
              if (isPractice) {
                if (
                  response.data.answer_keys &&
                  response.data.answer_keys.length > 0
                ) {
                  this.setState({ answers: response.data.answer_keys });
                } else {
                  console.log("No answers found");
                }
              }
              this.setState({ qLength: response.data.exam_questions.length });

              // if (response.data && response.data?.instructions) {
              //   this.setState({ isPaused: true });
              //   $("#exampleModal").modal("show");
              // }

              let q = this.state.questions;
              let a = this.state.answers;
              let QASet = [];
              q.forEach((element, index) => {
                let newQA = {
                  question_number: element.question_number,
                  domain: element.question_domain,
                  question: element.question,
                  a: element.answer_choice["a"],
                  b: element.answer_choice["b"],
                  c: element.answer_choice["c"],
                  d: element.answer_choice["d"],
                  e: element.answer_choice["e"],
                  review: false,
                  explanation:
                    (a && a.length > 0 && a[index] && a[index].reason) ||
                    "No explanation available", // Add explanation value from answers array
                };
                QASet.push(newQA);
              });

              this.setState({ newQASet: QASet });
              //console.log("QASet", QASet);

              this.setState({ currentQuestion: this.state.newQASet[0] });
            })
            .catch((error) => {
              console.error(error);
              console.log(error.response.data.errors[0].message);
            });
        } else if (enrollment_id) {
          this.setState({ enrollment_id });
          axios({
            method: "GET",
            headers: {
              ContentType: "application/json",
            },
            url: urlForCall,
          })
            .then((response) => {
              // if (response.data && response.data?.instructions) {
              //   this.setState({ isPaused: true });
              //   $("#exampleModal").modal("show");
              // }
              this.setState({ loading: false });
              this.setState({ examId: response.data.exam_id });
              this.setState({ examDetails: response.data });
              this.setState({ questions: response.data.exam_questions });
              this.setState({ qLength: response.data.exam_questions.length });
              if (isPractice) {
                if (
                  response.data.answer_keys &&
                  response.data.answer_keys.length > 0
                ) {
                  this.setState({ answers: response.data.answer_keys });
                } else {
                  console.log("No answers found");
                }
              }

              let q = this.state.questions;
              let a = this.state.answers;
              let QASet = [];
              q.forEach((element, index) => {
                let newQA = {};
                newQA["question_number"] = element.question_number;
                newQA["domain"] = element.question_domain;
                newQA["question"] = element.question;
                newQA["a"] = element.answer_choice["a"];
                newQA["b"] = element.answer_choice["b"];
                newQA["c"] = element.answer_choice["c"];
                newQA["d"] = element.answer_choice["d"];
                newQA["e"] = element.answer_choice["e"];
                newQA["review"] = false;
                if (isPractice) {
                  newQA["explanation"] =
                    a[index]?.reason || "No explanation available";
                } // Add explanation value from answers array
                QASet.push(newQA);
              });

              this.setState({ newQASet: QASet });

              this.setState({ currentQuestion: this.state.newQASet[0] });
            })
            .catch((error) => {
              console.error().response;
              console.log(error.response.data.errors[0].message);
            });
        } else {
          console.log("Exam not found.");
        }
      }
    }
  }

  hasAnsweredQuestions() {
    console.log("hasAnsweredQuestions");
    const answeredQuestions = this.state.newQASet.some(
      (question) => question.answer !== undefined && question.answer !== ""
    );
    console.log(answeredQuestions);
    return answeredQuestions;
  }

  checkUser() {
    axios({
      method: "GET",
      url: "/api/users/currentuser",
    })
      .then((response) => {
        if (response.data.current_user === null) {
          this.props.history.push("/signin");
        }
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }
  questionDataChange(field, e) {
    if (this.state.isPaused) {
      this.setState({ isPaused: false });
    }
    let flag = false;
    let arr = this.state.newQASet;
    let i = this.state.currentQuestion["question_number"] - 1;
    arr[i][field] = e.target.value;
    this.setState({ newQASet: arr });
    let q = arr[i]["question_number"];
    let a = arr[i]["answer"];

    let pArr = this.state.pauseSet;
    if (pArr.length > 0) {
      pArr.forEach((element) => {
        if (element.question_number === q) {
          element.selected_answer = a;

          flag = true;
        }
      });
    }
    if (flag === false) {
      let obj = {
        question_number: q,
        selected_answer: a,
      };
      pArr.push(obj);
    }
    this.setState({ pauseSet: pArr });
    // this.pauseExamSet(pArr);
  }

  handleChange() {
    if (this.state.isPaused) {
      this.setState({ isPaused: false });
    }
    let arr = this.state.newQASet;
    let i = this.state.currentQuestion["question_number"] - 1;
    let quest = this.state.currentQuestion["review"];
    if (quest === false) {
      arr[i]["review"] = true;
    } else if (quest === true) {
      arr[i]["review"] = false;
    }
    this.setState({ newQASet: arr });

    let flag = false;
    let q = arr[i]["question_number"];
    let a = arr[i]["answer"];
    let r = arr[i]["review"];
    let pArr = this.state.pauseSet;
    if (pArr.length > 0) {
      pArr.forEach((element) => {
        if (element.question_number === q) {
          element.review = r;
          if (typeof a === "undefined") {
            // If the answer is undefined, update it to "none"
            element.selected_answer = "empty";
          }
          flag = true;
        }
      });
    }
    if (flag === false) {
      if (typeof a === "undefined") {
        // If the answer is undefined, update it to "none"
        a = "empty";
      }
      let obj = {
        question_number: q,
        selected_answer: a,
        review: r,
      };
      pArr.push(obj);
    }
    this.setState({ pauseSet: pArr });
  }

  submitExam() {
    let answer_set = [];
    let set = this.state.newQASet;
    console.log("submitting", set);
    set.forEach((element) => {
      if (element.answer) {
        let object = {
          question_number: element.question_number,
          selected_answer: element.answer,
        };
        answer_set.push(object);
      }
    });
    this.setState({ answer_set });
    let totalSeconds = this.state.examDetails.exam_duration * 60;
    let timeRemaining = this.state.minutes * 60 + this.state.seconds;
    let data = {
      enrollment_id: this.state.enrollment_id,
      run_duration: totalSeconds - timeRemaining,
      answer_set: answer_set,
    };
    axios({
      method: "POST",
      url: "/api/exams/enrollments/valuations",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((response) => {
        sessionStorage.setItem("enrollment_id", response.data.enrollment_id);
        // console.log('Exam Submitted.');
        swal({
          title: "Exam Submitted",
          icon: "success",
          buttons: {
            confirm: {
              text: "OK",
              value: true,
              visible: true,
              className: "",
              closeModal: true,
            },
          },
          dangerMode: false,
        }).then((willDelete) => {
          if (willDelete) {
            this.props.history.push("/exam-review");
          } else {
            this.props.history.push("/exam-review");
          }
        });
      })
      .catch((error) => {
        if (
          error.response.data.errors[0].message === "answer_set is required"
        ) {
          //https://blog.logrocket.com/using-react-toastify-style-toast-messages/
          toast.warning(
            "Whoops, Exam timed out as unattended, back to landing page !",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        }

        this.props.history.push("/studentlanding");
      });
  }

  pauseExamSet(set) {
    let answer_set = set;
    let enrolId = this.state.enrollment_id;
    let data = {
      run_duration: this.state.timer / 60,
      inStudyMode: this.props.location.state?.isPractice || false,
      answer_set: answer_set,
    };
    if (answer_set.length > 0) {
      axios({
        method: "POST",
        url: "/api/students/runhist/enrollments/" + enrolId,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      })
        .then((response) => {
          sessionStorage.setItem("enrollment_id", response.data.enrollment_id);
        })
        .catch((error) => {
          console.log(error.response.data.errors[0].message);
        });
    }
  }

  continueLater() {
    this.pauseExamSet(this.state.pauseSet);
    this.props.history.push("/studentlanding");
    // window.location.reload();
  }

  pauseExam() {
    let answer_set = this.state.pauseSet;
    let enrolId = this.state.enrollment_id;
    let data = {
      run_duration: this.state.timer / 60,
      inStudyMode: this.state.isPractice,
      answer_set: answer_set,
    };
    axios({
      method: "POST",
      url: "/api/students/runhist/enrollments/" + enrolId,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((response) => {
        sessionStorage.setItem("enrollment_id", response.data.enrollment_id);
        this.setState({ isPaused: false });
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  pauseResumeExam(status) {
    this.setState({ isPaused: status });
  }

  // Add a new method to check if any option is selected
  isAnyOptionSelected() {
    const { currentQuestion, pauseSet } = this.state;
    const options = ["a", "b", "c", "d", "e"];

    return (
      options.some(
        (option) =>
          currentQuestion["answer"] === option ||
          pauseSet.find(
            (item) => item.question_number === currentQuestion.question_number
          )?.selected_answer === option
      ) || currentQuestion["answer"] === "none"
    );
  }

  getBubbleClassNameForResume = (questionNumber) => {
    // const { type } = this.props.location.state || {};
    const { pauseSet } = this.state;

    if (this.props.location) {
      let search = new URLSearchParams(this.props.location.search);
      //let isPractice = this.props.location.state?.isPractice || false;

      //console.log("Attempted Questions:", pauseSet);
      //console.log("New Questions:", this.state.newQASet);

      if (search) {
        let type = search.get("type");

        if (type === "resume") {
          const attemptedQuestion = pauseSet.find(
            (pItem) => pItem.question_number === questionNumber
          );

          if (attemptedQuestion) {
            if (
              attemptedQuestion.selected_answer &&
              attemptedQuestion.selected_answer !== "empty" &&
              attemptedQuestion.review === true
            ) {
              // console.log("answeredreview");
              return "answeredreview";
            } else if (
              attemptedQuestion.selected_answer &&
              attemptedQuestion.selected_answer !== "empty" &&
              !attemptedQuestion.review
            ) {
              // console.log("answered");
              return "answered";
            } else if (
              (!attemptedQuestion.answer ||
                attemptedQuestion.selected_answer === "empty") &&
              attemptedQuestion.review
            ) {
              // console.log("notansweredreview");
              return "notansweredreview";
            }
            return "blank";
          }
        }
      }
    }
    // console.log("not catched by resume");
    // If not resuming or question not attempted, use the logic from getBubbleClassName
    return this.getBubbleClassNameForFresh(questionNumber);
  };

  getBubbleClassNameForFresh = (questionNumber) => {
    const currentQuestion = this.state.newQASet.find(
      (item) => item.question_number === questionNumber
    );

    if (currentQuestion) {
      // Check if the question has been reviewed or answered
      if (
        currentQuestion.answer &&
        currentQuestion.selected_answer !== "empty" &&
        currentQuestion.review === true
      ) {
        console.log("answeredreview");
        return "answeredreview";
      } else if (
        currentQuestion.answer &&
        currentQuestion.selected_answer !== "empty" &&
        !currentQuestion.review
      ) {
        console.log("answered");
        return "answered";
      } else if (
        (!currentQuestion.answer ||
          currentQuestion.selected_answer === "empty") &&
        currentQuestion.review
      ) {
        console.log("notansweredreview");
        return "notansweredreview";
      }
    }

    // Default class when none of the conditions are met
    return "blank";
  };

  modal() {
    swal({
      title: "Submit Exam ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Exam Submitted", {
          icon: "success",
        });
        this.submitExam();
      } else {
        console.log("exam submission failed");
      }
    });
  }
  state = {
    username: "",
    password: "",
    isDirty: false,
  };

  buttonText = this.state.showExplanation ? "Hide" : "Show";

  /*  getBubbleClassName = (questionNumber) => {
    const pauseItem = this.state.pauseSet.find(
      (pItem) =>
        pItem.question_number === questionNumber &&
        (pItem.selected_answer ||
          pItem.review === true ||
          pItem.review === false)
    );

    if (pauseItem) {
      const { review, selected_answer } = pauseItem;

      if (review && selected_answer === "none") {
        console.log("Rendering with class: notansweredreview");
        return "notansweredreview";
      } else if (review && selected_answer) {
        console.log("Rendering with class: answeredreview");
        return "answeredreview";
      } else if (selected_answer) {
        console.log("Rendering with class: answered");
        return "answered";
      } else if (!review && selected_answer !== "none") {
        console.log("Rendering with class: blank");
        return "blank"; // Considered as not marked for review and not answered
      }
    } */
  /* else {
      const currentQuestion = this.state.newQASet.find(
        (item) => item.question_number === questionNumber
      );

      if (currentQuestion && currentQuestion.review) {
        console.log("Rendering with class: notansweredreview");
        return "notansweredreview";
      }
      console.log("Rendering with class: blank");
      return "blank";
    } */
  //};

  render() {
    if (this.state.loading) {
      return (
        <Loader
          className="loader text-center"
          type="Puff"
          color="#00BFFF"
          height={500}
          width={100}
          timeout={3000}
        />
      );
    }

    return (
      <div>
        <div className="container  screen-padding border border-gray ">
          <div className="row">
            {this.state.currentQuestion && (
              <p className="col-4 text-left">
                Question {this.state.currentQuestion.question_number} of{" "}
                {this.state.qLength}
              </p>
            )}

            <p className="col-4 text-right">
              Domain:{" "}
              {this.state.currentQuestion && this.state.currentQuestion.domain}
            </p>
          </div>
          <div className="row">
            <div className="question-section col-md-8">
              <div className="question">
                <b
                  dangerouslySetInnerHTML={{
                    __html: this.state.currentQuestion.question,
                  }}
                />
              </div>
              <div>
                <div>
                  <div className="explanationStyle">
                    <p
                      className="explanationTextStyle"
                      dangerouslySetInnerHTML={{
                        __html: this.state.currentQuestion.explanation,
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          className="modal"
          id="exampleModal"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
          data-backdrop="static"
          data-keyboard="false"
        ></div>
      </div>
    );
  }
}
