import React, { Component } from "react";

class ExamCreater extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      shareholders: [{ name: "" }],
    };
  }

  handleNameChange = (evt) => {
    this.setState({ name: evt.target.value });
  };

  handleShareholderNameChange = (idx) => (evt) => {
    const newShareholders = this.state.shareholders.map((shareholder, sidx) => {
      if (idx !== sidx) return shareholder;
      return { ...shareholder, name: evt.target.value };
    });

    this.setState({ shareholders: newShareholders });
  };

  handleSubmit = () => {
    const { name, shareholders } = this.state;
    console.log(
      `Incorporated: ${name} with ${shareholders.length} shareholders`
    );
  };

  handleAddShareholder = () => {
    this.setState({
      shareholders: this.state.shareholders.concat([{ name: "" }]),
    });
  };

  handleRemoveShareholder = (idx) => () => {
    this.setState({
      shareholders: this.state.shareholders.filter((s, sidx) => idx !== sidx),
    });
  };

  render() {
    return (
      <div className="test mt-5">
        <form onSubmit={this.handleSubmit}>
          <input
            type="text"
            placeholder="Question Field"
            value={this.state.name}
            onChange={this.handleNameChange}
          />

          <h4>Options</h4>

          {this.state.shareholders.map((shareholder, idx) => (
            <div key={idx} className="shareholder">
              {/* <input
                            type="text"
                            placeholder={`Shareholder #${idx + 1} name`}
                            value={shareholder.name}
                            onChange={this.handleShareholderNameChange(idx)}
                        /> */}
              <div className="row mt-4">
                <div className="col-xs-6 col-md-1 col-12">
                  <h6>{idx + 1}</h6>
                </div>
                <div className="col-xs-6 col-md-10 col-12">
                  <div>
                    <input
                      type="text"
                      className="form-control"
                      id="colFormLabelSm"
                    />
                  </div>
                </div>
              </div>
              <button
                type="button"
                onClick={this.handleRemoveShareholder(idx)}
                className="small"
              >
                -
              </button>
            </div>
          ))}
          <button
            type="button"
            onClick={this.handleAddShareholder}
            className="small"
          >
            Add Options
          </button>
          <button>Save</button>
        </form>
      </div>
    );
  }
}
export default ExamCreater;
