export default {
  IMG_URL: window.location.origin,
  WEBSITENAME: "ExamArc",
  ERROR_TXT: "Could not perform the request",
  FILE_EXTN: "jpg, JPG, jpeg, JPEG, png, PNG, pdf, docx",
  FILE_SIZE: 1024,
  IMG_EXTNS: ["jpg", "JPG", "jpeg", "JPEG", "PNG", "png"],
  POPULAR_CATEGORIES_HEIGHT: "130px",
  POPULAR_CATEGORIES_WIDTH: "160px",
  POPULAR_INSTRUCTOR_HEIGHT: "150px",
  POPULAR_INSTRUCTOR_HEIGHT_LANDING: "150px",
  SUCCESS_CODE: "200",
  INVALID_CODE: "300",
  FORBIDDEN: 403,
  UNAUTHORIZED: 401,
  INTERNAL_SERVER_ERROR: 500,

  UI_URLS: {
    LOGIN: "/login",
  },

  SERVER_URLS: {
    LOGIN: "login",
    REGISTRATION: "registration",
    FORGOTPASSWORD: "forgot-password",
  },
};
