import React from "react";
import { NavLink } from "react-router-dom";
import InstructorSidebar from "./InstructorSidebar";
import "./Sidebar.css";

function Sidebar() {
  const [loggedInStatus, setLoggedInStatus] = React.useState(false);
  const [usertype, setUserType] = React.useState("student");

  React.useEffect(() => {
    let userData = sessionStorage.getItem("userData");
    if (userData && null != userData) {
      setLoggedInStatus(true);
      let usersType = sessionStorage.getItem("userType");
      setUserType(usersType);
    }
  }, [loggedInStatus]);

  return (
    <>
      {loggedInStatus ? (
        <>
          {/* https://fontawesome.com/v4/icons/ */}
          {usertype === "student" ? (
            <div className="col-lg-2 col-md-2 col-sm-10 col-xs-10 bd-categories text-center d-none d-lg-block">
              <ul className="navbar-nav flex-column">
                <div className="dropdown-divider"></div>

                <li className="nav-item">
                  <NavLink
                    to="/studentlanding"
                    className="btn-block text-decoration-none"
                  >
                    <i className="fa fa fa-dashboard"> </i> Dashboard
                  </NavLink>
                </li>
                <div className="dropdown-divider"></div>
                <li className="nav-item">
                  <NavLink
                    to="/studentprofile"
                    className="btn-block text-decoration-none"
                  >
                    <i className="fa fa-drivers-license"> </i> My Profile
                  </NavLink>
                </li>
                <div className="dropdown-divider"></div>
                <li className="nav-item">
                  <NavLink
                    to="/enrolled-posts"
                    className="btn-block  text-decoration-none"
                  >
                    <i className="fa fa-handshake-o"></i> Premium Reads
                  </NavLink>
                </li>
                <div className="dropdown-divider"></div>
                <li className="nav-item">
                  <NavLink
                    to="/enrolled-exams"
                    className="btn-block  text-decoration-none"
                  >
                    <i className="fa fa-handshake-o"></i> Enrolled Exams
                  </NavLink>
                </li>
                <div className="dropdown-divider"></div>
                <li className="nav-item">
                  <NavLink
                    to="/exams-history"
                    className="btn-block  text-decoration-none"
                  >
                    <i className="fa fa-mortar-board"></i> Exam History
                  </NavLink>
                </li>
                <div className="dropdown-divider"></div>
                <li className="nav-item">
                  <NavLink
                    to="/transactions"
                    className="btn-block text-decoration-none"
                  >
                    <i className="fa fa-rss-square"></i> Subscription
                    {/* <button className="btn btn-block">Subscription</button> */}
                  </NavLink>
                </li>
                <div className="dropdown-divider"></div>
              </ul>
            </div>
          ) : (
            <InstructorSidebar />
          )}
        </>
      ) : (
        <div></div>
      )}

      {/* </div> */}
    </>
  );
}
export default Sidebar;
