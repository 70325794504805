import React, { Component } from "react";
import "./Footer.css";
export default class Policies extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <div className="container">
        <div className="row" id="">
          <h4>About Us</h4>
          <br></br>
          <ul className="footer-li"></ul>
        </div>
        <div className="row" id="AboutUs">
          <br></br>
          <ul className="footer-li">
            <li>
              Founded and managed by 2 techie friends, Examarc aims to become
              one amoung the best guidence source for science and technology
            </li>
            <li>More details to come soon </li>
          </ul>
        </div>
        <div className="row" id="PrivacyPolicy">
          <h4>Privacy Policy</h4>
          <ul className="footer-li">
            <li>
              Access: You may be allowed to access and view the Platform as a
              guest and without creating an account on the Platform or providing
              any Personal Information; Examarc does not validate or takes no
              responsibility towards information, if any, provided by you as a
              guest, except as otherwise required under any law, regulation, or
              an order of competent authority.However, to have access to all the
              features and benefits on our Platform, you are required to first
              create an account on our Platform.To create an account, you are
              required to provide certain Personal Information as may be
              required during the time of registration and all other information
              requested on the registration page, including the ability to
              receive promotional offers from Examarc, is optional.Examarc may,
              in future, include other optional requests for information from
              you to help Examarc to customize the Platform to deliver
              personalized information to you.Examarc may keep records of
              telephone calls or emails received from or made by you for making
              enquiries, feedback, or other purposes for the purpose of
              rendering Services effectively and efficiently
            </li>
            <li>
              Information we collect from You: Basic account information, Public
              profile information, Know Your Customer (&apos;KYC&apos;)
              information, Information when you communicate with us, Information
              related to location etc
            </li>
          </ul>
        </div>
        <div className="row" id="ShippingDelivery">
          <h4>Shipping and Delivery</h4>
          <ul className="footer-li">
            <li>
              Please note that we do not offer any physical delivery products.
              All services and products provided through ExamArc are delivered
              digitally, ensuring instant access to our educational resources
              from anywhere in the world.
            </li>
          </ul>
        </div>
        <div className="row" id="Subscriptions">
          <h4>Subscriptions T&C</h4>
          <ul className="footer-li">
            <li>
              Purchase of a Subscription will allow you access only to the
              content available under the category of content for which you have
              purchased the Subscription.
            </li>
            <li>
              The Services offered, and the validity/term of your Subscription
              (“Subscription Period”)may vary depending on the plan you may
              purchase. Hence, before you proceed to purchase any Subscription,
              please read and understand the details of the Subscription(s) you
              intend to purchase on the Platform. If you are unclear about any
              part of the Subscription offering or need further clarification,
              then please feel free to write to us prior to your purchase at
              email address provided.
            </li>
            <li>
              We may personalize Services and feature them as part of
              Subscriptions, including showing you recommendations on content in
              the subscribed category, and other related categories that might
              be of interest to you. We also endeavor to continuously improve
              the Subscription offerings to improve your Platform experience.
            </li>
            <li>
              Subscription Period may be extended upon renewal of your already
              purchased Subscription. The terms of renewal, if any, can be found
              on our Site and/or Application. However, please note that the
              prices may stand revised from the time of your first purchase of
              the Subscription.
            </li>
            <li>
              The Subscription is of a personal nature and is solely for the
              benefit of the person subscribing and is not allowed to be resold
              by you or transferred to or shared with any other person for
              consideration or otherwise. In the event we get to know that any
              User has resold / transferred / shared Subscription with another
              person, then Examard retains the right to cancel/terminate the
              Subscription forthwith.
            </li>
          </ul>
        </div>
        <div className="row" id="UserGuidelines">
          <h4>User Guidelines</h4>
          <ul className="footer-li">
            <li>
              Impersonation: Impersonating another person is not permitted while
              using the Examarc Platform. In this case impersonation would mean
              the intention to cause confusion regarding who the real person is
              by pretending to be them. This can be done by means of using
              names, image, documents, certificates etc. not belonging to you or
              not used to identify you. Pretending to be a company, institute,
              group etc.by using their logo, brand name or any distinguishing
              mark would also amount to impersonation and could also be a
              potential trademark infringement.
            </li>
            <li>
              Hateful content: We realise that there may be instances when there
              are exchange of ideas and opinions which is essential in the
              learning process, while we agree that individuals have the right
              to voice their opinion, we do not encourage or tolerate any hate
              speech. Hate speech is any content where the sole objective is
              inciting hatred against specific individuals or groups with
              respect to but not limited to race or ethnic origin, country
              caste, religion, disability, gender, age, sexual
              orientation/gender identity etc.
            </li>
            <li>
              Misleading metadata: Misuse of title, description, tags, thumbnail
              and bios and other features which constitutes the Metadata on the
              Platform is not allowed. Using these features to trick or
              circumvent our search algorithms is prohibited
            </li>
            <li>
              Harmful or dangerous content: We at Examarc believe the Platform
              is a safe space for learners and educators alike and would like
              your help in ensuring that it remains so. Keeping this in mind any
              content which incites or promotes violence that may cause physical
              or emotional harm or endanger the safety of any individual is
              expressly prohibited on the Platform. Content that requires
              references to harmful or dangerous acts solely for educational
              purposes are allowed. The sale and promotion of any regulated or
              illegal goods is not allowed. The Platform is to be used only for
              the permitted uses as detailed under the Terms and Conditions.
            </li>
          </ul>
        </div>
        {/* <div className="row" id="Sitemap">
                    <h4>Sitemap</h4>
                    <ul className='footer-li'>
                        <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</li>
                        <li>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of lett</li>
                        <li>Contrary to popular belief, Lorem Ipsum is not simply random text. It ha</li>
                        <li>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s</li>
                        <li>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of lett</li>
                        <li>Contrary to popular belief, Lorem Ipsum is not simply random text. It ha</li>
                    </ul>
                </div> */}
        <div className="row" id="RefundPolicy">
          <h4>Refund Policy</h4>
          <ul className="footer-li">
            <li>
              Please read the subscription terms and conditions carefully before
              subscribing to any of the subscription plans, as once you have
              subscribed you cannot change, cancel your subscription plan. Once
              you subscribe and make the required payment, it shall be final and
              there cannot be any changes or modifications to the same and
              neither will there be any refund.
            </li>
          </ul>
        </div>
        <div className="row" id="LegalNotices">
          <h4>Legal Notices</h4>
          <ul className="footer-li">
            <li>
              We have implemented the following procedures for receiving written
              notification of claimed infringements on the User-generated
              Content uploaded on the Platform. If you believe that your
              intellectual property rights or of any person you are aware of,
              have been used in a way that gives rise to concerns of
              infringement, you may either report such User-generated Content by
              using the report button provided within the Platform or write to
              us at legal@examarc.com with complete details
            </li>
          </ul>
        </div>
      </div>
    );
  }
}
