import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import {
  FaFacebook,
  FaInstagram,
  FaYoutube,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import { Link } from "react-router-dom";
import { HashLink as NavHashLink } from "react-router-hash-link";
import AppConstants from "../../../Constants/AppConstants";
import "./Footer.css";

const FooterPage = () => {
  return (
    <div className="container">
      <footer className="modal-footer font-small pt-4 mt-4 position-sticky">
        <Container>
          <Row>
            <Col md="3" className="social-link-row">
              <div className="col-lg-3">
                <Link to="/" className="header-img">
                  <img
                    src={"/images/examarc_logo.png"}
                    alt="mock-india-header"
                    className="header-img"
                  />
                </Link>
              </div>
              <Row className="link-row">
                <Col>
                  <a
                    href="https://www.facebook.com/examarcpage"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebook />
                  </a>
                </Col>
                <Col>
                  <Link
                    className="social-icon-link"
                    to="/"
                    target="_blank"
                    aria-label="Instagram"
                  >
                    <FaInstagram />
                  </Link>
                </Col>
                <Col>
                  <a
                    href="https://www.youtube.com/channel/UCm9Zaa01_jDUCcTTxmad0hw"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaYoutube />
                  </a>
                </Col>
                <Col>
                  <Link
                    className="social-icon-link"
                    to="/"
                    target="_blank"
                    aria-label="Twitter"
                  >
                    <FaTwitter />
                  </Link>
                </Col>
                <Col>
                  <Link
                    className="social-icon-link"
                    to="/"
                    target="_blank"
                    aria-label="LinkedIn"
                  >
                    <FaLinkedin />
                  </Link>
                </Col>
              </Row>
              <br />
              <Row>
                <Container fluid>
                  &copy; {new Date().getFullYear()} Copyright:{" "}
                  <a href="/">{AppConstants.WEBSITENAME} </a>
                </Container>
              </Row>
            </Col>
            <Col md="3 mt-5">
              <ul>
                <li className="list-unstyled" id="aboutus">
                  <NavHashLink to="/about-us#ShippingDelivery">
                    Shipping and Delivery
                  </NavHashLink>
                </li>
                <li className="list-unstyled">
                  <Link to="/contact-us">Contact Us</Link>
                </li>
                <li className="list-unstyled">
                  <Link to="/terms-conditions">Terms and Conditions</Link>
                </li>
                <li className="list-unstyled">
                  <NavHashLink to="/about-us#Subscriptions">
                    Subscriptions T&C
                  </NavHashLink>
                </li>
              </ul>
            </Col>
            <Col md="3 mt-5">
              <ul>
                <li className="list-unstyled">
                  <NavHashLink to="/about-us#UserGuidelines">
                    User Guidelines
                  </NavHashLink>
                </li>
                <li className="list-unstyled">
                  <NavHashLink to="/about-us#RefundPolicy">
                    Refund Policy
                  </NavHashLink>
                </li>
                <li className="list-unstyled">
                  <NavHashLink to="/about-us#LegalNotices">
                    Legal Notices
                  </NavHashLink>
                </li>
              </ul>
            </Col>
            <Col md="3">
              <p>Coming Soon</p>
              <img
                className="store-image"
                src="images/app.PNG"
                alt="examarc.com"
              />
            </Col>
          </Row>
        </Container>
      </footer>
    </div>
  );
};

export default FooterPage;
