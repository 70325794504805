import axios from "axios";

import React, { Component } from "react";
import AppConstants from "../../../Constants/AppConstants";
import Sidebar from "../../Sidebar";
import "./InstructorsList.css";

class InstructorsList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inst: [],
      instructorDetails: {},
      finalInst: [],
      instEmpty: false,
      isTablet: false,
    };
  }

  componentDidMount() {
    const userAgent = navigator.userAgent.toLowerCase();
    const isTablet =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        userAgent
      );
    this.setState({ isTablet: isTablet });
    this.getPopularInstructors();
  }

  getPopularInstructors() {
    axios({
      method: "GET",
      url: "/api/popular",
    }).then((response) => {
      if (response.status === 200) {
        let data = response.data;
        let array = [];
        let instArray = data.popular.instructors;
        if (instArray === "") {
          this.setState({ instEmpty: true });
        } else {
          instArray.forEach((element) => {
            let id = element["instructor_id"];
            axios({
              method: "GET",
              url: "/api/users/instructors/" + id,
            })
              .then((respond) => {
                if (respond.status === 200) {
                  this.setState({ instructorDetails: respond });
                  let inst = respond.data;
                  let str = inst.bio.slice(0, 200);
                  let object = {
                    user_id: inst.user_id,
                    title: inst.first_name + " " + inst.last_name,
                    // "last_name": inst.last_name,
                    bio: str,
                    profile_image: AppConstants.IMG_URL + inst.profile_image,
                    education: inst.education,
                  };
                  array.push(object);
                  this.setState({ finalInst: array });
                }
              })
              .catch(() => {
                console.log("Unable to fetch all instructors");
              });
          });
        }
      } else {
        console.log("Unable to fetch all instructors");
      }
    });
  }

  render() {
    return (
      <div className="container">
        <br></br>
        {/* <div className="report--header small">
          <h3>My Dashboard</h3>
        </div> */}

        <div className="row">
          <Sidebar />
          <div className="col-lg-10 col-md-12 col-sm-12 col-xs-12 mob-pro ">
            <div className="">
              <div>
                {this.state.instEmpty === false ? (
                  <div className="container">
                    <div className=" mr-4 top-educators-private ">
                      <div className="row">
                        <div className="col-md-9 top-educators-left">
                          <h5 className="font-mob">
                            Prepare under the guidence of Experienced
                            Professionals
                          </h5>
                          <hr></hr>
                          <p className="para-mob">
                            Access to best talents in technology is a
                            subscription away
                          </p>
                        </div>
                      </div>
                      <br />
                    </div>

                    <div className="row">
                      {!this.state.finalInst
                        ? "Loading..."
                        : this.state.finalInst.map((inst) => {
                            return (
                              <div
                                className={
                                  this.state.isTablet
                                    ? "col-4"
                                    : "col-md-3 my-3"
                                }
                                key={inst.user_id}
                              >
                                <div
                                  className="card mt"
                                  href={
                                    "/instructor-page?instructorId=" +
                                    inst.user_id
                                  }
                                >
                                  <a
                                    className="text-decoration-none"
                                    href={
                                      "/instructor-page?instructorId=" +
                                      inst.user_id
                                    }
                                  >
                                    <div className="avatar">
                                      <figure className="image">
                                        <img
                                          src={inst.profile_image}
                                          alt="category"
                                          height={
                                            AppConstants.POPULAR_INSTRUCTOR_HEIGHT_LANDING
                                          }
                                          width={
                                            AppConstants.POPULAR_CATEGORIES_WIDTH
                                          }
                                        />
                                      </figure>
                                    </div>
                                    <div>
                                      <p className="text-dark text-center">
                                        {inst.title}
                                      </p>
                                    </div>
                                  </a>
                                </div>
                              </div>
                            );
                          })}
                    </div>
                  </div>
                ) : (
                  <div></div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InstructorsList;
