import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import { toast } from "react-toastify";
import "./Style.css";

import bcrypt from "bcryptjs";

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registrationDataInstitution: {
        user_type: "institution",
      },
      registrationData: {
        user_type: "instructor",
      },
      errors: {},
      Redirect: false,
      isIndividualTutor: true, // Set to true to make "Individual Tutor" tab active by default
    };
  }

  loginChange(field, e) {
    let registrationData = this.state.registrationData;
    registrationData[field] = e.target.value;
    this.setState({ registrationData });
  }

  loginChangeInstitution(field, e) {
    let registrationDataInstitution = this.state.registrationDataInstitution;
    registrationDataInstitution[field] = e.target.value;
    this.setState({ registrationDataInstitution });
  }

  // Add additional validation rules for each field
  handleValidation() {
    let registrationData = this.state.registrationData;
    let errors = {};
    let formIsValid = true;

    // First name
    if (!registrationData["first_name"]) {
      formIsValid = false;
      errors["first_name"] = "Cannot be empty";
    } else if (registrationData["first_name"].length < 2) {
      formIsValid = false;
      errors["first_name"] = "Must be at least 2 characters long";
    }

    // Last name
    if (!registrationData["last_name"]) {
      formIsValid = false;
      errors["last_name"] = "Cannot be empty";
    } else if (registrationData["last_name"].length < 2) {
      formIsValid = false;
      errors["last_name"] = "Must be at least 2 characters long";
    }

    // Email
    // Validate email addresses
    if (!registrationData["email"]) {
      formIsValid = false;
      errors["email"] = "Cannot be empty";
    } else if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
        registrationData["email"]
      )
    ) {
      formIsValid = false;
      errors["email"] = "Email is not valid";
    }

    // Password
    if (!registrationData["password"]) {
      formIsValid = false;
      errors["password"] = "Cannot be empty";
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()-+]).{6,}$/.test(
        registrationData["password"]
      )
    ) {
      formIsValid = false;
      errors["password"] =
        "Password must be at least 6 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character";
    } else {
      registrationData["password"] = bcrypt.hashSync(
        registrationData["password"],
        "$2a$10$CwTycUXWue0Thq9StjUM0u"
      ); // hash created previously created upon sign up
    }

    // Handle validation errors more gracefully
    if (!formIsValid) {
      // Display the list of validation errors to the user
      // Prevent the user from submitting the form until the errors have been fixed
    }

    this.setState({ errors: errors });

    return formIsValid;
  }

  handleValidationForInstitution() {
    let registrationDataInstitution = this.state.registrationDataInstitution;
    let errors = {};
    let formIsValid = true;

    // First name
    if (!registrationDataInstitution["name"]) {
      formIsValid = false;
      errors["name"] = "Cannot be empty";
    } else if (registrationDataInstitution["name"].length < 2) {
      formIsValid = false;
      errors["name"] = "Must be at least 2 characters long";
    }

    if (!registrationDataInstitution["email"]) {
      formIsValid = false;
      errors["institutionemail"] = "Cannot beeee empty";
    } else if (
      !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
        registrationDataInstitution["email"]
      )
    ) {
      formIsValid = false;
      errors["institutionemail"] = "Email is not valid";
    }

    // Password
    if (!registrationDataInstitution["password"]) {
      formIsValid = false;
      errors["inpassword"] = "Cannot be empty";
    } else if (
      !/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()-+]).{6,}$/.test(
        registrationDataInstitution["password"]
      )
    ) {
      formIsValid = false;
      errors["inpassword"] =
        "Password must be at least 6 characters long and contain at least one uppercase letter, one lowercase letter, one number, and one special character";
    } else {
      registrationDataInstitution["password"] = bcrypt.hashSync(
        registrationDataInstitution["password"],
        "$2a$10$CwTycUXWue0Thq9StjUM0u"
      ); // hash created previously created upon sign up
    }

    // Handle validation errors more gracefully
    if (!formIsValid) {
      // Display the list of validation errors to the user
      // Prevent the user from submitting the form until the errors have been fixed
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  onLoginFormSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidation()) {
      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        url: "/api/users/signup",
        data: this.state.registrationData,
      })
        .then((response) => {
          if (response.status === 201) {
            sessionStorage.setItem("userData", JSON.stringify(response.data));
            this.setState({ Redirect: true });
          }
        })
        .catch((error) => {
          toast.error(error.response.data.errors[0].message);
        });
    }
  };

  onLoginFormSubmitForInstitution = (e) => {
    console.log(this.state.registrationDataInstitution);
    e.preventDefault();
    if (this.handleValidationForInstitution()) {
      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        url: "/api/users/signup",
        data: this.state.registrationDataInstitution,
      })
        .then((response) => {
          if (response.status === 201) {
            sessionStorage.setItem("userData", JSON.stringify(response.data));
            this.setState({ Redirect: true });
          }
        })
        .catch((error) => {
          toast.error(error.response.data.errors[0].message);
        });
    }
  };

  clearError = (field) => {
    let errors = { ...this.state.errors };
    errors[field] = "";
    this.setState({ errors });
  };

  render() {
    if (this.state.Redirect === true) {
      return <Redirect to="/instructor-details" />;
    }
    return (
      <div
        className="regi"
        style={{ backgroundColor: "#f8f9fa", borderRadius: "10px" }}
      >
        <div className="container">
          <h5 className="signin mt-4">Sign Up</h5>
          <br />
          <ul className="nav nav-tabs">
            {/* Individual Tutor tab */}
            <li className="nav-item col-7 text-center">
              <button
                className={`nav-link ${
                  this.state.isIndividualTutor ? "active" : ""
                }`}
                onClick={() => this.setState({ isIndividualTutor: true })}
              >
                Individual Tutor
              </button>
            </li>

            {/* Institution tab */}
            <li className="nav-item col-5 text-center">
              <button
                className={`nav-link ${
                  !this.state.isIndividualTutor ? "active" : ""
                }`}
                onClick={() => this.setState({ isIndividualTutor: false })}
              >
                Institution
              </button>
            </li>
          </ul>

          <form method="post" onSubmit={this.onLoginFormSubmit}>
            {/* Common fields for both options */}

            {/* Fields specific to Individual Tutor */}
            {this.state.isIndividualTutor && (
              <>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    name="first_name"
                    placeholder="Enter Your First Name*"
                    onChange={(e) => {
                      this.loginChange("first_name", e);
                      this.handleValidation("first_name");
                    }}
                    onFocus={() => this.clearError("first_name")} // Add this line to clear error on focus
                    defaultValue={
                      this.state.registrationData["first_name"] || ""
                    }
                  />

                  <span style={{ color: "red" }}>
                    {this.state.errors["first_name"]}
                  </span>
                </div>
                {/* Last Name field */}
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.loginChange.bind(this, "last_name")}
                    onFocus={() => this.clearError("last_name")} // Add this line to clear error on focus
                    defaultValue={
                      this.state.registrationData["last_name"] || ""
                    }
                    name="last_name"
                    placeholder="Enter Your Last Name*"
                  />
                  <span style={{ color: "red" }}>
                    {this.state.errors["last_name"]}
                  </span>
                </div>

                {/* Email field */}
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    aria-describedby="emailHelp"
                    name="email"
                    onChange={this.loginChange.bind(this, "email")}
                    onFocus={() => this.clearError("email")} // Add this line to clear error on focus
                    defaultValue={this.state.registrationData["email"] || ""}
                    placeholder="Enter Your Email*"
                  />
                  <span style={{ color: "red" }}>
                    {this.state.errors["email"]}
                  </span>
                </div>

                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    onChange={this.loginChange.bind(this, "password")}
                    onFocus={() => this.clearError("password")} // Add this line to clear error on focus
                    defaultValue={this.state.registrationData["password"] || ""}
                    name="password"
                    placeholder="Create Password*"
                  />
                  <span style={{ color: "red" }}>
                    {this.state.errors["password"]}
                  </span>
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn--wide btn-sm bg-green"
                    name="create"
                  >
                    Sign up
                  </button>
                  <div className="mt-2">
                    <button
                      style={{
                        textDecoration: "none",
                        border: "none",
                        background: "none",
                        color: "#007bff",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        // Add your navigation logic here
                        // For example, use react-router-dom to navigate to the sign-in page
                        this.props.history.push("/signin");
                      }}
                    >
                      Already have an account? Sign in
                    </button>
                  </div>
                </div>
              </>
            )}
          </form>
          <form method="post" onSubmit={this.onLoginFormSubmitForInstitution}>
            {/* Fields specific to Institution */}
            {!this.state.isIndividualTutor && (
              <>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control"
                    onChange={this.loginChangeInstitution.bind(this, "name")}
                    onFocus={() => this.clearError("name")} // Add this line to clear error on focus
                    defaultValue={
                      this.state.registrationDataInstitution["name"] || ""
                    }
                    name="name"
                    placeholder="Enter Your Institution Name*"
                  />
                  <span style={{ color: "red" }}>
                    {this.state.errors["name"]}
                  </span>
                </div>
                <div className="form-group">
                  <input
                    type="email"
                    className="form-control"
                    aria-describedby="emailHelp"
                    name="institutionemail"
                    onChange={this.loginChangeInstitution.bind(this, "email")}
                    onFocus={() => this.clearError("institutionemail")} // Add this line to clear error on focus
                    defaultValue={
                      this.state.registrationDataInstitution["email"] || ""
                    }
                    placeholder="Enter Institution Email*"
                  />
                  <span style={{ color: "red" }}>
                    {this.state.errors["institutionemail"]}
                  </span>
                </div>
                <div className="form-group">
                  <input
                    type="password"
                    className="form-control"
                    onChange={this.loginChangeInstitution.bind(
                      this,
                      "password"
                    )}
                    onFocus={() => this.clearError("inpassword")} // Add this line to clear error on focus
                    defaultValue={
                      this.state.registrationDataInstitution["password"] || ""
                    }
                    name="inpassword"
                    placeholder="Create Password*"
                  />
                  <span style={{ color: "red" }}>
                    {this.state.errors["inpassword"]}
                  </span>
                </div>
                <div className="text-center">
                  <button
                    type="submit"
                    className="btn btn--wide btn-sm bg-green"
                    name="create"
                  >
                    Sign up
                  </button>
                  <div className="mt-2">
                    <button
                      style={{
                        textDecoration: "none",
                        border: "none",
                        background: "none",
                        color: "#007bff",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        // Add your navigation logic here
                        // For example, use react-router-dom to navigate to the sign-in page
                        this.props.history.push("/signin");
                      }}
                    >
                      Already have an account? Sign in
                    </button>
                  </div>
                </div>
              </>
            )}

            {/*  <div className="text-center">
              <button
                type="submit"
                className="btn btn--wide btn-sm bg-green"
                name="create"
              >
                Sign up
              </button>
              <div className="mt-2">
                <button
                  style={{
                    textDecoration: "none",
                    border: "none",
                    background: "none",
                    color: "#007bff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    // Add your navigation logic here
                    // For example, use react-router-dom to navigate to the sign-in page
                    this.props.history.push("/signin");
                  }}
                >
                  Already have an account? Sign in
                </button>
              </div>
            </div> */}
          </form>
        </div>{" "}
      </div>
    );
  }
}

export default withRouter(SignUp);
