import React from "react";
import "./Navbar.css";
import "./NavbarProfile.css";
import { Component } from "react";
import { Redirect } from "react-router-dom";
import { withRouter, Link } from "react-router-dom";
import axios, { CancelToken } from "axios";

import AppConstants from "../Constants/AppConstants";

class Navbar extends Component {
  constructor() {
    super();
    this.state = {
      domains: [],
      showHide: false,
      showHide1: false,
      userData: sessionStorage.getItem("userData"),
      loginStatus: false,
      Redirect: false,
      isInstructor: false,
      userType: sessionStorage.getItem("userType"),
      switchDetails: {},
      studentRedirect: false,
      instructorRedirect: false,
      domainSelected: "",
      domainChanged: false,
      avtar: "", // Your avatar state
      cancelToken: null, // Add cancelToken to the state
      profileSidebarVisible: false,
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
    this.getDomain();
    let usersType = sessionStorage.getItem("userType");
    this.setState({ userType: usersType });
    if (this.state.userData) {
      let userData = JSON.parse(sessionStorage.getItem("userData"));
      this.setState({ avtar: userData.avtar });
      this.setState({ first_name: userData.first_name });
      let isInstructor = sessionStorage.getItem("isInstructor");
      this.setState({ isInstructor: isInstructor });
      this.setState({ loginStatus: true });
    } else {
      this.setState({ loginStatus: false });
    }
    let domainSelected = sessionStorage.getItem("domainSelected");
    if (domainSelected) {
      this.setState({ domainSelected: domainSelected });
    }
    // Add an event listener to close the dropdown when clicking outside of it
    document.addEventListener("mousedown", this.handleClickOutside);
  }

  componentWillUnmount() {
    this._isMounted = false; // Unset the flag when the component is unmounted
    if (this.state.cancelToken) {
      this.state.cancelToken.cancel("Component unmounted");
    }
    // Remove the event listener when the component is unmounted
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  getDomain() {
    console.log("calling/api/popular?type=domains");
    if (this.state.cancelToken) {
      // If there is an ongoing request, cancel it before making a new one
      this.state.cancelToken.cancel("Request canceled by the user");
    }

    const cancelTokenSource = CancelToken.source();
    this.setState({ cancelToken: cancelTokenSource });
    axios({
      method: "GET",
      url: "/api/popular?type=domains",
      cancelToken: cancelTokenSource.token, // Set the cancel token
    })
      .then((response) => {
        let resp = response.data;
        this.setState({ domains: resp.popular.domains, cancelToken: null });
      })
      .catch((error) => {
        if (axios.isCancel(error)) {
          // Request was canceled, handle this as needed
        } else {
          // Handle other errors
        }
      });
  }

  logOut() {
    sessionStorage.clear();
    document.cookie.split(";").forEach((c) => {
      document.cookie = c
        .replace(/^ +/, "")
        .replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
    });
    axios({
      method: "POST",
      url: "/api/users/signout",
      Headers: {
        "content-type": "application/json",
      },
    })
      .then(() => {
        this.setState({ Redirect: true });
        window.location.reload();
      })
      .catch((error) => {
        // console.log(error.message)
        console.log(error.response.data.errors[0].message);
      });
  }

  switchUser() {
    let usersType = sessionStorage.getItem("userType");
    this.setState({ userType: usersType });
    let userDetails = JSON.parse(sessionStorage.getItem("userData"));
    this.setState({ userData: userDetails });
    let isInstructor = JSON.parse(sessionStorage.getItem("isInstructor"));
    this.setState({ isInstructor: isInstructor });
    if (this.state.userData != null) {
      if (usersType === "student") {
        if (isInstructor === false) {
          sessionStorage.setItem("userType", "instructor");
          sessionStorage.setItem("isSwitching", true);
          this.setState({ showHide: true });
          this.props.history.push("/instructor-details");
          window.location.reload();
        } else {
          sessionStorage.setItem("userType", "instructor");
          this.setState({ showHide: true });
          this.props.history.push("/instructorlanding");
          window.location.reload();
        }
      } else if (usersType === "instructor") {
        sessionStorage.setItem("userType", "student");
        this.setState({ showHide1: true });
        this.props.history.push("/studentlanding");
        window.location.reload();
      }
    } else {
      return <Redirect to={{ pathname: "/signin" }} />;
    }
  }
  modal() {
    this.logOut();
  }

  changeDomain(field, e) {
    this.setState({ domainSelected: e.target.value });
    sessionStorage.setItem("domainSelected", e.target.value);
    if (e.target.value) {
      this.state.domains.forEach((element) => {
        if (e.target.value === element.domain_id) {
          sessionStorage.setItem("domainNameSelected", element.domain_name);
        }
      });
      this.props.history.push({
        pathname: "/domain-wise-categories",
        search: "?search=" + e.target.value,
      });
    }
  }

  editProfile() {
    if (this.state.userType === "student") {
      this.props.history.push("/studentprofile");
    } else {
      let isInstructor = JSON.parse(sessionStorage.getItem("isInstructor"));
      if (isInstructor === false) {
        console.log(
          "Complete instructor profile to access instructor features"
        );
        this.props.history.push("/instructor-details");
      } else {
        this.props.history.push("/instructorprofile");
      }
    }
  }
  home() {
    this.props.history.push("/");
  }

  handleDropdownClick = () => {
    // Toggle the Bootstrap 'show' class on the dropdown menu
    const dropdownMenu = document.getElementById("profileDropdownMenu");
    if (dropdownMenu) {
      dropdownMenu.classList.toggle("m5show");
    }

    // Toggle the dropdown visibility in your state
    this.setState((prevState) => ({
      profileSidebarVisible: !prevState.profileSidebarVisible,
    }));
  };

  handleClickOutside = (event) => {
    // Check if the clicked element is outside the dropdown
    if (
      this.dropdownRef &&
      !this.dropdownRef.contains(event.target) &&
      this.state.profileSidebarVisible
    ) {
      // Close the dropdown
      this.setState({ profileSidebarVisible: false });
    }
  };

  handleMenuItemClick = () => {
    // Close the dropdown explicitly
    this.setState({ profileSidebarVisible: false });
  };

  render() {
    const publicRoutes = [
      "/signin",
      "/signup",
      "/forgotpassword-emailentry",
      "/forgotpassword",
    ]; // Add more as needed
    const isPublicRoute = publicRoutes.includes(location.pathname);
    const { pathname } = this.props.location;
    const isHomePage = pathname === "/";
    console.log(pathname);
    if (this.state.Redirect === true) {
      return <Redirect to={{ pathname: "/" }} />;
    }
    // Check if the user is logged in
    const isLoggedIn = this.state.loginStatus === true;
    //const username = isLoggedIn ? this.state.userData.username : ""; // Replace "username" with the actual user property name

    return (
      <div className="container topnav ">
        <div className="row">
          <div className="col-lg-3">
            <Link to="/" className="header-img">
              <img
                src={"/images/examarc_logo.png"}
                alt="mock-india-header"
                className="header-img"
              />
            </Link>
          </div>
          {!isPublicRoute && (
            <>
              <div className="col-lg-3 d-none d-lg-block">
                <select
                  className="custom-select top-bar-domain mt-3"
                  onChange={this.changeDomain.bind(this, "domain_id")}
                  id="inlineFormCustomSelectPref"
                  value={this.state.domainSelected}
                >
                  <option value="">Select Domain</option>
                  {this.state.domains.map((item, i) => {
                    return (
                      <option
                        key={i}
                        value={item.domain_id}
                        to={
                          "/domain-wise-categories?domainId=" +
                          item.domain_id +
                          "&domain=" +
                          item.domain_name
                        }
                        className="hover_category"
                      >
                        {item.domain_name}
                      </option>
                    );
                  }, this)}
                </select>
              </div>
              {isLoggedIn ? (
                <div className="col-lg-2 d-none d-lg-block">
                  <a
                    className="text-dark text-decoration-none"
                    href="https://guide.examarc.com/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "black", top: "12px" }}
                  >
                    <i className="fa fa-book"></i> Guide Home{" "}
                  </a>
                </div>
              ) : (
                <div className="col-lg-3 d-none d-lg-block">
                  <a
                    className="text-dark text-decoration-none"
                    href="https://guide.examarc.com/"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: "black", top: "12px" }}
                  >
                    <i className="fa fa-book"></i> Guide Home{" "}
                  </a>
                </div>
              )}
            </>
          )}
          {isLoggedIn ? (
            <div className="col-lg-3 d-none d-lg-block text-right">
              <p style={{ padding: "20px 1px" }}>
                Welcome, {this.state.first_name}
              </p>
            </div>
          ) : (
            <></>
          )}
          {/* signin button visible only in public */}
          {!isLoggedIn &&
            (isHomePage ||
              pathname.startsWith("/instructor-page") ||
              pathname.startsWith("/domain-wise-categories")) && (
              <div
                className={
                  "col-lg-3 topnav-right " +
                  (this.state.loginStatus === false ? "m5show" : "m5hidden")
                }
              >
                <Link
                  to="/signin"
                  className="btn-link d-none d-lg-block align-right"
                >
                  <button className="btn btn-sm btn--wide green">
                    Sign In
                  </button>
                </Link>
              </div>
            )}
          <div
            className={this.state.loginStatus === true ? "m5show" : "m5hidden"}
          >
            <div className="topnav-right userprofilemobile dropdown d-none d-lg-block">
              <div
                className="dropdown-toggle"
                id="dropdownMenuLink"
                //data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                onClick={() => this.handleDropdownClick()}
              >
                {this.state.avtar && (
                  <img
                    className="profile-icon"
                    src={AppConstants.IMG_URL + this.state.avtar}
                    alt="Profile"
                  ></img>
                )}
              </div>
              <div
                className={
                  "dropdown-menu dropdown-menu-left " +
                  (this.state.profileSidebarVisible ? "show" : "")
                }
                aria-labelledby="dropdownMenuLink"
                id="profileDropdownMenu"
                ref={(ref) => (this.dropdownRef = ref)}
              >
                <ul className="navbar-nav">
                  <li className="nav-item active">
                    <a
                      className="drp-btn"
                      onClick={() => {
                        this.editProfile();
                        this.handleMenuItemClick();
                      }}
                    >
                      My Profile
                    </a>
                  </li>
                  <div className="dropdown-divider"></div>

                  {this.state.isInstructor === true ? (
                    <a
                      className={
                        "drp-btn " +
                        (this.state.userType === "student"
                          ? "m5show"
                          : "m5hidden")
                      }
                      onClick={() => {
                        this.switchUser();
                        this.handleMenuItemClick();
                      }}
                    >
                      Switch to instructor
                    </a>
                  ) : (
                    <a
                      className={
                        "drp-btn " +
                        (this.state.userType === "student"
                          ? "m5show"
                          : "m5hidden")
                      }
                      onClick={() => {
                        this.switchUser();
                        this.handleMenuItemClick();
                      }}
                    >
                      Switch to instructor
                    </a>
                  )}
                  <a
                    className={
                      "drp-btn " +
                      (this.state.userType === "instructor"
                        ? "m5show"
                        : "m5hidden")
                    }
                    onClick={() => {
                      this.switchUser();
                      this.handleMenuItemClick();
                    }}
                  >
                    Switch to student
                  </a>
                  <li className="nav-item active">
                    <div className="dropdown-divider"></div>
                    <a className="drp-btn " onClick={() => this.modal()}>
                      Log Out
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Left Navbar Temp1_offcanvas-collapse.zip/offcanvas-collapse.html */}
        <nav className="navbar navbar-custom navbar-light bg-light fixed-top d-block d-lg-none">
          <div className="row">
            <div className="col-2 hamburger">
              <button
                className="navbar-toggler"
                type="button"
                data-trigger="#main_nav"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>

            {/* https://stackoverflow.com/questions/43226511/how-can-i-center-an-image-in-bootstrap */}
            <div className="col-7 col-sm-7	col-md-7	col-lg-7	col-xl-7 d-flex justify-content-center ">
              <Link to="/">
                <img
                  src={"/images/examarc_logo.png"}
                  alt="mock-india-header"
                  className="header-img"
                />
              </Link>
            </div>
            {!isLoggedIn &&
              (pathname.startsWith("/instructor-page") ||
                isHomePage ||
                pathname.startsWith("/domain-wise-categories")) && (
                <div
                  className={
                    "col-3 col-sm-3 col-md-3 col-lg-3 col-xl-3 justify-content-end " +
                    (this.state.loginStatus === false ? "m5show" : "m5hidden")
                  }
                >
                  <Link to="/signin" className="btn-link">
                    <button className="btn btn-sm btn--wide green">
                      Sign In
                    </button>
                  </Link>
                </div>
              )}

            <div
              className={
                "col-3 userprofilemobile justify-content-end " +
                (this.state.loginStatus === true ? "m5show" : "m5hidden")
              }
            >
              <div className="topnav-right dropdown">
                <p
                  className="dropdown-toggle"
                  // id="dropdownMenuLink"
                  // data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  data-trigger="#profile-sidebar"
                >
                  {this.state.avtar && (
                    <img
                      className="profile-icon"
                      src={AppConstants.IMG_URL + this.state.avtar}
                      alt="Profile"
                    ></img>
                  )}
                </p>
                <div
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <Link
                    to="/#"
                    className="drp-btn"
                    onClick={() => this.editProfile()}
                  >
                    Profilee
                  </Link>

                  <Link
                    to="/#"
                    className={
                      "drp-btn " +
                      (this.state.userType === "student"
                        ? "m5show"
                        : "m5hidden")
                    }
                    onClick={() => this.switchUser()}
                  >
                    Switch to In
                  </Link>
                  <Link
                    to="/#"
                    className={
                      "drp-btn " +
                      (this.state.userType === "instructor"
                        ? "m5show"
                        : "m5hidden")
                    }
                    onClick={() => this.switchUser()}
                  >
                    Switch to student
                  </Link>
                  <Link
                    to="/#"
                    className="drp-btn "
                    onClick={() => this.modal()}
                  >
                    Log Out
                  </Link>
                </div>
              </div>
            </div>
          </div>

          <div className="navbar-collapse" id="main_nav">
            {/* <div className="offcanvas-header mt-3 hamburger">
              <button className="btn btn-outline-danger btn-close float-right">
                {" "}
                Close{" "}
              </button>
            </div> */}

            <ul className="navbar-nav">
              <li className="nav-item active">
                <br></br>{" "}
                {/* <a className="nav-link" href="#">
                  Home{" "}
                </a>{" "} */}
                <button className="btn btn-sm btn-outline-danger btn-close float-right">
                  {" "}
                  Close{" "}
                </button>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="https://guide.examarc.com/">
                  {" "}
                  Guide Home{" "}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://guide.examarc.com/category/daily-current-affairs/"
                >
                  {" "}
                  Daily Current Affairs{" "}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://guide.examarc.com/category/daily-current-affairs/"
                >
                  {" "}
                  Current Affairs Quiz{" "}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://guide.examarc.com/economics/"
                >
                  {" "}
                  Economics{" "}
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://guide.examarc.com/history/"
                >
                  {" "}
                  History{" "}
                </a>
              </li>
            </ul>
          </div>
          {/* profile sidebar -apears only for tabs, mobiles and small screens*/}
          <div className="profilesidebar-collapse" id="profile-sidebar">
            {/* <div className="offcanvas-header mt-3 hamburger">
              <button className="btn btn-outline-danger btn-close float-right">
                {" "}
                Closee{" "}
              </button>
            </div> */}
            <br></br>

            <ul className="navbar-nav">
              <li className="nav-item active">
                <button className="btn btn-sm btn-outline-danger btn-close float-left">
                  {" "}
                  Close{" "}
                </button>{" "}
                {/* <a className="nav-link" href="#">
                  Home{" "}
                </a>{" "} */}
              </li>

              <div className="dropdown-divider"></div>
              <li className="nav-item">
                <a
                  className="nav-link"
                  href="https://guide.examarc.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i className="fa fa-book"></i> Guide Home{" "}
                </a>
              </li>
              <div className="dropdown-divider"></div>
              <li className="nav-item">
                <a className="nav-link" href="/studentprofile">
                  <i className="fa fa-drivers-license"></i> My Profile
                </a>
              </li>
              <div className="dropdown-divider"></div>
              {this.state.userType === "instructor" ? (
                <>
                  <li className="nav-item">
                    <a className="nav-link" href="/instructorlanding">
                      <i className="fa fa-dashboard"></i> Dashboard
                    </a>
                  </li>
                  <div className="dropdown-divider"></div>
                  <li className="nav-item">
                    <Link
                      to="/#"
                      className="nav-link"
                      onClick={() => this.switchUser()}
                    >
                      <i
                        className="fa fa-graduation-cap"
                        aria-hidden="true"
                      ></i>{" "}
                      Switch to Student
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  <li className="nav-item">
                    <a className="nav-link" href="/studentlanding">
                      <i className="fa fa-dashboard"></i> Dashboard
                    </a>
                  </li>
                  <div className="dropdown-divider"></div>
                  <li className="nav-item">
                    <a className="nav-link" href="/enrolled-exams">
                      <i className="fa fa-handshake-o"></i> Enrolled Exams
                    </a>
                  </li>
                  <div className="dropdown-divider"></div>
                  <li className="nav-item">
                    <a className="nav-link" href="/exams-history">
                      <i className="fa fa-mortar-board"></i> Exam History
                    </a>
                  </li>
                  <div className="dropdown-divider"></div>
                  <li className="nav-item">
                    <a className="nav-link" href="/transactions">
                      <i className="fa fa-rss-square"></i> Subscriptions
                    </a>
                  </li>
                  <li className="nav-item">
                    <div className="dropdown-divider"></div>
                    <Link
                      to="/#"
                      className="nav-link"
                      onClick={() => this.switchUser()}
                    >
                      <i className="fa fa-university" aria-hidden="true"></i>{" "}
                      Switch to Instructor
                    </Link>
                  </li>
                </>
              )}
              <div className="dropdown-divider"></div>
              <li className="nav-item">
                <a className="nav-link" onClick={() => this.modal()} href="/">
                  <i className="fa fa-sign-out"></i> Sign Off
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    );
  }
}
export default withRouter(Navbar);
