import axios from "axios";
import React, { Component } from "react";
import Loader from "react-loader-spinner";
import AppConstants from "../../../Constants/AppConstants";
import Sidebar from "../../Sidebar";
import "./Enrollments.css";
import { Fade } from "react-awesome-reveal";

class EnrollmentPosts extends Component {
  _isMounted = false; // Class property to track mounted state
  source = axios.CancelToken.source(); // Create a cancel token source
  constructor(props) {
    super(props);
    this.state = {
      enrollments: [],
      loading: true,
      exams: [],
    };
  }

  componentDidMount() {
    this._isMounted = true; // Component is now mounted
    this.getEnrollments();
  }

  componentWillUnmount() {
    this._isMounted = false; // Unset the flag when the component is unmounted
    // Cancel any pending Axios requests when the component is unmounted
    this.source.cancel("Component unmounted");
  }

  async getEnrollments() {
    try {
      const response = await axios.get("/api/students/enrollments", {
        cancelToken: this.source.token, // Use the cancel token
      });

      if (this._isMounted) {
        // Ensure that the component is still mounted before updating the state
        const { enrollments } = response.data;

        // Use Promise.all to fetch exam data
        const exams = await Promise.all(
          enrollments.map(async (element) => {
            try {
              const examResponse = await axios.get(
                `/api/active-exams/${element.exam_id}`
              );
              const exam = examResponse.data;

              // Check if exam_desc is "blogpost"
              if (exam.exam_desc === "blogpost") {
                const imgUrl = AppConstants.IMG_URL + exam.exam_image;
                exam.exam_image = imgUrl;
                exam.enrollment_id = element.enrollment_id;

                if (exam.exam_desc.length > 240) {
                  const truncatedDesc = exam.exam_desc.slice(0, 237) + "...";
                  exam.exam_desc = truncatedDesc;
                }

                return exam; // Add the exam if exam_desc is "blogpost"
              } else {
                return null; // Return null for exams where exam_desc is not "blogpost"
              }
            } catch (error) {
              if (axios.isCancel(error)) {
                // Axios request was canceled, no need to handle errors
              } else if (error.response && error.response.status === 404) {
                // Handle 404 error (exam not found) by returning null
                return null;
              } else {
                // Handle other errors
                if (this._isMounted) {
                  console.error("Error fetching enrollments:", error);
                }
              }
            }
          })
        );

        // Check if the component is still mounted
        if (this._isMounted) {
          const filteredExams = exams.filter((exam) => exam !== null); // Filter out null results (failed fetch)
          this.setState({ enrollments, exams: filteredExams, loading: false });
        }
      }
    } catch (error) {
      if (this._isMounted) {
        console.error("Error fetching enrollments:", error);
      }
    }
  }

  studentprofile() {
    this.props.history.push("/studentprofile");
  }

  enrolledexams() {
    this.props.history.push("/enrolled-exams");
  }

  getExam(exam_id) {
    this.props.history.push("/blogpostpage?examId=" + exam_id);
  }

  studentSubscriptions() {
    this.props.history.push("/transactions");
  }

  render() {
    if (this.state.loading) {
      return (
        <Loader
          className="text-center"
          type="Puff"
          color="#00BFFF"
          height={500}
          width={100}
          timeout={5000}
        />
      );
    } else {
      return (
        <div className="container">
          {/* <div className="report--header small">
            <h3>My Enrollments</h3>
          </div> */}
          <br></br>
          <div className="row">
            <Sidebar />
            <div className="conatiner col-lg-10 col-md-10 col-sm-12 col-xs-12">
              <div className="repo-cards-div-main">
                {this.state.exams.map((exam, i) => {
                  return (
                    <div key={i} className="repo-card-div">
                      <Fade bottom duration={2000} distance="40px">
                        <div>
                          <div
                            key={i + "key"}
                            onClick={() => {
                              this.getExam(exam.exam_id);
                            }}
                          >
                            <div className="repo-name-div">
                              <object
                                data="/images/test-icon.svg"
                                width="25"
                                height="25"
                              >
                                {" "}
                              </object>
                              <p className="repo-name"> {exam.exam_title}</p>
                            </div>
                            <p className="repo-description">
                              {/* {exam.exam_subtitle} */}
                              {exam.exam_desc}
                            </p>
                          </div>
                          <div className="repo-details">
                            <p className="repo-creation-date">
                              <li className="author">
                                <a
                                  title="Exam Creator"
                                  href={
                                    "/instructor-page?instructorId=" +
                                    exam.exam_creator_id
                                  }
                                >
                                  {" "}
                                  <object
                                    data="/images/teacher-mono.svg"
                                    width="25"
                                    height="25"
                                  >
                                    {" "}
                                  </object>{" "}
                                  {/* {exam.exam_creator_name.first_name}{" "} */}
                                </a>
                              </li>
                            </p>
                          </div>
                        </div>
                      </Fade>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}

export default EnrollmentPosts;
