import React, { Component } from "react";
import "./Card-style.css";
import axios from "axios";

import AppConstants from "../../Constants/AppConstants";

class PopularInstructors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instArray: [],
      instructorDetails: {},
      instEmpty: false,
      isTablet: false,
    };
  }

  componentDidMount() {
    this.updateIsTablet();
    window.addEventListener("resize", this.updateIsTablet);
    axios({
      method: "GET",
      url: "/api/popular?type=instructors&top=12",
    }).then((response) => {
      if (response.status === 200) {
        let data = response.data;
        let instArray = data.popular.instructors;
        if (instArray.length === 0) {
          this.setState({ instEmpty: true });
        } else {
          this.setState({ instArray: instArray });
        }
      } else {
        console.log("Unable to fetch top 8 instructors");
      }
    });
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateIsTablet);
  }

  updateIsTablet = () => {
    const screenWidth = window.innerWidth;
    const isTablet = screenWidth >= 600 && screenWidth <= 980;
    this.setState({ isTablet });
  };

  render() {
    return (
      <div>
        {this.state.instEmpty === false ? (
          <div className="container">
            <div className=" mr-4 top-educators ">
              <br />
              <div className="row">
                <div className="col-md-9 top-educators-left">
                  <h5 className="font-mob">
                    Prepare under the guidence of Experienced Professionals
                  </h5>
                  <hr></hr>
                  <p className="para-mob">
                    Access to best talents in technology is a subscription away
                  </p>
                </div>
                <div className="col-md-3 top-educators-right">
                  <br />
                  <br />
                  <a
                    className="text-decoration-none"
                    href="/instructors-public"
                  >
                    <p className="card-seeall">View More</p>
                  </a>
                </div>
              </div>
              <br />
            </div>

            <div className="row">
              {this.state.instArray.map((inst) => {
                return (
                  <div
                    className={
                      this.state.isTablet
                        ? "col-6 col-md-4 my-3"
                        : "col-md-2 my-3"
                    }
                    key={inst.instructor_id}
                  >
                    <div
                      className="card mt"
                      href={
                        "/instructor-page?instructorId=" + inst.instructor_id
                      }
                    >
                      <a
                        className="text-decoration-none"
                        href={
                          "/instructor-page?instructorId=" + inst.instructor_id
                        }
                      >
                        <div className="avatar">
                          <figure className="image">
                            <img
                              src={inst.instructor_info.profile_image}
                              alt="category"
                              height={AppConstants.POPULAR_INSTRUCTOR_HEIGHT}
                              width={AppConstants.POPULAR_CATEGORIES_WIDTH}
                            />
                          </figure>
                        </div>
                        <div>
                          <p className="text-dark text-center">
                            {inst.instructor_info.first_name}{" "}
                            {inst.instructor_info.last_name}
                          </p>
                        </div>
                      </a>
                    </div>
                  </div>
                );
              })}
            </div>
            {/* <Carousel responsive={responsive}>
                            {this.state.finalInst.map((inst, index) => {
                                return (

                                    <div className="container-fluid content-row" key={inst.user_id}>
                                        <a className="text-style" href={"/instructor-page?instructorId=" + inst.user_id}>
                                            <div className="card-row">
                                                <div className=" avatar col-md-6 col-lg-6 col-sm-6 col-xs-12 ">
                                                    <img src={inst.profile_image} alt='Instructor' />
                                                    <h6 className='testcenter'>{inst.title}</h6>
                                                </div>
                                                <div className="col-md-6 col-lg-6 col-sm-6 col-xs-6">

                                                    <p className="text-secondary text-style bio-desc">
                                                        {inst.bio}
                                                    </p>
                                                    <p className="text-secondary text-style">
                                                        {inst.education}
                                                    </p>
                                                </div>
                                                <br />
                                                <br />
                                            </div>
                                        </a>
                                    </div>

                                    // <Card user_id={inst.user_id} imgsrc={inst.profile_image} title={inst.title} bio={inst.bio} key={inst.user_id} />
                                )
                            })}
                        </Carousel> */}
          </div>
        ) : (
          <div></div>
        )}
      </div>
    );
  }
}

export default PopularInstructors;
