import "./Style.css";
import "./Exam.css";
import React, { Component } from "react";
import axios from "axios";
import swal from "sweetalert";
import { toast } from "react-toastify";

import $ from "jquery";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";

export default class Exam extends Component {
  constructor(props) {
    super(props);
    // this.onChangeDeselect = this.onChangeDeselect.bind(this);
    this.state = {
      loading: true,
      timer: 0,
      qLength: 0,
      examDetails: { instructions: {} },
      questions: [],
      questionNo: 0,
      questionData: [],
      examId: "",
      i: 0,
      currentQuestion: {
        review: false, // Set the default value to false
      },
      newQA: {
        question_number: 0,
        domain: "",
        question: "",
        a: "",
        b: "",
        c: "",
        d: "",
        e: "",
        answer: "",
        review: false,
      },
      newQASet: [],
      time: 0,
      enrollment_id: "",
      answer_set: [],
      pauseSet: [],
      isPaused: false,
      minutes: 0,
      seconds: 0,
      search: null,
      showExplanation: false,
      isPractice: false,
      //isChecked: props.isChecked,
    };
    // $(document).on("keydown", this.disableF5);
    // window.addEventListener('beforeunload', function (e) {
    //     e.preventDefault();
    //     e.returnValue = '';
    // });
    this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
  }

  disableF5(e) {
    if ((e.which || e.keyCode) === 116) e.preventDefault();
    if (e.keyCode === 123) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.keyCode === "E".charCodeAt(0)) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode === "I".charCodeAt(0)) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.shiftKey && e.keyCode === "J".charCodeAt(0)) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.keyCode === "U".charCodeAt(0)) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.keyCode === "S".charCodeAt(0)) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.keyCode === "H".charCodeAt(0)) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.keyCode === "A".charCodeAt(0)) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.keyCode === "F".charCodeAt(0)) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.keyCode === "E".charCodeAt(0)) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.keyCode === "R".charCodeAt(0)) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.keyCode === "V".charCodeAt(0)) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.keyCode === "C".charCodeAt(0)) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.keyCode === "L".charCodeAt(0)) {
      e.preventDefault();
    }
    if (e.ctrlKey && e.keyCode === "T".charCodeAt(0)) {
      e.preventDefault();
    }
    if (e.ctrlKey) {
      e.preventDefault();
    }
    $(document).bind("contextmenu", function (e) {
      e.preventDefault();
    });
  }

  QuestionListNext = () => {
    // alert(this.state.seconds);
    // alert(this.state.minutes);
    this.pauseExamSet(this.state.pauseSet);
    this.setState({ showExplanation: false });
    if (this.state.isPaused) {
      this.setState({ isPaused: false });
    }
    let b = this.state.currentQuestion["question_number"];
    let Length = this.state.newQASet.length;
    if (Length > b) {
      let quest = this.state.newQASet[b];
      this.setState({ currentQuestion: quest });
    }
  };

  QuestionListPrev = () => {
    this.pauseExamSet(this.state.pauseSet);
    this.setState({ showExplanation: false });
    if (this.state.isPaused) {
      this.setState({ isPaused: false });
    }
    let b = this.state.currentQuestion["question_number"] - 2;
    if (b >= 0) {
      let quest = this.state.newQASet[b];
      this.setState({ currentQuestion: quest });
    }
  };

  startExam() {
    this.setState({ isPaused: false });
  }

  componentWillUnmount() {
    clearInterval(this.myInterval);
    window.removeEventListener("beforeunload", this.handleBeforeUnload);
  }

  fetchResumeData(enrollmentId) {
    let url = `/api/students/runhist/enrollments/${enrollmentId}`;
    axios({
      method: "GET",
      headers: {
        ContentType: "application/json",
      },
      url: url,
    })
      .then((response) => {
        const savedHistory = response.data.saved_history;
        const attemptedQuestions = savedHistory.answerset;

        // Log the attempted questions to the console
        console.log("Attempted Questions:", attemptedQuestions);

        // Call the new method to update the pauseSet with attempted questions
        this.populatePauseSetWithAttemptedQuestions(attemptedQuestions);
      })
      .catch((error) => {
        console.error(error);
        console.log(error.response.data.errors[0].message);
      });
  }

  populatePauseSetWithAttemptedQuestions(attemptedQuestions, correctAnswers) {
    let previousArr = this.state.pauseSet;

    attemptedQuestions.forEach((attemptedQuestion) => {
      const { question_number, selected_answer, review } = attemptedQuestion;

      let flag = false;
      previousArr.forEach((element) => {
        if (element.question_number === question_number) {
          element.selected_answer = selected_answer; // Update with the attempted answer
          element.review = review;

          // If you have correct answers available, you can compare and update
          const correctAnswer = correctAnswers.find(
            (ca) => ca.question_number === question_number
          );

          if (correctAnswer) {
            element.correct_answer = correctAnswer.answer; // Add correct_answer property

            if (typeof correctAnswer.review !== "undefined") {
              // If the answer is undefined, update it to "none"
              element.review = correctAnswer.review;
            }
          }

          flag = true;
        }
      });

      if (!flag) {
        if (typeof review !== "undefined") {
          // If the answer is undefined, update it to "none"
          let review = review;
        }
        previousArr.push({
          question_number: question_number,
          selected_answer: selected_answer,
          review: review,
        });
      }
    });

    this.setState({ pauseSet: previousArr }, () => {
      console.log("Updated pauseSet with attempted questions:", previousArr);
      // Optionally, call your questionDataChange method to update the UI
      // this.questionDataChange(); // Pass appropriate arguments if needed
    });
    this.setState({ newQASet: previousArr });
    console.log(
      "Updated newQASet with attempted questions:",
      this.state.newQASet
    );
  }

  componentDidMount() {
    document.addEventListener("contextmenu", (e) => {
      e.preventDefault();
    });
    this.checkUser();
    if (this.props.location) {
      let search = new URLSearchParams(this.props.location.search);
      let isPractice = this.props.location.state?.isPractice || false;
      this.setState({ search });
      console.log("isPractice", isPractice);

      //let search = new URLSearchParams(this.props.location.search);
      //console.log("fdsffdf" + search);
      if (search) {
        let enrollment_id = search.get("enrollment");
        let type = search.get("type");

        let urlForCall = "/api/exams/enrollments/" + enrollment_id;
        if (isPractice) {
          urlForCall = "/api/exams/enrollments/" + enrollment_id + "?key=yes";
        }
        if (enrollment_id && type) {
          this.setState({ enrollment_id });
          // Call the new method for type "resume"
          if (type === "resume") {
            this.fetchResumeData(enrollment_id);
          }

          axios({
            method: "GET",
            headers: {
              ContentType: "application/json",
            },
            url: urlForCall,
          })
            .then((response) => {
              this.setState({ loading: false });
              this.setState({ examId: response.data.exam_id });
              this.setState({ examDetails: response.data });
              this.setState({ questions: response.data.exam_questions });
              if (isPractice) {
                if (
                  response.data.answer_keys &&
                  response.data.answer_keys.length > 0
                ) {
                  this.setState({ answers: response.data.answer_keys });
                } else {
                  console.log("No answers found");
                }
              }
              this.setState({ qLength: response.data.exam_questions.length });

              // if (response.data && response.data?.instructions) {
              //   this.setState({ isPaused: true });
              //   $("#exampleModal").modal("show");
              // }

              let q = this.state.questions;
              let a = this.state.answers;
              let QASet = [];
              q.forEach((element, index) => {
                let newQA = {
                  question_number: element.question_number,
                  domain: element.question_domain,
                  question: element.question,
                  a: element.answer_choice["a"],
                  b: element.answer_choice["b"],
                  c: element.answer_choice["c"],
                  d: element.answer_choice["d"],
                  e: element.answer_choice["e"],
                  review: false,
                  explanation:
                    (a && a.length > 0 && a[index] && a[index].reason) ||
                    "No explanation available", // Add explanation value from answers array
                };
                QASet.push(newQA);
              });

              let exam = this.state.examDetails;
              let time = exam.exam_duration;
              this.setState({ minutes: time });
              this.myInterval = setInterval(() => {
                const { seconds, minutes } = this.state;
                console.log(this.state.isPaused);
                if (!this.state.isPaused) {
                  if (seconds > 0) {
                    this.setState(({ seconds }) => ({
                      seconds: seconds - 1,
                    }));
                  }
                  if (seconds === 0) {
                    if (minutes === 0) {
                      clearInterval(this.myInterval);
                    } else {
                      this.setState(({ minutes }) => ({
                        minutes: minutes - 1,
                        seconds: 59,
                      }));
                    }
                  }
                  if (seconds === 0 && minutes === 0) {
                    this.submitExam();
                  }
                }
              }, 1000);

              let seconds = time * 60;
              this.setState({ timer: seconds });
              this.setState({ newQASet: QASet });
              //console.log("QASet", QASet);

              this.setState({ currentQuestion: this.state.newQASet[0] });
            })
            .catch((error) => {
              console.error(error);
              console.log(error.response.data.errors[0].message);
            });
        } else if (enrollment_id) {
          this.setState({ enrollment_id });
          axios({
            method: "GET",
            headers: {
              ContentType: "application/json",
            },
            url: urlForCall,
          })
            .then((response) => {
              // if (response.data && response.data?.instructions) {
              //   this.setState({ isPaused: true });
              //   $("#exampleModal").modal("show");
              // }
              this.setState({ loading: false });
              this.setState({ examId: response.data.exam_id });
              this.setState({ examDetails: response.data });
              this.setState({ questions: response.data.exam_questions });
              this.setState({ qLength: response.data.exam_questions.length });
              if (isPractice) {
                if (
                  response.data.answer_keys &&
                  response.data.answer_keys.length > 0
                ) {
                  this.setState({ answers: response.data.answer_keys });
                } else {
                  console.log("No answers found");
                }
              }

              let q = this.state.questions;
              let a = this.state.answers;
              let QASet = [];
              q.forEach((element, index) => {
                let newQA = {};
                newQA["question_number"] = element.question_number;
                newQA["domain"] = element.question_domain;
                newQA["question"] = element.question;
                newQA["a"] = element.answer_choice["a"];
                newQA["b"] = element.answer_choice["b"];
                newQA["c"] = element.answer_choice["c"];
                newQA["d"] = element.answer_choice["d"];
                newQA["e"] = element.answer_choice["e"];
                newQA["review"] = false;
                if (isPractice) {
                  newQA["explanation"] =
                    a[index]?.reason || "No explanation available";
                } // Add explanation value from answers array
                QASet.push(newQA);
              });

              let exam = this.state.examDetails;
              let time = exam.exam_duration;
              this.setState({ minutes: time });
              this.myInterval = setInterval(() => {
                const { seconds, minutes } = this.state;
                if (!this.state.isPaused) {
                  if (seconds > 0) {
                    this.setState(({ seconds }) => ({
                      seconds: seconds - 1,
                    }));
                  }
                  if (seconds === 0) {
                    if (minutes === 0) {
                      clearInterval(this.myInterval);
                    } else {
                      this.setState(({ minutes }) => ({
                        minutes: minutes - 1,
                        seconds: 59,
                      }));
                    }
                    if (seconds === 0 && minutes === 0) {
                      this.submitExam();
                    }
                  }
                }
              }, 1000);
              let seconds = time * 60;
              this.setState({ timer: seconds });
              this.setState({ newQASet: QASet });

              this.setState({ currentQuestion: this.state.newQASet[0] });
            })
            .catch((error) => {
              console.error().response;
              console.log(error.response.data.errors[0].message);
            });
        } else {
          console.log("Exam not found.");
        }
      }
    }
  }

  handleBeforeUnload(event) {
    // Check if the user has answered any questions
    if (this.hasAnsweredQuestions()) {
      const message = "Do you want to save the exam attended till now?";
      // Standard for most browsers
      event.returnValue = message;
      // For some older browsers
      return message;
    }
  }

  hasAnsweredQuestions() {
    console.log("hasAnsweredQuestions");
    const answeredQuestions = this.state.newQASet.some(
      (question) => question.answer !== undefined && question.answer !== ""
    );
    console.log(answeredQuestions);
    return answeredQuestions;
  }

  checkUser() {
    axios({
      method: "GET",
      url: "/api/users/currentuser",
    })
      .then((response) => {
        if (response.data.current_user === null) {
          this.props.history.push("/signin");
        }
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }
  questionDataChange(field, e) {
    if (this.state.isPaused) {
      this.setState({ isPaused: false });
    }
    let flag = false;
    let arr = this.state.newQASet;
    let i = this.state.currentQuestion["question_number"] - 1;
    arr[i][field] = e.target.value;
    this.setState({ newQASet: arr });
    let q = arr[i]["question_number"];
    let a = arr[i]["answer"];

    let pArr = this.state.pauseSet;
    if (pArr.length > 0) {
      pArr.forEach((element) => {
        if (element.question_number === q) {
          element.selected_answer = a;

          flag = true;
        }
      });
    }
    if (flag === false) {
      let obj = {
        question_number: q,
        selected_answer: a,
      };
      pArr.push(obj);
    }
    this.setState({ pauseSet: pArr });
    // this.pauseExamSet(pArr);
  }

  /* questionDataNewChange = (field, e) => {
    console.log("field", field);
    console.log("e", e.target.value);
    if (this.state.isPaused) {
      this.setState({ isPaused: false });
    }

    let arr = this.state.newQASet;
    console.log("this.state.newQASet", this.state.newQASet);
    let i = this.state.currentQuestion["question_number"] - 1;

    // Check if the selected answer is "none"
    if (e.target.value === "none") {
      arr[i][field] = "none";
    } else {
      // Handle the case when the selected answer is not "none"
      arr[i][field] = e.target.value;
    }

    // Check if the selected answer is "none" and remove it from pauseSet
    if (e.target.value === "none" && arr[i].review === false) {
      let pArr = this.state.pauseSet.filter(
        (element) => element.question_number !== arr[i]["question_number"]
      );
      this.setState({ pauseSet: pArr });
      console.log("pauseSet - item removed", pArr);
      console.log("arr[i].review", arr[i].review);
    } else {
      // Handle the case when the selected answer is not "none"
      let q = arr[i]["question_number"];
      let r = arr[i]["review"];
      let a = arr[i]["answer"];
      let pArr = this.state.pauseSet;
      let flag = false;

      // Check if there is a previous entry for this question in pauseSet
      const existingEntryIndex = pArr.findIndex(
        (element) => element.question_number === q
      );

      if (existingEntryIndex !== -1) {
        // Update the existing entry
        pArr[existingEntryIndex].selected_answer = a;
        pArr[existingEntryIndex].review = r;
        flag = true;
      }

      if (!flag) {
        // Create a new entry if none exists
        let obj = {
          question_number: q,
          selected_answer: a,
          review: r,
        };
        pArr.push(obj);
      }

      this.setState({ pauseSet: pArr });
      console.log("pauseSet - item added", pArr);
    }
  }; */

  handleChange() {
    if (this.state.isPaused) {
      this.setState({ isPaused: false });
    }
    let arr = this.state.newQASet;
    let i = this.state.currentQuestion["question_number"] - 1;
    let quest = this.state.currentQuestion["review"];
    if (quest === false) {
      arr[i]["review"] = true;
    } else if (quest === true) {
      arr[i]["review"] = false;
    }
    this.setState({ newQASet: arr });

    let flag = false;
    let q = arr[i]["question_number"];
    let a = arr[i]["answer"];
    let r = arr[i]["review"];
    let pArr = this.state.pauseSet;
    if (pArr.length > 0) {
      pArr.forEach((element) => {
        if (element.question_number === q) {
          element.review = r;
          if (typeof a === "undefined") {
            // If the answer is undefined, update it to "none"
            element.selected_answer = "empty";
          }
          flag = true;
        }
      });
    }
    if (flag === false) {
      if (typeof a === "undefined") {
        // If the answer is undefined, update it to "none"
        a = "empty";
      }
      let obj = {
        question_number: q,
        selected_answer: a,
        review: r,
      };
      pArr.push(obj);
    }
    this.setState({ pauseSet: pArr });
  }

  /* handleReviewChange() {
    if (this.state.isPaused) {
      this.setState({ isPaused: false });
    }

    let arr = this.state.newQASet;
    let i = this.state.currentQuestion["question_number"] - 1;
    let quest = arr[i]["review"];
    console.log("VALUE of CHECKBOX before toggle", arr[i]["review"]);

    // Toggle the "Mark for Review" status
    arr[i]["review"] = !quest;

    // Check if the "Deselect" radio button is selected
    /*  if (this.state.selectedAnswerForDeselect) {
    arr[i]["answer"] = "none"; // Set the answer to "none" only when deselecting
  } */
  /*
    // Update the state using the callback function to ensure the correct state transition
    this.setState((prevState) => ({ newQASet: [...prevState.newQASet] }));

    // Update the pauseSet based on "Mark for Review" status
    let pArr = this.state.pauseSet;
    let q = arr[i]["question_number"];
    let a = arr[i]["answer"];

    if (typeof a === "undefined") {
      // If the answer is undefined, update it to "none"
      a = "empty";
    }

    let r = arr[i]["review"];
    let existingEntryIndex = pArr.findIndex(
      (element) => element.question_number === q
    );

    if (r) {
      // If marked for review, add or update the entry in pauseSet
      if (existingEntryIndex === -1) {
        pArr.push({
          question_number: q,
          selected_answer: a,
          review: r,
        });
      }
    } else {
      // If unmarked, remove the entry from pauseSet
      if (existingEntryIndex !== -1) {
        pArr.splice(existingEntryIndex, 1);
      }
    }

    this.setState({
      newQASet: arr,
      pauseSet: pArr,
      selectedAnswerForDeselect: false,
    });
    console.log("pauseSet - item added from review", pArr);
    console.log("VALUE of CHECKBOX after toggle", arr[i]["review"]);
  } */

  /* onChangeDeselect = (event) => {
    console.log("HELLO CALLING DESELECT");
    this.setState({ selectedAnswerForDeselect: true });
    this.questionDataChange("answer", event);
  }; */

  submitExam() {
    let answer_set = [];
    let set = this.state.newQASet;
    console.log("submitting", set);
    set.forEach((element) => {
      if (element.answer) {
        let object = {
          question_number: element.question_number,
          selected_answer: element.answer,
        };
        answer_set.push(object);
      }
    });
    this.setState({ answer_set });
    let totalSeconds = this.state.examDetails.exam_duration * 60;
    let timeRemaining = this.state.minutes * 60 + this.state.seconds;
    let data = {
      enrollment_id: this.state.enrollment_id,
      run_duration: totalSeconds - timeRemaining,
      answer_set: answer_set,
    };
    axios({
      method: "POST",
      url: "/api/exams/enrollments/valuations",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((response) => {
        sessionStorage.setItem("enrollment_id", response.data.enrollment_id);
        // console.log('Exam Submitted.');
        swal({
          title: "Exam Submitted",
          icon: "success",
          buttons: {
            confirm: {
              text: "OK",
              value: true,
              visible: true,
              className: "",
              closeModal: true,
            },
          },
          dangerMode: false,
        }).then((willDelete) => {
          if (willDelete) {
            this.props.history.push("/exam-review");
          } else {
            this.props.history.push("/exam-review");
          }
        });
      })
      .catch((error) => {
        if (
          error.response.data.errors[0].message === "answer_set is required"
        ) {
          //https://blog.logrocket.com/using-react-toastify-style-toast-messages/
          toast.warning(
            "Whoops, Exam timed out as unattended, back to landing page !",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        }

        this.props.history.push("/studentlanding");
      });
  }

  pauseExamSet(set) {
    let answer_set = set;
    let enrolId = this.state.enrollment_id;
    let data = {
      run_duration: this.state.timer / 60,
      inStudyMode: this.props.location.state?.isPractice || false,
      answer_set: answer_set,
    };
    if (answer_set.length > 0) {
      axios({
        method: "POST",
        url: "/api/students/runhist/enrollments/" + enrolId,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      })
        .then((response) => {
          sessionStorage.setItem("enrollment_id", response.data.enrollment_id);
        })
        .catch((error) => {
          console.log(error.response.data.errors[0].message);
        });
    }
  }

  continueLater() {
    this.pauseExamSet(this.state.pauseSet);
    this.props.history.push("/studentlanding");
    // window.location.reload();
  }

  pauseExam() {
    let answer_set = this.state.pauseSet;
    let enrolId = this.state.enrollment_id;
    let data = {
      run_duration: this.state.timer / 60,
      inStudyMode: this.state.isPractice,
      answer_set: answer_set,
    };
    axios({
      method: "POST",
      url: "/api/students/runhist/enrollments/" + enrolId,
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    })
      .then((response) => {
        sessionStorage.setItem("enrollment_id", response.data.enrollment_id);
        this.setState({ isPaused: false });
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  pauseResumeExam(status) {
    this.setState({ isPaused: status });
  }

  // Add a new method to check if any option is selected
  isAnyOptionSelected() {
    const { currentQuestion, pauseSet } = this.state;
    const options = ["a", "b", "c", "d", "e"];

    return (
      options.some(
        (option) =>
          currentQuestion["answer"] === option ||
          pauseSet.find(
            (item) => item.question_number === currentQuestion.question_number
          )?.selected_answer === option
      ) || currentQuestion["answer"] === "none"
    );
  }

  getBubbleClassNameForResume = (questionNumber) => {
    // const { type } = this.props.location.state || {};
    const { pauseSet } = this.state;

    if (this.props.location) {
      let search = new URLSearchParams(this.props.location.search);
      //let isPractice = this.props.location.state?.isPractice || false;

      //console.log("Attempted Questions:", pauseSet);
      //console.log("New Questions:", this.state.newQASet);

      if (search) {
        let type = search.get("type");

        if (type === "resume") {
          const attemptedQuestion = pauseSet.find(
            (pItem) => pItem.question_number === questionNumber
          );

          if (attemptedQuestion) {
            if (
              attemptedQuestion.selected_answer &&
              attemptedQuestion.selected_answer !== "empty" &&
              attemptedQuestion.review === true
            ) {
              // console.log("answeredreview");
              return "answeredreview";
            } else if (
              attemptedQuestion.selected_answer &&
              attemptedQuestion.selected_answer !== "empty" &&
              !attemptedQuestion.review
            ) {
              // console.log("answered");
              return "answered";
            } else if (
              (!attemptedQuestion.answer ||
                attemptedQuestion.selected_answer === "empty") &&
              attemptedQuestion.review
            ) {
              // console.log("notansweredreview");
              return "notansweredreview";
            }
            return "blank";
          }
        }
      }
    }
    // console.log("not catched by resume");
    // If not resuming or question not attempted, use the logic from getBubbleClassName
    return this.getBubbleClassNameForFresh(questionNumber);
  };

  getBubbleClassNameForFresh = (questionNumber) => {
    const currentQuestion = this.state.newQASet.find(
      (item) => item.question_number === questionNumber
    );

    if (currentQuestion) {
      // Check if the question has been reviewed or answered
      if (
        currentQuestion.answer &&
        currentQuestion.selected_answer !== "empty" &&
        currentQuestion.review === true
      ) {
        console.log("answeredreview");
        return "answeredreview";
      } else if (
        currentQuestion.answer &&
        currentQuestion.selected_answer !== "empty" &&
        !currentQuestion.review
      ) {
        console.log("answered");
        return "answered";
      } else if (
        (!currentQuestion.answer ||
          currentQuestion.selected_answer === "empty") &&
        currentQuestion.review
      ) {
        console.log("notansweredreview");
        return "notansweredreview";
      }
    }

    // Default class when none of the conditions are met
    return "blank";
  };

  modal() {
    swal({
      title: "Submit Exam ?",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        swal("Exam Submitted", {
          icon: "success",
        });
        this.submitExam();
      } else {
        console.log("exam submission failed");
      }
    });
  }
  state = {
    username: "",
    password: "",
    isDirty: false,
  };

  buttonText = this.state.showExplanation ? "Hide" : "Show";

  /*  getBubbleClassName = (questionNumber) => {
    const pauseItem = this.state.pauseSet.find(
      (pItem) =>
        pItem.question_number === questionNumber &&
        (pItem.selected_answer ||
          pItem.review === true ||
          pItem.review === false)
    );

    if (pauseItem) {
      const { review, selected_answer } = pauseItem;

      if (review && selected_answer === "none") {
        console.log("Rendering with class: notansweredreview");
        return "notansweredreview";
      } else if (review && selected_answer) {
        console.log("Rendering with class: answeredreview");
        return "answeredreview";
      } else if (selected_answer) {
        console.log("Rendering with class: answered");
        return "answered";
      } else if (!review && selected_answer !== "none") {
        console.log("Rendering with class: blank");
        return "blank"; // Considered as not marked for review and not answered
      }
    } */
  /* else {
      const currentQuestion = this.state.newQASet.find(
        (item) => item.question_number === questionNumber
      );

      if (currentQuestion && currentQuestion.review) {
        console.log("Rendering with class: notansweredreview");
        return "notansweredreview";
      }
      console.log("Rendering with class: blank");
      return "blank";
    } */
  //};

  render() {
    if (this.state.loading) {
      return (
        <Loader
          className="loader text-center"
          type="Puff"
          color="#00BFFF"
          height={500}
          width={100}
          timeout={3000}
        />
      );
    } else {
      const { minutes, seconds } = this.state;
      let isPractice = this.props.location.state?.isPractice || false;

      return (
        <div>
          <div className="container  screen-padding border border-gray ">
            <div className=" row">
              {this.state.currentQuestion && (
                <p className="col-4 text-left">
                  Question {this.state.currentQuestion.question_number} of{" "}
                  {this.state.qLength}
                </p>
              )}

              <p className="col-4 text-right">
                Domain:{" "}
                {this.state.currentQuestion &&
                  this.state.currentQuestion.domain}
              </p>
              <div className="col-4 text-center">
                {minutes === 0 && seconds === 1 ? (
                  <h6>Busted!</h6>
                ) : (
                  <h6>
                    Time Left: {minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </h6>
                )}
              </div>
            </div>
            <div className="row">
              <div className="question-section col-md-8">
                <div className="question">
                  <b
                    dangerouslySetInnerHTML={{
                      __html: this.state.currentQuestion.question,
                    }}
                  />
                </div>

                <div>
                  <div className="mb-3">
                    <label className="option-label">
                      <div className="option-box">
                        <input
                          type="radio"
                          id="customControlValidation1"
                          name="radio-stacked"
                          value="a"
                          required
                          onChange={this.questionDataChange.bind(
                            this,
                            "answer"
                          )}
                          checked={
                            this.state.currentQuestion["answer"] === "a" ||
                            this.state.pauseSet.find(
                              (item) =>
                                item.question_number ===
                                this.state.currentQuestion.question_number
                            )?.selected_answer === "a"
                          }
                        />{" "}
                        <span className="option-letter">&nbsp;A. </span>
                        <span
                          className="option-text"
                          dangerouslySetInnerHTML={{
                            __html: this.state.currentQuestion["a"],
                          }}
                        />
                      </div>
                    </label>
                  </div>
                  <div className="mb-3">
                    <label className="option-label">
                      <div className="option-box">
                        <input
                          type="radio"
                          id="customControlValidation2"
                          name="radio-stacked"
                          value="b"
                          required
                          onChange={this.questionDataChange.bind(
                            this,
                            "answer"
                          )}
                          checked={
                            this.state.currentQuestion["answer"] === "b" ||
                            this.state.pauseSet.find(
                              (item) =>
                                item.question_number ===
                                this.state.currentQuestion.question_number
                            )?.selected_answer === "b"
                          }
                        />{" "}
                        <span className="option-letter">&nbsp;B. </span>
                        <span
                          className="option-text"
                          dangerouslySetInnerHTML={{
                            __html: this.state.currentQuestion["b"],
                          }}
                        />
                      </div>
                    </label>
                  </div>

                  {this.state.currentQuestion["c"] === undefined ||
                  this.state.currentQuestion["c"] === "" ? (
                    <></>
                  ) : (
                    <div className="mb-3">
                      <label className="option-label">
                        <div className="option-box">
                          <input
                            type="radio"
                            id="customControlValidation3"
                            name="radio-stacked"
                            value="c"
                            required
                            onChange={this.questionDataChange.bind(
                              this,
                              "answer"
                            )}
                            checked={
                              this.state.currentQuestion["answer"] === "c" ||
                              this.state.pauseSet.find(
                                (item) =>
                                  item.question_number ===
                                  this.state.currentQuestion.question_number
                              )?.selected_answer === "c"
                            }
                          />{" "}
                          <span className="option-letter">&nbsp;C. </span>
                          <span
                            className="option-text"
                            dangerouslySetInnerHTML={{
                              __html: this.state.currentQuestion["c"],
                            }}
                          />
                        </div>
                      </label>
                    </div>
                  )}
                  {this.state.currentQuestion["d"] === undefined ||
                  this.state.currentQuestion["d"] === "" ? (
                    <></>
                  ) : (
                    <div className="mb-3">
                      <label className="option-label">
                        <div className="option-box">
                          <input
                            type="radio"
                            id="customControlValidation4"
                            name="radio-stacked"
                            value="d"
                            required
                            onChange={this.questionDataChange.bind(
                              this,
                              "answer"
                            )}
                            checked={
                              this.state.currentQuestion["answer"] === "d" ||
                              this.state.pauseSet.find(
                                (item) =>
                                  item.question_number ===
                                  this.state.currentQuestion.question_number
                              )?.selected_answer === "d"
                            }
                          />{" "}
                          <span className="option-letter">&nbsp;D. </span>
                          <span
                            className="option-text"
                            dangerouslySetInnerHTML={{
                              __html: this.state.currentQuestion["d"],
                            }}
                          />
                        </div>
                      </label>
                    </div>
                  )}

                  {this.state.currentQuestion["e"] === undefined ||
                  this.state.currentQuestion["e"] === "" ? (
                    <></>
                  ) : (
                    <div className="mb-3">
                      <label className="option-label">
                        <div className="option-box">
                          <input
                            type="radio"
                            id="customControlValidation5"
                            name="radio-stacked"
                            value="e"
                            required
                            onChange={this.questionDataChange.bind(
                              this,
                              "answer"
                            )}
                            checked={
                              this.state.currentQuestion["answer"] === "e" ||
                              this.state.pauseSet.find(
                                (item) =>
                                  item.question_number ===
                                  this.state.currentQuestion.question_number
                              )?.selected_answer === "e"
                            }
                          />{" "}
                          <span className="option-letter">&nbsp;E. </span>
                          <span
                            className="option-text"
                            dangerouslySetInnerHTML={{
                              __html: this.state.currentQuestion["e"],
                            }}
                          />
                        </div>
                      </label>
                    </div>
                  )}
                </div>
                {/* <div className="mb-3" style={{ width: "32%" }}>
                  <label className="option-label">
                    <div className="option-box-unselect">
                      <input
                        type="radio"
                        id="customControlValidationNone"
                        name="radio-stacked"
                        value="none"
                        required
                        onChange={this.onChangeDeselect}
                        checked={
                          this.state.currentQuestion["answer"] === "none" ||
                          this.state.pauseSet.find(
                            (item) =>
                              item.question_number ===
                              this.state.currentQuestion.question_number
                          )?.selected_answer === "none"
                        }
                        disabled={!this.isAnyOptionSelected()}
                      />
                      <span className="option-letter">
                        &nbsp;Deselect the selected option
                      </span>
                    </div>
                  </label>
                </div> */}

                <div className="row question-change">
                  <div className="col-4">
                    {this.state.currentQuestion["question_number"] <= 1 ? (
                      <></>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-outline-success btn-sm"
                        onClick={this.QuestionListPrev}
                      >
                        Previous
                      </button>
                    )}
                  </div>
                  <div className="col-4 question-review text-center">
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      id="customCheck1"
                      onChange={this.handleChange.bind(this)}
                      checked={this.state.currentQuestion["review"] === true}
                    />

                    <label
                      className="custom-control-label"
                      htmlFor="customCheck1"
                    >
                      Mark for review
                    </label>
                  </div>
                  <div className="col-4 text-right right-padding">
                    {this.state.currentQuestion["question_number"] ===
                    this.state.qLength ? (
                      <button
                        type="button"
                        className="btn btn-success btn-sm"
                        onClick={() => {
                          this.modal();
                        }}
                      >
                        Submit
                      </button>
                    ) : (
                      <button
                        type="button"
                        className="btn btn-success btn-sm"
                        onClick={this.QuestionListNext}
                      >
                        Next
                      </button>
                    )}
                  </div>
                </div>
                {isPractice ? (
                  <div>
                    <div>
                      {!this.state.showExplanation && (
                        <button
                          className="btn btn-outline-primary btn-sm"
                          onClick={() =>
                            this.setState({
                              showExplanation: !this.state.showExplanation,
                            })
                          }
                        >
                          {this.buttonText} Explanation
                        </button>
                      )}
                    </div>
                    <br />
                    <div>
                      {this.state.showExplanation ? (
                        <div className="explanationStyle">
                          <p
                            className="explanationTextStyle"
                            dangerouslySetInnerHTML={{
                              __html: this.state.currentQuestion.explanation,
                            }}
                          />
                        </div>
                      ) : null}
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="col-md-4 border-gray">
                <div className="questionsList text-center">
                  {/* <div>
                      {minutes === 0 && seconds === 1 ? (
                        <h6>Busted!</h6>
                      ) : (
                        <h6>
                          Time Left: {minutes}:
                          {seconds < 10 ? `0${seconds}` : seconds}
                        </h6>
                      )}
                    </div> */}
                  <div>
                    {isPractice ? (
                      this.state.isPaused ? (
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          onClick={() => {
                            this.pauseResumeExam(false);
                          }}
                        >
                          Resume Exam
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-primary btn-sm"
                          onClick={() => {
                            this.pauseResumeExam(true);
                          }}
                        >
                          Pause Exam
                        </button>
                      )
                    ) : null}
                  </div>

                  <div className="row mt-2">
                    <div className="col-12 " key="2">
                      <div className="row" key="3">
                        {this.state.newQASet.map((item) => (
                          <div
                            className="col-1 text-center mb-4"
                            key={item.question_number}
                            onClick={() => {
                              this.setState({ showExplanation: false });
                              if (this.state.newQASet) {
                                let newQASet = this.state.newQASet;
                                let question =
                                  newQASet[item.question_number - 1];
                                if (question.review === true) {
                                  question.review = false;
                                }
                                this.setState({ currentQuestion: question });
                              }
                            }}
                          >
                            <div
                              className={
                                "rounded pb-4 question-number " +
                                this.getBubbleClassNameForResume(
                                  item.question_number
                                )
                                /*  (item.review === true
                                  ? "answeredreview "
                                  : "") +
                                (item.answer && !item.review
                                  ? "answered "
                                  : "") +
                                (!item.answer && item.review
                                  ? "notansweredreview "
                                  : "") */
                              }
                            >
                              {item.question_number}
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>

                  {isPractice ? (
                    <button
                      className="btn btn-success btn-sm float-center"
                      onClick={() => this.continueLater()}
                    >
                      Continue Later
                    </button>
                  ) : null}
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal"
            id="exampleModal"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
            data-backdrop="static"
            data-keyboard="false"
          ></div>
        </div>
      );
      // }
    }
  }
}
