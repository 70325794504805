import React, { Component } from "react";
import axios from "axios";
import "./InstructorPage.css";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import AppConstants from "../../../Constants/AppConstants";
import { Fade } from "react-awesome-reveal";

class InstructorPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: JSON.parse(sessionStorage.getItem("userData")),
      usersType: sessionStorage.getItem("userType"),
      isRegistered: sessionStorage.getItem("isInstructor"),
      instructorRedirect: false,
      instructorList: [],
      instructor: {},
      finalInstructors: [],
      final: [],
      examsList: [],
      loading: true,
      verified: false,
      image: AppConstants.IMG_URL,
      socialmedia: {},
    };
  }
  openRepoinNewTab(url) {
    var win = window.open(url, "_blank");
    win.focus();
  }

  componentDidMount() {
    let search = new URLSearchParams(this.props.location.search);
    if (search) {
      let instId = search.get("instructorId");
      if (instId) {
        this.getUser(instId);
        this.getExams(instId);
      }
    }
    if (this.state.userDetails) {
      this.setState({ loginStatus: true });
    } else {
      this.setState({ loginStatus: false });
    }

    // Set the initial collapse/expand state for each category
    const initialCategoryCollapse = {};
    this.state.examsList.forEach((exam) => {
      initialCategoryCollapse[exam.catagory_id] = true;
    });
    this.setState({ categoryCollapse: initialCategoryCollapse });
  }

  checkUser() {
    axios({
      method: "GET",
      url: "/api/users/currentuser",
    })
      .then((response) => {
        if (response.data.current_user === null) {
          // swal("Please login first");
          this.props.history.push("/signin");
        } else {
          this.getUser();
          console.log(JSON.stringify(response.data));
        }
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  getUser(instId) {
    axios({
      method: "GET",
      url: "/api/users/instructors/" + instId,
    })
      .then((response) => {
        let instructor = response.data;
        let image = AppConstants.IMG_URL + instructor.profile_image;
        instructor.profile_image = image;
        this.setState({ instructor });
        let socialmedia = instructor.socialmedia;
        this.setState({ socialmedia });
        console.log("data:" + JSON.stringify(this.state.socialmedia));
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  getExams(instId) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: "/api/active-exams?exam_creator_id=" + instId,
    })
      .then((response) => {
        this.setState({ loading: false });
        let examsList = response.data.exams;
        this.setState({ examsList });
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  getExam(exam_id) {
    this.props.history.push("/exampage?examId=" + exam_id);
  }

  toggleCategory(categoryId) {
    this.setState((prevState) => ({
      activeCategory:
        prevState.activeCategory === categoryId ? null : categoryId,
    }));
  }

  render() {
    if (this.state.loading) {
      return (
        <Loader
          className="text-center"
          type="Puff"
          color="#00BFFF"
          height={500}
          width={100}
          timeout={5000}
        />
      );
    } else {
      // Extract unique categories
      const uniqueCategories = Array.from(
        new Set(this.state.examsList.map((exam) => exam.catagory_id))
      );
      return (
        <div className="container">
          <div className="basic-projects">
            <Fade bottom duration={2000} distance="40px">
              <div className="projects-heading-div">
                <div className="projects-heading-img-div">
                  <img src="/images/instructorpageimage.svg" alt="ExamArc" />
                </div>
                <div className="projects-heading-text-div">
                  <div className="avatar">
                    <img
                      src={this.state.instructor.profile_image}
                      alt="category"
                    />
                  </div>
                  <h3 className="projects-heading-text">
                    {this.state.instructor.first_name}{" "}
                    {this.state.instructor.last_name}
                  </h3>
                  <p className="projects-header-detail-text subTitle">
                    {this.state.instructor.bio}
                  </p>
                </div>
              </div>
            </Fade>
          </div>
          {/* Exam Listing Section */}
          <div className="container">
            <div className="row text-center mt-4">
              <h5 className="edu-title mx-auto">
                Exams by {this.state.instructor.first_name}{" "}
                {this.state.instructor.last_name}
              </h5>
            </div>
            <hr />
            <div>
              {uniqueCategories.map((categoryId) => {
                const categoryExams = this.state.examsList.filter(
                  (exam) => exam.catagory_id === categoryId
                );

                return (
                  <div key={categoryId + "category"}>
                    <div
                      className={`category-item ${
                        this.state.activeCategory === categoryId ? "active" : ""
                      }`}
                      onClick={() => this.toggleCategory(categoryId)}
                    >
                      <b>
                        {this.state.activeCategory === categoryId ? (
                          <span>- </span>
                        ) : (
                          <span>+ </span>
                        )}
                        {categoryExams[0].catagory_name}{" "}
                      </b>

                      <div
                        className={` exams-list ${
                          this.state.activeCategory === categoryId
                            ? "expanded"
                            : "collapsed"
                        } `}
                      >
                        {" "}
                        <hr style={{ margin: "5px 0", width: "100%" }} />
                        {categoryExams.map((filteredExam, index) => (
                          <div
                            className="repo-cards-div-main"
                            key={filteredExam.exam_id}
                            onClick={() => this.getExam(filteredExam.exam_id)}
                          >
                            <Fade bottom duration={2000} distance="40px">
                              <object
                                data="/images/test-icon.svg"
                                width="25"
                                height="25"
                              >
                                {" "}
                              </object>
                              <p className="repo-durations">
                                {filteredExam.exam_title}
                              </p>

                              <p className="repo-durations">
                                {filteredExam.exam_subtitle}
                              </p>

                              <p className="repo-durations">
                                Questions: {filteredExam.number_of_questions}
                              </p>
                              <p className="repo-duration">
                                Time: {filteredExam.exam_duration} Mins
                              </p>
                              <p className="repo-duration">
                                Marks: {filteredExam.total_exam_score}
                              </p>
                            </Fade>
                            {index !== categoryExams.length - 1 && (
                              <hr style={{ margin: "5px 0", width: "100%" }} />
                            )}
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>{" "}
            <hr />
          </div>
        </div>
      );
    }
  }
}

export default InstructorPage;
