import axios from "axios";
import React, { Component } from "react";
import AppConstants from "../../../../Constants/AppConstants";
//import Sidebar from "../../../Sidebar";
import "./CategoriesList.css";

class CategoriesList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      categories: [],
      search: "",
      imageUrl: "",
      isTablet: false,
    };
  }

  componentDidMount() {
    const userAgent = navigator.userAgent.toLowerCase();
    const isTablet =
      /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
        userAgent
      );
    this.setState({ isTablet: isTablet });
    let imageUrl = AppConstants.IMG_URL;
    this.setState({ imageUrl });
    this.getCategories();
  }

  getCategories() {
    let search = "";
    this.setState({ search: search });
    let categories = [];
    axios({
      method: "Get",
      url: "/api/popular?categories=yes",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      let cat = response.data.popular.categories;
      cat.forEach((element) => {
        axios({
          method: "Get",
          url: "/api/catagories/" + element.category_id,
          headers: {
            "Content-Type": "application/json",
          },
        }).then((response) => {
          let catDeatail = response.data;
          categories.push(catDeatail);
          this.setState({ categories });
        });
      });
    });
  }

  setSearch = (e) => {
    this.setState({ search: e.target.value });
  };

  searchCategories() {
    this.setState({ categories: [] });
    let search = this.state.search;
    if (!search) {
      this.getCategories();
    } else
      axios({
        method: "Get",
        url: "/api/catagories?search=" + search + "&status=active",
        headers: {
          "Content-Type": "application/json",
        },
      }).then((response) => {
        let categories = [];
        let data = response.data.catagories;
        data.forEach((ele) => {
          axios({
            method: "Get",
            url: "/api/catagories/" + ele.catagory_id,
            headers: {
              "Content-Type": "application/json",
            },
          }).then((response) => {
            let catDeatail = response.data;
            categories.push(catDeatail);
            this.setState({ categories });
          });
        });
      });
  }

  render() {
    return (
      <div className="container">
        {/* <div className='row'>
                    <Sidebar /> */}
        <div className="col-12">
          <div className="row">
            <div className="form-inline float-sm-right mt-4">
              <div className="form-group mx-sm-2 mb-2">
                <input
                  type="search"
                  className="form-control"
                  id="inputPassword2"
                  placeholder="Search Here..."
                  onChange={this.setSearch.bind(this)}
                />
              </div>
              <div className="">
                <button
                  className="btn btn-primary btn-sm mx-2"
                  onClick={() => this.searchCategories()}
                >
                  Search
                </button>
                <button
                  className="btn btn-primary btn-sm"
                  onClick={() => this.getCategories()}
                >
                  Reset
                </button>
              </div>
            </div>
            <div className="container">
              <div className="row">
                {!this.state.categories
                  ? "Loading..."
                  : this.state.categories.map((cat) => {
                      return (
                        <div
                          className={
                            this.state.isTablet
                              ? "col-md-4 my-3"
                              : "col-md-2 my-3"
                          }
                          key={cat.catagory_id}
                        >
                          <div
                            key={cat.catagory_id}
                            className="card mt"
                            href={
                              "/category-page?categoryId=" + cat.catagory_id
                            }
                          >
                            <a
                              className="text-decoration-none"
                              href={
                                "/category-page?categoryId=" + cat.catagory_id
                              }
                            >
                              <div className="card-image">
                                <figure className="image">
                                  <img
                                    src={cat.catagory_image}
                                    alt="category"
                                    height={
                                      AppConstants.POPULAR_CATEGORIES_HEIGHT
                                    }
                                    width={
                                      AppConstants.POPULAR_CATEGORIES_WIDTH
                                    }
                                  />
                                </figure>

                                <div className="card-content text-center">
                                  {/* <a
                                                              className="text-decoration-none"
                                                              href={
                                                                "/category-page?categoryId=" +
                                                                cat.catagory_id
                                                              }
                                                            > */}
                                  <p className="text-dark cat-name text-center">
                                    {cat.catagory}
                                  </p>
                                  {/* </a> */}
                                </div>
                              </div>
                            </a>
                          </div>
                        </div>
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
        {/* </div > */}
      </div>
    );
  }
}

export default CategoriesList;
