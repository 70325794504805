import React, { useEffect } from "react";
import { withRouter } from "react-router-dom";
import PopularGoals from "../../PopularGoals";
import InstructorSection from "../../InstructorSection";
import { homeObjOne, homeInstructorSectionData } from "./Data";
import PopularInstructors from "../../Cards/PopularInstructorsPublic";
import Categories from "../../categories/CategoriesPublic";
import "./Home.css";
import Footer from "../Footer/Footer";

function Home(props) {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the component mounts
    let usersType = sessionStorage.getItem("userType");

    if (usersType) {
      let isInstructor = sessionStorage.getItem("isInstructor");

      if (isInstructor === true) {
        props.history.push("/instructorlanding");
      } else {
        props.history.push("/studentlanding");
      }
    } else {
      props.history.push("/");
    }

    let domainSelected = sessionStorage.getItem("domainSelected");
    if (domainSelected) {
      // You can handle setting state or any other logic here
    }
  }, []); // The empty dependency array ensures this effect runs once, similar to componentDidMount

  return (
    <div>
      <PopularGoals {...homeObjOne} />
      <div>
        <Categories />
        <PopularInstructors />
      </div>
      <div className="screen-padding pad-mob">
        <InstructorSection {...homeInstructorSectionData} />
      </div>
      <Footer />
    </div>
  );
}
export default withRouter(Home);
