import React from "react";
import "./PopularGoals.css";
import { Link } from "react-router-dom";
import { Redirect } from "react-router-dom";
import { useHistory } from "react-router";

function InstructorSection({
  topLine,
  lightText,
  lightTextDesc,
  scrollingheadlines,
  headline,
  userData,
  description,
  buttonLabel,
  img,
  alt,
}) {
  const history = useHistory();

  const switchToInstructor = () => {
    let usersType = sessionStorage.getItem("userType");
    let userDetails = JSON.parse(sessionStorage.getItem("userData"));
    let isInstructor = JSON.parse(sessionStorage.getItem("isInstructor"));
    if (userDetails != null) {
      if (usersType === "student") {
        if (isInstructor === false) {
          sessionStorage.setItem("userType", "instructor");
          sessionStorage.setItem("isSwitching", true);
          history.push("/instructor-details");
          window.location.reload();
        } else {
          sessionStorage.setItem("userType", "instructor");
          history.push("/instructorlanding");
          window.location.reload();
        }
      } else if (usersType === "instructor") {
        if (isInstructor === false) {
          sessionStorage.setItem("userType", "instructor");
          sessionStorage.setItem("isSwitching", true);
          history.push("/instructor-details");
          window.location.reload();
        } else {
          sessionStorage.setItem("userType", "instructor");
          history.push("/instructorlanding");
          window.location.reload();
        }
      }
    } else {
      return <Redirect to={{ pathname: "/signin" }} />;
    }
  };

  return (
    <>
      <div className="container rows">
        <div className="row">
          <div className="col-sm-6 ">
            <div className="home__hero-img-wrapper">
              <img src={img} alt={alt} className="home__hero-img" />
            </div>
          </div>
          <div className="col-sm-6 justify-content-center">
            <br></br>
            <div className="top-line">{topLine}</div>
            {scrollingheadlines ? (
              <div className="row slider">{/* <Demo {...Sheadlines} /> */}</div>
            ) : (
              <h1 className={lightText ? "heading" : "heading dark"}>
                {headline}
              </h1>
            )}

            <p
              className={
                lightTextDesc
                  ? "home__hero-subtitle"
                  : "home__hero-subtitle dark"
              }
            >
              {description}
            </p>
            {userData === null ? (
              <Link className="justify-content-center" to="/sign-up">
                <div className="mob-resp">
                  <button className="btn btn-primary  btn-sm">
                    {buttonLabel}
                  </button>
                </div>
              </Link>
            ) : (
              <div className="justify-content-center">
                <button
                  className="btn btn-primary  btn-sm"
                  onClick={() => switchToInstructor()}
                >
                  {buttonLabel}
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default InstructorSection;
