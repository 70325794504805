import React, { Component } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import "./Style.css";

class InstructorSignup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      registrationData: {
        user_type: "instructor",
      },
      errors: {},
      Redirect: "",
    };
  }

  loginChange(field, e) {
    let registrationData = this.state.registrationData;
    registrationData[field] = e.target.value;
    this.setState({ registrationData });
  }

  /**
   * form validation part here
   */
  handleValidation() {
    let registrationData = this.state.registrationData;
    let errors = {};
    let formIsValid = true;
    //first_name
    if (!registrationData["first_name"]) {
      formIsValid = false;
      errors["first_name"] = "Cannot be empty";
    }

    if (typeof registrationData["first_name"] !== "undefined") {
      if (!registrationData["first_name"].match(/^[Aa-zA-Z]+$/)) {
        formIsValid = false;
        errors["first_name"] = "Only letters";
      }
    }
    //last_name
    if (!registrationData["last_name"]) {
      formIsValid = false;
      errors["last_name"] = "Cannot be empty";
    }
    if (typeof registrationData["last_name"] !== "undefined") {
      if (!registrationData["last_name"].match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["last_name"] = "Only letters";
      }
    }
    //Email
    if (!registrationData["email"]) {
      formIsValid = false;
      errors["email"] = "Cannot be empty";
    }
    if (typeof registrationData["email"] !== "undefined") {
      let lastAtPos = registrationData["email"].lastIndexOf("@");
      let lastDotPos = registrationData["email"].lastIndexOf(".");

      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          registrationData["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          registrationData["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }
    //Password
    if (!registrationData["password"]) {
      formIsValid = false;
      errors["password"] = "Cannot be empty";
    }

    this.setState({ errors: errors });
    return formIsValid;
  }

  onLoginFormSubmit = (e) => {
    e.preventDefault();
    if (this.handleValidation()) {
      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        //  url: Api.API_URL + 'users/signup',
        url: "/api/users/signup",
        data: this.state.registrationData,
      })
        .then((response) => {
          if (response.status === 201) {
            sessionStorage.setItem(
              "studentData",
              JSON.stringify(response.data)
            );
            this.setState({ Redirect: true });
          }
        })
        .catch((error) => {
          console.log(error.response.data.errors[0].message);
        });
      this.setState({ Redirect: true });
    }
  };
  render() {
    if (this.state.Redirect === true) {
      return <Redirect to={{ pathname: "/instructor-details" }} />;
    }
    return (
      <div className="regi mt-2 ">
        <div className="container  ">
          <h2>Sign Up</h2>
          <form method="post" onSubmit={this.onLoginFormSubmit}>
            <div className="form-group">
              <label htmlFor="firstname">First Name</label>
              <input
                type="text"
                className="form-control"
                id="exampleInputfirstname"
                name="first_name"
                placeholder="Enter Your First Name"
                onChange={this.loginChange.bind(this, "first_name")}
                defaultValue={this.state.registrationData["first_name"] || ""}
              />
              <span style={{ color: "red" }}>
                {this.state.errors["first_name"]}
              </span>
            </div>
            <div className="form-group">
              <label htmlFor="lastname">Last Name</label>
              <input
                type="text"
                className="form-control"
                onChange={this.loginChange.bind(this, "last_name")}
                defaultValue={this.state.registrationData["last_name"] || ""}
                id="last_name"
                // name="last_name"
                placeholder="Enter Your Last Name"
              />
              <span style={{ color: "red" }}>
                {this.state.errors["last_name"]}
              </span>
            </div>
            <div className="form-group">
              <label htmlFor="Email1">Email</label>
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                name="email"
                onChange={this.loginChange.bind(this, "email")}
                defaultValue={this.state.registrationData["email"] || ""}
                placeholder="Enter Your Email"
              />
              <span style={{ color: "red" }}>{this.state.errors["email"]}</span>
            </div>
            <div className="form-group">
              <label htmlFor="Password">Password</label>
              <input
                type="password"
                className="form-control"
                id="exampleInputPassword"
                onChange={this.loginChange.bind(this, "password")}
                defaultValue={this.state.registrationData["password"] || ""}
                name="password"
                placeholder="Enter password"
              />
              <span style={{ color: "red" }}>
                {this.state.errors["password"]}
              </span>
            </div>
            <button
              type="submit"
              className="btn btn-primary btn-sm"
              name="create"
            >
              Sign Up
            </button>

            <Link
              style={{ textDecoration: "none" }}
              to="/signin"
              variant="body2"
            >
              Already have an account? Sign in
            </Link>
          </form>
        </div>
      </div>
    );
  }
}
export default withRouter(InstructorSignup);
