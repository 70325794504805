/* eslint-disable react/no-deprecated */

import React, { Component } from "react";
import "./CreateExam.css";
import axios from "axios";
import $ from "jquery";
import "bootstrap/dist/css/bootstrap.css";
import ReactSummernote from "react-summernote";
import "react-summernote/dist/react-summernote.css";
import Loader from "react-loader-spinner";
import { Redirect } from "react-router-dom";
window.jQuery = $;
require("jquery/dist/jquery");
require("bootstrap");
require("popper.js/dist/umd/popper");
require("bootstrap/dist/js/bootstrap");

export default class CreateBlogPost extends Component {
  constructor(props) {
    super(props);
    this.state = {
      uniqueEditor: "",
      errors: {},
      emptyError: "",
      isPostEdit: false,
      loading: true,

      blogData: {
        blog_title: "",
        blog_desc: "",
        catagory_id: "",
        catagory_name: "",
        domain_id: "",
        domain_name: "",
        blog_creator_id: "",
      },
      categoryList: [],
      domainList: [],
      currentTab: 1,
      showHide: false,
      showHide1: false,
      showHide3: false,
      is_submitted: false,
      RedirectNew: false,
      defaultTextSummerNote: "Start writing...",
      editorInitialized: false,
      editorRef: React.createRef(),
      blogContent: "",
    };
  }

  /**
   * lifecylce method
   */
  componentDidMount() {
    // we introduce the editorInitialized state variable to keep track of whether the Summernote
    // editor has been initialized.We use a setInterval function to check for the presence of the editor
    // element every 100 milliseconds.Once the editor is found, the CSS class is added, and the interval is cleared.
    const intervalId = setInterval(() => {
      const editor = document.querySelector(".note-editable");
      if (editor) {
        editor.classList.add("left-aligned");
        clearInterval(intervalId);
      }
    }, 100);

    // this.checkUser();
    window.scrollTo(0, 0);
    this.getDomainList();

    let search = new URLSearchParams(this.props.location.search);
    if (search.has("blogId")) {
      // Check if "blogId" parameter exists in the URL
      this.setState({ isPostEdit: true });
      let blogId = search.get("blogId");
      this.setState((prevState) => ({
        blogData: {
          ...prevState.blogData,
          blog_id: blogId,
        },
      }));
      this.getBlogById(blogId); // Call getBlogById only when "blogId" parameter exists
      //this.fetchBlogContent(blogId);
    }
  }

  getBlogById(blogId) {
    let url;
    let showHide1 = false;
    this.setState({ showHide1 });

    url = "/api/blogs/v1/" + blogId;

    axios({
      method: "get",
      headers: {
        "Content-Type": "application/json",
      },
      url: url,
    })
      .then((response) => {
        this.setState({ blogData: response.data });
        console.log(this.state.blogData);
        console.log("hai", this.state.blogData.blog_content);
        this.getCategoryList(response.data.domain_id);
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  handleBackClick() {
    this.props.history.push("/instructorlanding");
  }

  dataChange(field, e) {
    const { blogData, domainList, categoryList } = this.state;
    const updatedBlogData = { ...blogData, [field]: e.target.value };

    if (field === "domain_id") {
      const domainId = e.target.value;
      const selectedDomain = domainList.find(
        (domain) => domain.domain_id === domainId
      );
      updatedBlogData.domain_name = selectedDomain ? selectedDomain.domain : "";
      this.getCategoryList(domainId);
    }

    if (field === "catagory_id") {
      const categoryId = e.target.value;
      const selectedCategory = categoryList.find(
        (category) => category.catagory_id === categoryId
      );
      updatedBlogData.catagory_name = selectedCategory
        ? selectedCategory.catagory
        : "";
    }

    this.setState({ blogData: updatedBlogData });
    console.log("again", updatedBlogData);
  }

  //The toBlob method converts the canvas content into a Blob object.If the resulting
  //blob size is within the target size limit, the compressed image URL is obtained
  //using URL.createObjectURL and passed to the insertImage function.
  //the newWidth and newHeight variables are introduced to calculate the resized dimensions
  //while maintaining the aspect ratio of the original image.The resizing logic is then
  //performed using these updated dimensions to ensure that the image is not distorted.
  onImageUpload = (images, insertImage) => {
    const maxSize = 1572864; // Maximum allowed image size in bytes (1.5MB)
    const targetSize = 1200000; // Target image size in bytes (1.2MB)
    const maxWidth = 500; // Maximum allowed image width (adjust as needed)
    const maxHeight = 500; // Maximum allowed image height (adjust as needed)
    const compressionQuality = 0.8; // Compression quality (adjust as needed)

    if (images[0].size <= maxSize) {
      for (let i = 0; i < images.length; i++) {
        const reader = new FileReader();
        reader.onloadend = () => {
          const image = new Image();
          image.src = reader.result;
          image.onload = function () {
            let width = image.width;
            let height = image.height;
            let newWidth = width;
            let newHeight = height;

            if (width > maxWidth || height > maxHeight) {
              const scale = Math.min(maxWidth / width, maxHeight / height);
              newWidth = width * scale;
              newHeight = height * scale;
            }

            const canvas = document.createElement("canvas");
            const ctx = canvas.getContext("2d");
            canvas.width = newWidth;
            canvas.height = newHeight;

            ctx.drawImage(image, 0, 0, newWidth, newHeight);
            canvas.toBlob(
              (blob) => {
                if (blob.size <= targetSize) {
                  const compressedImageURL = URL.createObjectURL(blob);
                  insertImage(compressedImageURL);
                } else {
                  console.log(
                    "Image not saved, max allowed image size is 1500KB"
                  );
                }
              },
              "image/jpeg",
              compressionQuality // Compression quality
            );
          };
        };
        reader.readAsDataURL(images[i]);
      }
    } else {
      console.log("Image not saved, max allowed image size is 1.5MB");
    }
  };

  async getCategoryList(domaniId) {
    const { data: response } = await axios
      .get("/api/catagories?domain_id=" + domaniId)
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
    var i = 0;
    while (i < response.catagories.length) {
      response.catagories[i].domainName = response.catagories[i].domain.domain;
      i++;
    }
    this.setState({ categoryList: response.catagories });
  }

  async getDomainList() {
    const { data: response } = await axios
      .get("/api/domains?page_no=1&count=200&status=active")
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
    this.setState({ domainList: response.domains });
    this.setState({ loading: false });
  }

  onExamFormSubmit() {
    console.log("hai");
    const { blogData } = this.state;

    let finalBlogData = { ...blogData };
    let instructorData = JSON.parse(sessionStorage.getItem("userData"));

    if (instructorData && instructorData.user_id) {
      finalBlogData.blog_creator_id = instructorData.user_id;
    }

    axios({
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      url: "/api/blogs/v1/" + finalBlogData.blog_id, // Use finalBlogData.blog_id here
      data: finalBlogData, // Pass finalBlogData directly as the data payload
    })
      .then((response) => {
        if (response.status === 200 || response.status === 201) {
          console.log("Details updated successfully....");

          if (this.state.image) {
            console.log("image updated successfully....");

            this.uploadImage(response.data.blog_id);
          }

          // Fetch updated blog content after successfully updating the blog post
          this.fetchBlogContent(finalBlogData.blog_id);
        } else {
          console.log("Error while updating details");
        }
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });

    this.instructorlanding();
  }

  onExamFormSubmit2() {
    const { isPostEdit, blogData } = this.state;

    let finalBlogData = { ...blogData };
    let instructorData = JSON.parse(sessionStorage.getItem("userData"));

    if (instructorData && instructorData.user_id) {
      finalBlogData.blog_creator_id = instructorData.user_id;
    }

    if (isPostEdit) {
      const { blog_id, ...updatedData } = finalBlogData;

      axios({
        method: "put",
        headers: {
          "Content-Type": "application/json",
        },
        url: "/api/blogs/v1/" + blog_id,
        data: updatedData,
      })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            console.log("Details updated successfully....");
            // Fetch updated blog content after successfully updating the blog post
            this.fetchBlogContent(blog_id);
          } else {
            console.log("Error while updating details");
          }
        })
        .catch((error) => {
          console.log(error.response.data.errors[0].message);
        });
    } else {
      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        url: "/api/blogs/v1/",
        data: finalBlogData, // No need to include blog_id in POST request
      })
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            console.log("Details saved successfully....");
            if (this.state.image) {
              this.uploadImage(response.data.blog_id);
            }
            // Fetch blog content after successfully submitting the blog post
            this.fetchBlogContent(response.data.blog_id);
          } else {
            console.log("Error while saving details");
          }
        })
        .catch((error) => {
          console.log(error.response.data.errors[0].message);
        });
    }
  }

  fetchBlogContent(blogId) {
    axios
      .get(`/api/blogs/v1/${blogId}`)
      .then((response) => {
        const blogContent = response.data.blog_content;
        this.setState({ blogContent });
        // Move to Step-3 (Tab 3)
        this.setState({ currentTab: 3 });
      })
      .catch((error) => {
        console.log("Error fetching blog content:", error);
      });
  }

  setImage(e) {
    this.setState({ image: e.target.files[0] });
  }

  uploadImage(blog_id) {
    let image = this.state.image;

    var bodyFormData = new FormData();
    bodyFormData.append("blog", image);
    console.log(bodyFormData);
    axios({
      method: "post",
      headers: { "Content-Type": "multipart/form-data" },
      url: "/api/images/exams/" + blog_id,
      data: bodyFormData,
    })
      .then(() => {
        console.log("image uploaded");
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }
  myExams() {
    this.props.history.push("/instructor-blogpost");
  }

  instructorlanding() {
    this.props.history.push("/instructorlanding");
  }

  handleChange = (content) => {
    console.log(this.state.blogData.blog_content);
    this.setState({
      blogData: {
        ...this.state.blogData,
        blog_content: content, // Update blog_content with Summernote content
      },
    });
  };

  render() {
    const {
      RedirectNew,
      loading,
      currentTab,
      is_submitted,
      domainList,
      categoryList,
      blogData,
      // defaultTextSummerNote = "Type here",
    } = this.state;

    if (RedirectNew) {
      return <Redirect to={{ pathname: "/instructor-blogpost" }} />;
    }

    if (loading) {
      return (
        <Loader
          className="text-center"
          type="Puff"
          color="#00BFFF"
          height={500}
          width={100}
          timeout={5000}
        />
      );
    }

    return (
      <div className="container screen-padding-exs">
        {/* Step-1 & Step-2: Create Post MetaData & Write Your Content */}
        <div className={currentTab === 1 ? "tabshow" : "tabhidden"}>
          <div className="report--header">
            <h3 className="text-center">Step-1: Create Post MetaData</h3>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="container ExamData">
                <div
                  className={
                    this.state.showAssistantStatus ? "tabhidden" : "tabshow"
                  }
                >
                  <div className="border">
                    {/* Blog Title */}
                    <div className="form-group row">
                      <div className="col-sm-12 col-md-6">
                        <label
                          htmlFor="Blog Title"
                          className="col-form-label required"
                        >
                          Blog Title
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="Blog Title"
                          name="blog_title"
                          placeholder="Enter Blog Title"
                          value={blogData.blog_title}
                          onChange={(e) => this.dataChange("blog_title", e)}
                        />
                        {!blogData.blog_title && is_submitted && (
                          <span className="required-validation">
                            Mandatory Field
                          </span>
                        )}
                      </div>
                      {/* Blog SubTitle */}
                      <div className="col-sm-12 col-md-6">
                        <label
                          htmlFor="Blog Subtitle"
                          className="col-form-label required"
                        >
                          Blog SubTitle
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="Blog Subtitle"
                          name="blog_desc"
                          placeholder="Enter Blog Subtitle"
                          value={blogData.blog_desc}
                          onChange={(e) => this.dataChange("blog_desc", e)}
                        />
                        {!blogData.blog_desc && is_submitted && (
                          <span className="required-validation">
                            Mandatory Field
                          </span>
                        )}
                      </div>
                    </div>
                    {/* Select Domain */}
                    <div className="form-group row">
                      <div className="categories col-sm-12 col-md-6">
                        <label
                          htmlFor="Domain"
                          className="col-form-label required"
                        >
                          Select Domain
                        </label>
                        <select
                          className="custom-select my-1 mr-sm-2"
                          id="Domain"
                          value={blogData.domain_id}
                          onChange={(e) => this.dataChange("domain_id", e)}
                        >
                          <option value="">Select Domain</option>
                          {domainList.map((item, i) => (
                            <option key={i} value={item.domain_id}>
                              {item.domain}
                            </option>
                          ))}
                        </select>
                        {!blogData.domain_id && is_submitted && (
                          <span className="required-validation">
                            Mandatory Field
                          </span>
                        )}
                      </div>
                      {/* Select Category */}
                      <div className="categories col-sm-12 col-md-6">
                        <label
                          htmlFor="Category"
                          className="col-form-label required"
                        >
                          Select Category
                        </label>
                        <select
                          className="custom-select my-1 mr-sm-2"
                          id="Category"
                          value={blogData.catagory_id}
                          onChange={(e) => this.dataChange("catagory_id", e)}
                        >
                          <option value="">Select Category</option>
                          {categoryList.map((item, i) => (
                            <option key={i} value={item.catagory_id}>
                              {item.catagory}
                            </option>
                          ))}
                        </select>
                        {!blogData.catagory_id && is_submitted && (
                          <span className="required-validation">
                            Mandatory Field
                          </span>
                        )}
                      </div>
                    </div>

                    {/* Buttons */}
                    <div className="form-group">
                      <div className="text-center">
                        <button
                          className="btn btn-primary mr-2"
                          onClick={() => this.handleBackClick()}
                        >
                          Back
                        </button>
                        <button
                          className="btn btn-primary"
                          onClick={() => {
                            if (
                              blogData.blog_title &&
                              blogData.domain_id &&
                              blogData.catagory_id
                            ) {
                              this.onExamFormSubmit2();
                            } else {
                              this.setState({ is_submitted: true });
                              console.log(
                                "Please fill all mandatory fields to continue"
                              );
                            }
                          }}
                        >
                          {this.state.isPostEdit
                            ? "Update Post"
                            : "Create Post"}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Step-3: Blog Content */}
        <div className={currentTab === 3 ? "tabshow" : "tabhidden"}>
          <div className="report--header">
            <h3 className="text-center">Step-3: Blog Content</h3>
          </div>

          <div className="col-xs-12 col-md-12 col-12">
            <div
              className="col-12 summernote"
              style={{ height: "calc(100vh * 2 / 3)" }}
            >
              <ReactSummernote
                value={this.state.blogContent}
                options={{
                  lang: "en-US",
                  height: 350,
                  dialogsInBody: true,
                  toolbar: [
                    ["style", ["style"]],
                    ["font", ["bold", "underline", "clear"]],
                    ["fontname", ["fontname"]],
                    ["para", ["ul", "ol", "paragraph"]],
                    ["table", ["table"]],
                    ["insert", ["link", "picture"]],
                    ["view", ["fullscreen"]],
                  ],
                }}
                onChange={this.handleChange}
                onImageUpload={this.onImageUpload}
              />
            </div>
          </div>

          {/* Button to navigate back to instructor landing page */}
          <div className="border text-center">
            <button
              type="button"
              className="btn btn-primary"
              onClick={() => this.onExamFormSubmit()}
            >
              Back to Instructor Landing Page
            </button>
          </div>

          <div className="col-xs-12 col-md-12 col-12">
            {/* Display fetched blog content */}
            <div dangerouslySetInnerHTML={{ __html: this.state.blogContent }} />
          </div>
        </div>
      </div>
    );
  }
}
