import axios from "axios";

import React, { Component } from "react";
import Loader from "react-loader-spinner";
import swal from "sweetalert";
import Sidebar from "../../Sidebar";
import { Fade } from "react-awesome-reveal";
import "./Instructorlanding.css";
import "./../Enrollment/Enrollments.css";
import { Link } from "react-router-dom";

export default class Instructorlanding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      notifications: [
        {
          note: "New payment options have been added to the payment module.",
        },
        {
          note: "Exams can be saved as drafts at any point of creation.",
        },
        {
          note: "Exams can be multiple-choice based or generic.",
        },
      ],
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.checkUser();
  }

  myExams = () => {
    this.props.history.push("/instructor-exams");
  };

  createExam = () => {
    this.props.history.push("/createexam");
  };

  instructorLanding = () => {
    this.props.history.push("/instructorlanding");
  };

  checkUser = () => {
    axios
      .get("/api/users/currentuser")
      .then((response) => {
        if (response.data.current_user === null) {
          swal("Please login first");
          this.props.history.push("/signin");
        } else {
          let usersType = sessionStorage.getItem("userType");
          this.setState({ userType: usersType });
          let userDetails = JSON.parse(sessionStorage.getItem("userData"));
          this.setState({ userData: userDetails });
          let isInstructor = JSON.parse(sessionStorage.getItem("isInstructor"));
          this.setState({ isInstructor: isInstructor });
          if (this.state.userData != null) {
            if (isInstructor === false) {
              sessionStorage.setItem("userType", "instructor");
              sessionStorage.setItem("isSwitching", true);
              this.setState({ showHide: true });
              this.props.history.push("/instructor-details");
              window.location.reload();
            }
          } else {
            console.log("User data available");
          }
          console.log("logged in");
        }
      })
      .catch((error) => {
        console.log(error.message || "Failed to fetch user data");
      });
  };
  render() {
    if (this.state.loading) {
      return (
        <Loader
          className="text-center"
          type="Puff"
          color="#00BFFF"
          height={500}
          width={100}
          timeout={2000}
        />
      );
    }

    return (
      <div className="container">
        <div className="row">
          <Sidebar />
          <div className="col-md-3 col-sm-6 mt-3">
            <Fade bottom duration={2000} distance="40px">
              <Link to="/createexam" className="card img-fluid">
                <div className="tile">
                  <h3 className="title">Create Exam</h3>
                  <p className="m-4 ipad-p">Create a new Exam.</p>
                </div>
              </Link>
            </Fade>
          </div>
          <div className="col-md-3 col-sm-6 mt-3">
            <Fade bottom duration={2000} distance="40px">
              <Link to="/instructor-exams" className="card img-fluid">
                <div className="tile">
                  <h3 className="title">Exams</h3>
                  <p className="m-4 ipad-p">Your Exams</p>
                </div>
              </Link>
            </Fade>
          </div>
          <div className="col-md-3 col-sm-6 mt-3">
            <Fade bottom duration={2000} distance="40px">
              <Link to="/instructor-blogpost" className="card img-fluid">
                <div className="tile">
                  <h3 className="title">Blog Posts</h3>
                  <p className="m-4 ipad-p">Your Posts</p>
                </div>
              </Link>
            </Fade>
          </div>
        </div>
      </div>
    );
  }
}
