import axios from "axios";
import React, { Component } from "react";

class DomainList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      domains: [],
      categories: [],
    };
    this.getDomains();
  }

  getCategories(domainId) {
    axios({
      method: "Get",
      url: "/api/catagories/?status=active&domain_id=" + domainId,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      let categories = response.data.catagories;
      console.log(categories);
      this.setState({ categories });
    });
  }

  getDomains() {
    axios({
      method: "Get",
      url: "/api/domains/?status=active",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      let domains = response.data.domains;
      this.setState({ domains });
    });
  }

  render() {
    return (
      <div className="container-fluid screen-padding">
        <div className="container-fluid">
          <div className="row">
            <div className="col-4 domains">
              <div className="row title">
                <h4>
                  <b>Domains List</b>
                </h4>
              </div>
              <div className="btn-group-vertical">
                {this.state.domains.map((domain) => {
                  return (
                    <button
                      key={domain.domain_id}
                      type="button"
                      className="btn btn-info mt-1 mb-1"
                      onClick={() => {
                        this.getCategories(domain.domain_id);
                      }}
                    >
                      {domain.domain}
                    </button>
                  );
                })}
              </div>
            </div>
            <div className="col-8 categories">
              <div className="row title">
                <h4>
                  <b>Categories</b>
                </h4>
              </div>
              <div className="container-fluid">
                <ul className="cards">
                  {this.state.categories.map((cat, i) => {
                    return (
                      <li key={i}>
                        {/* <Card instId={inst.user_id} imgsrc={inst.profile_image} title={inst.title} bio={inst.bio} /> */}

                        <a
                          href={"/category-page?categoryId=" + cat.catagory_id}
                          className="card"
                        >
                          <img
                            src="images/green-back.jpg"
                            className="card__image"
                            alt=""
                          />
                          <div className="card__overlay">
                            <div className="card__header">
                              {/* <svg className="card__arc" xmlns="http://www.w3.org/2000/svg"><path /></svg> */}
                              {/* <img className="card__thumb" src="https://i.imgur.com/7D7I6dI.png" alt="" /> */}
                              <div className="card__header-text">
                                <h3 className="card__title">{cat.catagory}</h3>
                                {/* <span className="card__status">{cat.education}</span> */}
                              </div>
                            </div>
                            <p className="card__description">
                              {cat.catagory_desc}...
                            </p>
                          </div>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default DomainList;
