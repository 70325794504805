import axios from "axios";
import { toast } from "react-toastify";
import React, { Component } from "react";
import "./Style.css";

class ForgotPasswordEmail extends Component {
  state = {
    email: "",
  };

  componentDidMount() {
    const search = new URLSearchParams(this.props.location.search);
    const email = search.get("email");
    if (email) {
      this.setState({ email });
    }
  }

  setForgotEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  redirectLogin = () => {
    this.props.history.push("/signin");
  };

  handleSubmit = async (event) => {
    event.preventDefault();

    const { email } = this.state;

    if (!email) {
      toast.error("Please enter your email address.");
      return;
    }

    const emailPattern =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;

    if (!emailPattern.test(email)) {
      toast.error("You have entered an invalid email address.");
      return;
    }

    try {
      await axios.post("/api/users/passwords/forgot", { email });

      toast.success(
        "Reset password instructions are mailed. Please check your email."
      );
      this.props.history.push(`/forgotpassword?email=${email}`);
    } catch (error) {
      toast.error(error.response.data.errors[0].message, {
        autoClose: 1000,
      });
    }
  };

  render() {
    return (
      <div
        className="regi"
        style={{ backgroundColor: "#f8f9fa", borderRadius: "10px" }}
      >
        <div className="container">
          <h5 className="signin mt-4">Password Reset</h5>
          <form className="modal-footer">
            <div>
              <br></br>
              <input
                className="form-control"
                type="email"
                placeholder="Enter your registered email"
                onChange={this.setForgotEmail}
                value={this.state.email}
                id="exampleInputEmail2"
                aria-describedby="emailHelp"
              />

              <div id="emailHelp" className="form-text">
                We&apos; ll never share your email with anyone.
              </div>

              <br />
              <div className="row modal-footer">
                <button
                  className="btn btn--wide btn-sm"
                  onClick={this.handleSubmit}
                >
                  Get Code
                </button>

                <button
                  className="btn btn--wide-blue btn-sm"
                  onClick={this.redirectLogin}
                >
                  Back To Login
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

export default ForgotPasswordEmail;
