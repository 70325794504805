import React, { Component } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { Redirect } from "react-router-dom";
import { withRouter } from "react-router";
import "./Style.css";

import $ from "jquery";
import AppConstants from "../../Constants/AppConstants";
import bcrypt from "bcryptjs";

class SignIn extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loginData: {},
      errors: {},
      Redirect: false,
      email: "",
      new_password: "",
      validation_token: "",
      showLoginL: false,
      userData: "",
      isInstructor: false,
      forgotPasswordRedirect: false,
      is_email_verified: true,
      callback: "not fired",
      value: "[empty]",
      load: false,
      expired: "false",
    };
  }

  loginChange(field, e) {
    let loginData = this.state.loginData;
    loginData[field] = e.target.value;
    this.setState({ loginData });
  }

  /**
   * form validation part here
   */
  handleValidation() {
    let loginData = this.state.loginData;
    let errors = {};
    let formIsValid = true;
    //Email
    if (!loginData["email"]) {
      formIsValid = false;
      errors["email"] = "Cannot be empty";
    }
    if (typeof loginData["email"] !== "undefined") {
      let lastAtPos = loginData["email"].lastIndexOf("@");
      let lastDotPos = loginData["email"].lastIndexOf(".");
      if (
        !(
          lastAtPos < lastDotPos &&
          lastAtPos > 0 &&
          loginData["email"].indexOf("@@") === -1 &&
          lastDotPos > 2 &&
          loginData["email"].length - lastDotPos > 2
        )
      ) {
        formIsValid = false;
        errors["email"] = "Email is not valid";
      }
    }
    //Password
    if (!loginData["password"]) {
      formIsValid = false;
      errors["password"] = "Cannot be empty";
    } else {
      loginData["password"] = bcrypt.hashSync(
        loginData["password"],
        "$2a$10$CwTycUXWue0Thq9StjUM0u"
      ); // hash created previously created upon sign up
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  onLoginFormSubmit = (e) => {
    e.preventDefault();
    toast.configure();
    if (this.handleValidation()) {
      axios({
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        url: "/api/users/signin",
        data: this.state.loginData,
      })
        .then((response) => {
          sessionStorage.setItem("userData", JSON.stringify(response.data));
          let user = response.data;
          if (user.is_email_verified === false) {
            this.setState({ is_email_verified: false });
          }
          if (response.data.is_student_user === true) {
            sessionStorage.setItem("userType", "student");
            sessionStorage.setItem("isInstructor", false);
            this.setState({ isStudent: true });
            this.setState({ isInstructor: false });
          }
          if (response.data.is_instructor === true) {
            sessionStorage.setItem("isInstructor", true);
            this.setState({ isInstructor: true });
          }
          this.setState({ Redirect: true, userData: response.data });
          window.location.reload();
        })
        .catch((error) => {
          toast.error(error.response.data.errors[0].message, {
            autoClose: 1000,
          });
        });
    }
  };

  forgotPassword = () => {
    const forgotEmail = {
      email: this.state.email,
    };
    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: "/api/users/passwords/forgot",
      data: forgotEmail,
    })
      .then(() => {
        $("#forgotpassword_modal").modal("hide");
        let email = this.state.email;
        toast.success(
          "Reset password instructions are mailed, Please check your mail."
        );
        this.props.history.push("/forgotpassword?email=" + email);
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].message);
      });
  };

  setForgotEmail = (e) => {
    this.setState({ email: e.target.value });
  };

  render() {
    if (this.state.is_email_verified === false) {
      return <Redirect to={{ pathname: "/verifyUser" }} />;
    }
    if (this.state.Redirect === true && this.state.isStudent === true) {
      return <Redirect to={{ pathname: "/studentlanding" }} />;
    } else if (
      this.state.Redirect === true &&
      this.state.isInstructor === true
    ) {
      return <Redirect to={{ pathname: "/instructorlanding" }} />;
    }
    if (this.state.forgotPasswordRedirect === true) {
      return <Redirect to={{ pathname: "/" }} />;
    }
    return (
      <div
        className="regi"
        style={{ backgroundColor: "#f8f9fa", borderRadius: "10px" }}
      >
        <div className="container">
          <h5 className="signin mt-4">Sign In</h5>
          <br></br>
          <form method="post" onSubmit={this.onLoginFormSubmit}>
            <div className="form-group">
              <input
                type="email"
                className="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                name="email"
                onChange={this.loginChange.bind(this, "email")}
                defaultValue={this.state.loginData["email"] || ""}
                placeholder="Enter Your Email*"
              />
              <span style={{ color: "red" }}>{this.state.errors["email"]}</span>
            </div>
            <div className="form-group">
              <input
                type="password"
                className="form-control"
                id="exampleInputPassword"
                onChange={this.loginChange.bind(this, "password")}
                defaultValue={this.state.loginData["password"] || ""}
                name="password"
                placeholder="Enter Your Password*"
              />
              <span style={{ color: "red" }}>
                {this.state.errors["password"]}
              </span>
            </div>
            <div className="text-center">
              <button
                type="submit"
                className="btn btn--wide btn-sm bg-green"
                name="create"
              >
                Sign In
              </button>
              <div className="mt-2">
                <button
                  style={{
                    textDecoration: "none",
                    border: "none",
                    background: "none",
                    color: "#007bff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    // Add your navigation logic here
                    // For example, use react-router-dom to navigate to the sign-up page
                    this.props.history.push("/signup");
                  }}
                >
                  New to {AppConstants.WEBSITENAME}? Sign Up
                </button>
              </div>
              <div className="mt-2">
                <button
                  style={{
                    textDecoration: "none",
                    border: "none",
                    background: "none",
                    color: "#007bff",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    // Add your navigation logic here
                    // For example, use react-router-dom to navigate to the forgot password page
                    this.props.history.push("/forgotpassword-emailentry");
                  }}
                >
                  Forgot Password
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  }
}
export default withRouter(SignIn);
