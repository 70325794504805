import React from "react";

const TermsConditions = () => {
  const containerStyle = {
    maxWidth: "800px",
    margin: "20px auto",
    padding: "20px",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
  };

  const heading1Style = {
    color: "#333",
  };

  const heading2Style = {
    color: "#555",
  };

  const paragraphStyle = {
    marginBottom: "10px",
  };

  const listStyle = {
    marginLeft: "20px",
  };

  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.6",
        margin: "0",
        padding: "0",
        backgroundColor: "#f4f4f4",
      }}
    >
      <div style={containerStyle}>
        <h1 style={heading1Style}>Terms and Conditions</h1>
        <p style={paragraphStyle}>
          Welcome to ExamArc.com, an e-learning platform designed to connect
          students and teachers for educational purposes. By registering and
          using our website, you agree to comply with and be bound by the
          following terms and conditions. Please review them carefully before
          using the site.
        </p>

        <h2 style={heading2Style}>1. Acceptance of Terms</h2>
        <p style={paragraphStyle}>
          By accessing or using ExamArc.com, you agree to be legally bound by
          these terms and conditions, as well as our Privacy Policy. If you do
          not agree with any of these terms, please do not use the site.
        </p>

        <h2 style={heading2Style}>2. Registration</h2>
        <p style={paragraphStyle}>
          To use certain features of the site, including enrolling in courses or
          offering courses, you must register for an account. You agree to
          provide accurate and complete information during registration and keep
          this information up to date.
        </p>

        <h2 style={heading2Style}>3. User Responsibilities</h2>
        <p style={paragraphStyle}>
          As a user of ExamArc.com, you agree to the following:
        </p>
        <ul style={listStyle}>
          <li style={paragraphStyle}>
            You are responsible for maintaining the confidentiality of your
            account login details.
          </li>
          <li style={paragraphStyle}>
            You must not use the site for any illegal or unauthorized purpose.
          </li>
          <li style={paragraphStyle}>
            You must not upload, post, or otherwise transmit any content that is
            harmful, abusive, defamatory, or otherwise objectionable.
          </li>
          <li style={paragraphStyle}>
            You agree to comply with all applicable laws and regulations
            regarding your use of the site.
          </li>
        </ul>

        <h2 style={heading2Style}>4. Content Ownership</h2>
        <p style={paragraphStyle}>
          All content on ExamArc.com, including but not limited to text,
          graphics, logos, and software, is the property of ExamArc.com or its
          content suppliers and is protected by intellectual property laws.
          Users retain ownership of content they upload but grant ExamArc.com a
          license to use, display, and distribute the content on the platform.
        </p>

        <h2 style={heading2Style}>5. Limitation of Liability</h2>
        <p style={paragraphStyle}>
          ExamArc.com is not liable for any damages that may occur from your use
          of the site, including but not limited to direct, indirect,
          incidental, or consequential damages. The site is provided as is and
          without warranties of any kind.
        </p>

        <h2 style={heading2Style}>6. Termination</h2>
        <p style={paragraphStyle}>
          ExamArc.com reserves the right to terminate or suspend your account
          and access to the site at our sole discretion, without notice, for
          conduct that we believe violates these terms or is harmful to other
          users or the site itself.
        </p>

        <h2 style={heading2Style}>7. Changes to Terms</h2>
        <p style={paragraphStyle}>
          We may update these terms and conditions from time to time. Any
          changes will be posted on this page, and continued use of the site
          after such changes constitutes acceptance of the new terms.
        </p>

        <h2 style={heading2Style}>8. Governing Law</h2>
        <p style={paragraphStyle}>
          These terms and conditions are governed by and construed in accordance
          with the laws of India. Any disputes arising from these terms will be
          resolved in the courts of Kollam, Kerala.
        </p>

        <h2 style={heading2Style}>9. Contact Us</h2>
        <p style={paragraphStyle}>
          If you have any questions or concerns about these terms, please
          contact us at{" "}
          <a href="mailto:support@examarc.com">support@examarc.com</a>.
        </p>

        <p style={paragraphStyle}>Last updated: 20-August 2024</p>
      </div>
    </div>
  );
};

export default TermsConditions;
