import React from "react";
import { NavLink } from "react-router-dom";
import "./InstructorSidebar.css";

function InstructorSidebar() {
  return (
    <>
      <div className="col-lg-2 col-md-2 col-sm-10 col-xs-10 bd-categories text-center d-none d-lg-block">
        <ul className="nav-pills flex-column mb-auto">
          <div className="dropdown-divider"></div>
          <li className="nav-item">
            <NavLink
              to="/instructorlanding"
              exact
              activeClassName="hover-2"
              className="btn-block text-decoration-none"
            >
              <i className="fa fa-dashboard"></i> Dashboard
            </NavLink>
          </li>
          <div className="dropdown-divider"></div>
          <li className="nav-item">
            <NavLink
              to="/instructorprofile"
              activeClassName="hover-2"
              className="btn-block text-decoration-none"
            >
              <i className="fa fa-drivers-license"></i> My Profile
            </NavLink>
          </li>
          <div className="dropdown-divider"></div>
          <li className="nav-item">
            <NavLink
              to="/instructor-exams"
              activeClassName="hover-2"
              className="btn-block text-decoration-none"
            >
              <i className="fa fa-mortar-board"></i> My Exams
            </NavLink>
          </li>
          <div className="dropdown-divider"></div>
          <li className="nav-item">
            <NavLink
              to="/instructor-blogpost"
              activeClassName="hover-2"
              className="btn-block text-decoration-none"
            >
              <i className="fa fa-mortar-board"></i> My Posts
            </NavLink>
          </li>
          <div className="dropdown-divider"></div>
          <li className="nav-item">
            <NavLink
              to="/createexam"
              activeClassName="activee"
              className="btn-block text-decoration-none"
            >
              <i className="fa  fa-edit"></i> Create Exam
            </NavLink>
          </li>
          <div className="dropdown-divider"></div>
          <li className="nav-item">
            <NavLink
              to="/createblogpost"
              activeClassName="activee"
              className="btn-block text-decoration-none"
            >
              <i className="fa  fa-edit"></i> Create Post
            </NavLink>
          </li>
          <div className="dropdown-divider"></div>
        </ul>
      </div>
    </>
  );
}

export default InstructorSidebar;
