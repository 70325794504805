import axios from "axios";
// import cogoToast from 'cogo-toast';
import React, { Component } from "react";
// import { DonutMultiple, DonutElement, DonutLabel } from "react-donut-component";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import "./ReviewExam.css";
import Loader from "react-loader-spinner";
import Sidebar from "../../Sidebar";

ChartJS.register(ArcElement, Tooltip, Legend);

class ReviewExam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      enrollment_id: sessionStorage.getItem("enrollment_id"),
      result: {},
      exam: {},
      chartPoints: [],
      chartData: {
        labels: [
          "Correct Answers",
          "Incorrect Answers",
          "Unattempted Questions",
        ],
        datasets: [
          {
            label: "Count:",
            data: [0, 0, 0],
            backgroundColor: [
              "rgba(75, 192, 192, 0.2)",
              "rgba(255, 99, 132, 0.2)",
              "rgba(255, 159, 64, 0.2)",
            ],
            borderColor: [
              "rgba(75, 192, 192, 1)",
              "rgba(255, 99, 132, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      },
      x: 0,
      y: 0,
      result_id: "",
      messageInfo: {
        results: {},
      },
      hours: 0,
      minutes: 0,
      seconds: 0,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.getExamHistory(this.state.enrollment_id);
    this.getExam(this.state.enrollment_id);
  }

  getExam(enrollment_id) {
    axios({
      method: "Get",
      url: "api/exams/enrollments/" + enrollment_id,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      let exam = response.data;
      this.setState({ exam });
    });
  }

  getExamResult(enrollment_id, result_id) {
    axios({
      method: "Get",
      url:
        "/api/students/results/enrollments/" +
        enrollment_id +
        "?result_id=" +
        result_id,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      let result = response.data.results;
      let questions_answered = result.questions_answered.length;
      let questions_skipped = result.questions_skipped.length;
      let wrong_answers = result.wrong_answers.length;
      let correct_answers = questions_answered - wrong_answers;
      this.setState({ result });
      let pass_score = result.run_score;
      let total_score = result.total_score;
      let x = (pass_score / total_score) * 100;
      x = x.toFixed(2);
      this.setState({ x });
      let y = 100 - x;
      y = y.toFixed(2);
      this.setState({ y });
      this.setState({ result_id });
      this.getMessage(result.result_ref_id);
      this.setState({ questions_answered });
      this.setState({ questions_skipped });
      this.setState({ wrong_answers });
      this.setState({ correct_answers });
      let chartPoints = [correct_answers, wrong_answers, questions_skipped];
      let d = this.state.chartData.datasets;
      d.data = chartPoints;
      // console.log("gfdgdf" + this.state.chartData.datasets.data);
      this.setState({
        chartData: {
          labels: [
            "Correct Answers",
            "Incorrect Answers",
            "Unattempted Questions",
          ],
          datasets: [
            {
              label: "Count:",
              data: chartPoints,
              backgroundColor: [
                "rgba(75, 192, 192, 0.2)",
                "rgba(255, 99, 132, 0.2)",
                "rgba(255, 159, 64, 0.2)",
              ],
              borderColor: [
                "rgba(75, 192, 192, 1)",
                "rgba(255, 99, 132, 1)",
                "rgba(255, 159, 64, 1)",
              ],
              borderWidth: 1,
            },
          ],
        },
      });
    });
  }

  getExamHistory(enrollment_id) {
    axios({
      method: "Get",
      url: "/api/students/results/enrollments/" + enrollment_id,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      let examHistory = response.data.results;
      let i = examHistory.length;
      let exam = examHistory[i - 1];
      this.getExamResult(enrollment_id, exam.result_id);
    });
  }

  getMessage(result_ref_id) {
    axios({
      method: "Get",
      url:
        "/api/students/positions/" +
        this.state.enrollment_id +
        "/results?result_ref_id=" +
        result_ref_id,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      let resp = response.data;
      this.setState({ messageInfo: resp });
      let msgInfo = this.state.messageInfo;
      // if (msgInfo.results && msgInfo.results.run_duration){
      //     msgInfo.results.run_duration = msgInfo.results.run_duration.toFixed(2);
      // }
      // if (msgInfo.results && msgInfo.results.run_score) {
      //     msgInfo.results.run_score = msgInfo.results.run_score.toFixed(2);
      // }

      const sec = parseInt(msgInfo.results.run_duration, 10); // convert value to number if it's string
      let hours = Math.floor(sec / 3600); // get hours
      let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
      let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
      // add 0 if value < 10; Example: 2 => 02
      if (hours < 10) {
        hours = "0" + hours;
      }
      if (minutes < 10) {
        minutes = "0" + minutes;
      }
      if (seconds < 10) {
        seconds = "0" + seconds;
      }

      this.setState({ hours: hours, minutes: minutes, seconds: seconds });

      this.setState({ messageInfo: msgInfo });
      this.setState({ loading: false });
    });
  }

  reviewQuestions() {
    let result_id = this.state.result_id;
    this.props.history.push("/exam-report?result_id=" + result_id);
  }

  render() {
    if (this.state.loading) {
      return (
        <Loader
          className="text-center"
          type="Puff"
          color="#00BFFF"
          height={800}
          width={100}
          timeout={5000}
        />
      );
    } else {
      return (
        <div className="container">
          {/* <div className="report--header small">
            <h3>Your Exam Attempt History</h3>
          </div> */}
          <br></br>
          <div className="row">
            <Sidebar />
            <div className="conatiner col-lg-10 col-md-10 col-sm-12 col-xs-12">
              <div className="mt-2 ">
                <div>
                  <h6>Exam Result</h6>
                </div>
                <hr />
                <div className="card-body">
                  {this.state.messageInfo.results &&
                  this.state.messageInfo.results.exam_style ===
                    "competitive" ? (
                    <div className="row">
                      <div className="col-12">
                        <h6 className="review-h4">
                          {this.state.messageInfo.message}
                        </h6>
                      </div>
                    </div>
                  ) : (
                    <div></div>
                  )}
                  <div className="row">
                    <div className="col-xs-6 col-md-4 mt-5 chart">
                      <Doughnut
                        data={this.state.chartData}
                        options={{
                          responsive: true,
                          maintainAspectRatio: true,
                        }}
                      />
                    </div>

                    <div className="col-xs-6 col-md-7">
                      <p className="card-title-top text-capitalize">
                        Attempt Count : {this.state.messageInfo.results.attempt}{" "}
                      </p>
                      <p className="card-title text-capitalize">
                        Questions Count : {this.state.exam.number_of_questions}{" "}
                      </p>
                      <p className="card-title text-capitalize">
                        Correct Answers : {this.state.correct_answers}{" "}
                      </p>
                      <p className="card-title text-capitalize">
                        Wrong Answers : {this.state.wrong_answers}{" "}
                      </p>
                      <p className="card-title text-capitalize">
                        Unattempted : {this.state.questions_skipped}{" "}
                      </p>
                      <p className="card-title text-capitalize">
                        Duration: <i>{this.state.hours} Hrs</i>&nbsp;
                        {this.state.minutes} Mins&nbsp;
                        {this.state.seconds} Secs
                      </p>
                      <p className="card-title text-capitalize">
                        Your Score : {this.state.messageInfo.results.run_score}
                        <span>
                          /{this.state.messageInfo.results.total_score}
                        </span>{" "}
                      </p>
                      <a
                        href={"/exam-report?result_id=" + this.state.result_id}
                        className="btn btn--wide"
                      >
                        Review Questions
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="col-2"></div> */}
          </div>
        </div>
      );
    }
  }
}

export default ReviewExam;
