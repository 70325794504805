import React from "react";

const ContactUs = () => {
  const containerStyle = {
    maxWidth: "800px",
    margin: "20px auto",
    padding: "20px",
    backgroundColor: "#fff",
    boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    borderRadius: "8px",
  };

  const headingStyle = {
    color: "#333",
  };

  const paragraphStyle = {
    marginBottom: "10px",
    color: "#555",
  };

  const contactInfoStyle = {
    marginTop: "20px",
  };

  const boldParagraphStyle = {
    fontWeight: "bold",
  };

  return (
    <div
      style={{
        fontFamily: "Arial, sans-serif",
        lineHeight: "1.6",
        margin: "0",
        padding: "0",
        backgroundColor: "#f4f4f4",
      }}
    >
      <div style={containerStyle}>
        <h1 style={headingStyle}>Contact Us</h1>
        <p style={paragraphStyle}>Last updated on Aug 24, 2024</p>
        <p style={paragraphStyle}>
          You may contact us using the information below:
        </p>

        <div style={contactInfoStyle}>
          <p style={boldParagraphStyle}>
            Merchant Legal entity name: KURIAKOSE MERIN
          </p>
          <p style={boldParagraphStyle}>
            Registered Address: Ambattu, Kollam, KERALA 691307
          </p>
          <p style={boldParagraphStyle}>
            Telephone No: <span>+91</span> <span>95</span>
            <span>26</span>
            <span>67</span>
            <span>25</span>
            <span>92</span>
          </p>
          <p style={boldParagraphStyle}>
            E-Mail ID:{" "}
            <a href="mailto:contact@examarc.com">contact@examarc.com</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
