import React, { Component } from "react";
import StateDistrict from "./StateDistrict.json";
import axios from "axios";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";
import {
  FaAngleLeft,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleRight,
  FaAngleDown,
  FaAngleUp,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import { Redirect } from "react-router-dom";

class InstructorsDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      instructorsDetails: {},
      social: {
        twitter: "",
        linkedin: "",
        youtube: "",
        website: "",
      },
      selected: [],
      StateDistrict: StateDistrict,
      District: [],
      education: "",
      state1: "",
      gender: "",
      bio: "",
      district: "",
      birth_year: "",
      primary_goal: {},
      secondary_goals: [],
      goalselect: false,
      primary_goalList: [],
      categoryList: [],
      checkRedirect: false,
      options: [],
      url: "",
    };
    this.getList();
  }

  componentDidMount() {
    this.checkUser();
  }

  checkUser() {
    axios({
      method: "GET",
      url: "/api/users/currentuser",
    })
      .then((response) => {
        if (response.data.current_user === null) {
          this.props.history.push("/signin");
        }
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  onChange = (selected) => {
    this.setState({ selected });
  };

  setStateId = (e) => {
    for (let i = 0; i < this.state.StateDistrict.length; i++) {
      if (e.target.value === this.state.StateDistrict[i].id) {
        this.setState({ District: this.state.StateDistrict[i].districts });
      }
    }
  };

  goalhide = () => {
    if (!this.state.education || !this.state.bio) {
      console.log("Please fill complete details");
    } else {
      this.setState({ goalselect: true });
    }
  };

  goalshow = () => {
    this.setState({ goalselect: false });
  };

  setstate1 = (e) => {
    this.setState({ state1: e.target.value });
  };

  setDistrict(e) {
    this.setState({ district: e.target.value });
  }

  setBirthyear = (e) => {
    this.setState({ birth_year: e.target.value });
  };

  setGender = (e) => {
    this.setState({ gender: e.target.value });
  };

  setBio = (e) => {
    this.setState({ bio: e.target.value });
  };

  setEducation = (e) => {
    this.setState({ education: e.target.value });
  };

  setSocial(field, e) {
    let social = this.state.social;
    social[field] = e.target.value;
    this.setState({ social });
  }

  async getList() {
    try {
      const response = await axios.get("api/catagories/?status=active");
      this.setState({ categoryList: response.data.catagories });
      let myarray = [];

      this.state.categoryList.forEach((item) => {
        var temp = {
          value: item.catagory_id,
          label: item.catagory,
        };
        myarray.push(temp);
      });
      this.setState({ options: myarray });
    } catch (error) {
      console.log(error);
      console.log(error.response.data.errors[0].message);
    }
  }

  onLoginFormSubmit() {
    if (!this.state.education || !this.state.bio) {
      console.log("Please fill complete details");
    } else {
      this.setState({ goalselect: true });

      let isSwitching = JSON.parse(sessionStorage.getItem("isSwitching"));
      let finalInstructionDetails = this.state.instructorsDetails;
      finalInstructionDetails.birth_year = "1956";
      finalInstructionDetails.socialmedia = this.state.social;
      finalInstructionDetails.instructor_goals = this.state.selected;
      finalInstructionDetails.education = this.state.education;
      finalInstructionDetails.bio = this.state.bio;
      this.setState({ instructorsDetails: finalInstructionDetails });

      if (isSwitching === true) {
        axios({
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          url: "/api/users/switches/instructors",
          data: this.state.instructorsDetails,
        })
          .then((response) => {
            if (response.status === 200 || response.status === 201) {
              console.log("Instructor profile is completed successfully....");
              sessionStorage.setItem("isInstructor", true);
              sessionStorage.setItem("isSwitching", false);
              this.setState({ checkRedirect: true });
            } else {
              console.log("Error while saving details");
            }
          })
          .catch((error) => {
            console.log(error.response.data.errors[0].message);
          });
      } else {
        axios({
          method: "post",
          headers: {
            "Content-Type": "application/json",
          },
          url: "/api/users/instructors",
          data: this.state.instructorsDetails,
        })
          .then((response) => {
            if (response.status === 201) {
              console.log("Details saved successfully....");
              sessionStorage.setItem("isInstructor", true);
              this.setState({ checkRedirect: true });
            } else {
              console.log("Error while saving details");
            }
          })
          .catch((error) => {
            console.log(error.response.data.errors[0].message);
          });
      }
    }
  }

  getLength() {
    if (this.state.bio) {
      return 250 - this.state.bio.length;
    } else {
      return 250;
    }
  }

  render() {
    if (this.state.checkRedirect === true) {
      return <Redirect to={{ pathname: "/instructorprofile" }} />;
    }

    const { selected } = this.state;

    return (
      <div className="container py-3">
        <div className="col-md-12">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <span className="anchor" id="formLogin" />
              <h6 className="text-center profile-h4 text-info">
                Complete Your Instructor Profile
              </h6>
              <div className="card-body">
                <form
                  autoComplete="off"
                  className="form"
                  id="formLogin"
                  name="formLogin"
                >
                  {/* Your Qualification */}
                  <div className="form-group">
                    <label htmlFor="colFormLabelSm">Your Qualification:</label>
                    <input
                      type="Education"
                      className="form-control form-control-sm"
                      id="colFormLabelSm"
                      placeholder="Enter Highest Qualification"
                      onChange={this.setEducation.bind(this)}
                      value={this.state.education}
                    />
                  </div>

                  {/* Your Bio */}
                  <div className="form-group">
                    <label htmlFor="exampleFormControlTextarea1">
                      Your Bio:
                    </label>
                    <textarea
                      className="form-control"
                      maxLength="500"
                      id="exampleFormControlTextarea1"
                      rows="3"
                      onChange={this.setBio.bind(this)}
                      value={this.state.bio}
                    ></textarea>
                    <span className="letter-remaining">
                      Letters Remaining: {this.getLength()}
                    </span>
                  </div>

                  {/* Select Your Expertise */}
                  <div>
                    <label htmlFor="exampleFormControlTextarea1">
                      Select Your Expertise From Below
                    </label>
                    <DualListBox
                      className="text-center"
                      alignActions="top"
                      canFilter
                      icons={{
                        moveLeft: <FaAngleLeft />,
                        moveAllLeft: <FaAngleDoubleLeft />,
                        moveRight: <FaAngleRight />,
                        moveAllRight: <FaAngleDoubleRight />,
                        moveDown: <FaAngleDown />,
                        moveUp: <FaAngleUp />,
                      }}
                      options={this.state.options}
                      selected={selected}
                      onChange={this.onChange}
                    />
                  </div>

                  {/* Social Media Links */}
                  <div style={{ marginTop: "20px" }}>
                    <form>
                      {[
                        { icon: <FaLinkedinIn />, field: "linkedin" },
                        { icon: <FaTwitter />, field: "twitter" },
                        { icon: <FaYoutube />, field: "youtube" },
                      ].map((item, index) => (
                        <div className="form-group row" key={index}>
                          <div className="col-12">
                            <input
                              type={item.field}
                              className="form-control form-control-sm"
                              placeholder={`Enter ${item.field} profile URL`}
                              onChange={(e) => this.setSocial(item.field, e)}
                            />
                          </div>
                        </div>
                      ))}
                    </form>
                  </div>

                  {/* Submit Button */}
                  <div className="form-group col-12 text-center">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={() => this.onLoginFormSubmit()}
                    >
                      Proceed
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InstructorsDetails;
