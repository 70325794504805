import axios from "axios";

import React, { Component } from "react";
import { Link } from "react-router-dom";
import swal from "sweetalert";
import Sidebar from "../Sidebar";
import "./Mail.css";

class ReadMail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redUrl: "",
      showMessage: [],
      messagesHistory: [],
      conv_id: "",
      sample: {},
      subject: "",
      message: "",
      reciver: {},
      sender: {},
      messageFormat: {
        message: "",
        subject: "",
        direction: "",
        conversation_id: "",
        from: {},
        to: {},
      },
      str: "",
    };
  }

  componentDidMount() {
    let search = new URLSearchParams(this.props.location.search);
    let exam_id = sessionStorage.getItem("exam_id");
    this.setState({ exam_id });
    let redUrl = "/inbox?examId=" + exam_id;
    this.setState({ redUrl });
    if (search) {
      let conv_id = search.get("conv_id");
      let int = conv_id.toString();
      let len = 17;
      var str = int.substring(len, 24);
      this.setState({ str });
      if (conv_id) {
        this.setState({ conv_id });
        this.getConvChat(conv_id, exam_id);
      } else {
        swal("Not Found");
      }
    }
  }

  getConvChat(conv_id, exam_id) {
    this.getChat(conv_id, exam_id);
  }

  getChat(conv_id, exam_id) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url:
        "/api/examstore/" +
        exam_id +
        "/conversations?conversation_id=" +
        conv_id,
    })
      .then((response) => {
        this.setState({ messagesHistory: response.data.conversation });
        let sample = response.data.conversation[0];
        if (sample.direction === "a2i") {
          let reciver = sample.from;
          this.setState({ reciver });
          let sender = sample.to;
          this.setState({ sender });
        } else {
          let reciver = sample.to;
          this.setState({ reciver });
          let sender = sample.from;
          this.setState({ sender });
        }
        this.setState({ sample });

        if (this.state.messagesHistory === []) {
          this.setState({ newMessageflag: true });
        } else {
          let show = [];
          let data = this.state.messagesHistory;
          data.forEach((element) => {
            let from = element.from;
            let utcDate = element.date;
            let localDate = new Date(utcDate);
            let string = localDate.toString();
            let length = 24;
            var date = string.substring(0, length);
            let object = {
              message: element.message,
              name: from.first_name,
              subject: element.subject,
              date: date,
              conversation: element.conversation_id,
              status: element.status,
              exam_id: element.exam_id,
              direction: element.direction,
            };
            show.push(object);
            this.setState({ showMessage: show });
          });
          console.log(this.state.showMessage);
        }
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  setSubject = (e) => {
    this.setState({ subject: e.target.value });
  };

  setMessage = (e) => {
    this.setState({ message: e.target.value });
  };

  submitMessage() {
    let examId = sessionStorage.getItem("exam_id");
    // this.ws.send(JSON.stringify(message))
    // this.addMessage(message)
    let msg = this.state.messageFormat;
    let subject = this.state.sample.subject;
    let sender = this.state.sender;
    let reciver = this.state.reciver;
    // let user = this.state.userData;
    let message = this.state.message;
    let conId = this.state.conv_id;
    msg.message = message;
    msg.subject = subject;
    msg.direction = "i2a";
    msg.conversation_id = conId;
    msg.from = sender;
    msg.to = reciver;
    axios({
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      data: msg,
      url: "/api/examstore/" + examId + "/conversations",
    })
      .then((response) => {
        console.log(
          "Query Submited, Our team will be in touch with you shortly. Thank you"
        );
        console.log(response.error);
        this.clearText();
        this.getChat(conId, examId);
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  clearText() {
    document.getElementById("output").value = "";
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <Sidebar />

          {/* <button type="button" className="btn btn-primary mr-1 ml-1" data-toggle="modal" data-target="#exampleModal" >Compose Mail</button> */}
          {/* <div className="">
                    <div className="">
                        Interaction Idlll : {this.state.str}
                    </div>
                    <div className="">
                        <button className="btn btn-primary mr-1 ml-1">Show reply & Hide conversation</button>
                        <Link to={this.state.redUrl} className='btn-link'>
                                <button className="btn btn-primary mr-1 ml-1">Back to Inbox</button>
                            </Link>
                    </div>
                </div> */}
          <div className="col-md-8 mx-auto">
            <div className="text-center mt-3 d-flex justify-content-around">
              Interaction Id : {this.state.str}
              <Link to={this.state.redUrl} className="">
                <button className="btn btn-primary">Inbox</button>
              </Link>
            </div>

            <div className="container chat-box border border-info mt-3 mb-2">
              {this.state.showMessage.map((msg, i) => {
                return (
                  <div key={i} className="mt-5">
                    {msg.direction === "a2i" ? (
                      <div className="row ml-1">
                        <div className="col-8">
                          <div className="row date mb-1">
                            {/* <div className="col-4"><b>{msg.name}</b></div> */}
                            <div className="col-4">
                              <b>Admin Team</b>
                            </div>
                            <div className="col-4"></div>
                            <div className="col-4">{msg.date}</div>
                          </div>
                          <div className="row alert alert-primary mb-1">
                            <p className="message mb-1 mt-1 ">{msg.message}</p>
                          </div>
                          {/* <div className="col-4"></div> */}
                        </div>
                      </div>
                    ) : (
                      <div className="row mr-1">
                        <div className="col-4"></div>
                        <div className="col-8">
                          <div className="row  userdate mb-1">
                            {/* <div className="col-4"><b>Admin Team</b></div> */}
                            <div className="col-4">
                              <b>{msg.name}</b>
                            </div>
                            <div className="col-4"></div>
                            <div className="col-4">{msg.date}</div>
                          </div>
                          <div className="row alert alert-warning mb-1">
                            <p className="message mb-1 mt-1 ">{msg.message}</p>
                          </div>
                        </div>
                      </div>
                    )}
                    {/* // <div className="row information">
                                    //     <div className="card border-success mb-3" >
                                        //         <div className="card-body text-success">
                                            //             <h5 className="card-title">{msg.name}</h5>
                            //             <p className="card-text">{msg.message}</p>
                            //         </div>

                            //     </div>
                            // </div> */}
                  </div>
                );
              })}

              <div className="">
                <textarea
                  id="output"
                  className="form-control"
                  rows="6"
                  aria-label="With textarea"
                  onChange={this.setMessage.bind(this)}
                ></textarea>
                <div className="text-center my-2">
                  <span className="input-group-text">
                    <button
                      className="btn btn-primary mx-auto"
                      value="Clear"
                      onClick={() => {
                        this.submitMessage();
                      }}
                    >
                      {" "}
                      Send
                    </button>
                  </span>
                </div>
              </div>
            </div>
          </div>

          {/* ********Modal********* */}

          <div
            className="modal fade"
            id="exampleModal"
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    New message
                  </h5>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <form>
                    <div className="form-group">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Subject
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="subject"
                        onChange={this.setSubject.bind(this)}
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="message-text" className="col-form-label">
                        Message:
                      </label>
                      <textarea
                        className="form-control"
                        id="message-text"
                        onChange={this.setMessage.bind(this)}
                      ></textarea>
                    </div>
                  </form>
                </div>
                <div className="modal-footer">
                  {/* <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button> */}
                  <button
                    type="button"
                    className="btn btn-primary"
                    data-dismiss="modal"
                    onClick={() => {
                      this.submitMessage();
                    }}
                  >
                    Send message
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReadMail;
