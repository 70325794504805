import React, { Component } from "react";
import "./Profile.css";
import axios from "axios";
import $ from "jquery";
import DualListBox from "react-dual-listbox";
import "react-dual-listbox/lib/react-dual-listbox.css";
import {
  FaAngleLeft,
  FaAngleDoubleLeft,
  FaAngleDoubleRight,
  FaAngleRight,
  FaAngleDown,
  FaAngleUp,
  FaGlobe,
  FaLinkedinIn,
  FaTwitter,
  FaYoutube,
} from "react-icons/fa";
import AvatarEditor from "react-avatar-editor";
import AppConstants from "../../../Constants/AppConstants";
import contrycodes from "./contrycodes.json";
import Sidebar from "../../Sidebar";

class InstructorProfile extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.state = {
      catagoryDetails: {},
      categoryList: [],
      userData: JSON.parse(sessionStorage.getItem("userData")),
      profile: JSON.parse(sessionStorage.getItem("userData")),
      image: null,
      instructorGoals: [],
      avatar: "",
      errors: {},
      options: [],
      selected: [],
      oldGoals: [],
      social: {
        twitter: "",
        linkedin: "",
        yutube: "",
        website: "",
      },
      ccode: "+91",
      phone: "",
      bio: "",
      education: "",
      contrycodes: contrycodes,
    };
    this.getCatList();
    this.getUser();
  }

  studentprofile() {
    this.props.history.push("/studentprofile");
  }

  myExams() {
    this.props.history.push("/instructor-exams");
  }

  createExam() {
    this.props.history.push("/createexam");
  }

  instructorlanding() {
    this.props.history.push("/instructorlanding");
  }

  // instructorlanding() {
  //     this.props.history.push('/instructorlanding')
  // }

  async getCatList() {
    const { data: response } = await axios
      .get("/api/catagories/?status=active")
      .catch((error) => {
        console.log(error);
        console.log(error.response.data.errors[0].message);
      });
    this.setState({ categoryList: response.catagories });
    let myarray = [];
    this.state.categoryList.forEach((item) => {
      var temp = {
        value: item.catagory_id,
        label: item.catagory,
      };
      myarray.push(temp);
    });
    this.setState({ options: myarray });
  }

  componentDidMount() {
    this._isMounted = true;
    this.checkUser();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getUser() {
    axios({
      method: "GET",
      url: "/api/users/profile",
    })
      .then((response) => {
        this.setState({ userData: response.data });
        let user = response.data;
        if (user.socialmedia) {
          let social = user.socialmedia;
          this.setState({ social });
        }
        this.setState({ verified: user.is_email_verified });
        let instructorGoals = user.instructor_goals;
        if (instructorGoals[0] === "[{no goals set}]") {
          console.log("empty instructorGoals");
        } else {
          instructorGoals.forEach((element) => {
            this.getCat(element);
          });
        }
        this.setState({ bio: user?.bio });
        this.setState({ education: user?.education });
        this.setState({ selected: instructorGoals });
        let avatar = AppConstants.IMG_URL + user.profile_image;
        this.setState({ avatar });
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  editProfile() {
    axios({
      method: "GET",
      url: "/api/users/profile",
    })
      .then((response) => {
        this.setState({ userData: response.data });
        let user = response.data;
        if (user.socialmedia) {
          let social = user.socialmedia;
          this.setState({ social });
        }
        this.setState({ verified: user.is_email_verified });
        let instructorGoals = user.instructor_goals;
        if (instructorGoals[0] === "[{no goals set}]") {
          console.log("empty instructorGoals");
        } else {
          instructorGoals.forEach((element) => {
            this.getCat(element);
          });
        }
        this.setState({ selected: instructorGoals });
        let avatar = AppConstants.IMG_URL + user.profile_image;
        this.setState({ avatar });
        if (
          !this.state.userData.phone ||
          this.state.userData.phone === "+000-0000000000"
        ) {
          let profile = this.state.userData;
          profile["phone"] = "";
          this.setState({ userData: profile });
        } else {
          let str = this.state.userData.phone;
          const myArr = str.split("-");
          if (myArr && myArr.length > 1) {
            this.setState({ ccode: myArr[0] });
            this.setState({ phone: myArr[1] });
          } else if (myArr && myArr.length === 1) {
            this.setState({ phone: myArr[0] });
          }
        }

        $("#exampleModal").modal("show");
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  async getCat(catagory_id) {
    const { data: response } = await axios
      .get("/api/catagories/" + catagory_id)
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
    let catDetails = response;
    let cat = response.catagory;
    let categories = this.state.instructorGoals;
    categories.push(cat);
    this.setState({ instructorGoals: categories });
    let myarray = [];

    var temp = {
      value: catDetails.catagory_id,
      label: catDetails.catagory,
    };
    myarray.push(temp);
    this.setState({ oldGoals: myarray });
  }

  checkUser() {
    axios({
      method: "GET",
      url: "/api/users/currentuser",
    })
      .then((response) => {
        if (response.data.current_user === null) {
          // swal("Please login first")
          this.props.history.push("/signin");
        } else {
          console.log("logged in");
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          console.log(error.response.data.errors[0].message);
        }
      });
  }

  profileChange(field, e) {
    let profile = this.state.userData;
    profile[field] = e.target.value;
    this.setState({ userData: profile });
  }

  setContryCode = (e) => {
    this.setState({ ccode: e.target.value });
  };

  setPhoneNo = (e) => {
    const re = /^[0-9\b]+$/;
    if (e.target.value === "" || re.test(e.target.value)) {
      this.setState({ phone: e.target.value });
    }
  };

  setBio = (e) => {
    this.setState({ bio: e.target.value });
  };

  setEducation = (e) => {
    this.setState({ education: e.target.value });
  };

  setSocial(field, e) {
    let social = this.state.social;
    social[field] = e.target.value;
    this.setState({ social });
  }

  handleValidation() {
    let registrationData = this.state.profile;
    let errors = {};
    let formIsValid = true;
    //first_name
    if (!registrationData["first_name"]) {
      formIsValid = false;
      errors["first_name"] = "Cannot be empty";
    }

    if (typeof registrationData["first_name"] !== "undefined") {
      if (!registrationData["first_name"].match(/^[Aa-zA-Z]+$/)) {
        formIsValid = false;
        errors["first_name"] = "Only letters";
      }
    }
    //last_name
    if (!registrationData["last_name"]) {
      formIsValid = false;
      errors["last_name"] = "Cannot be empty";
    }
    if (typeof registrationData["last_name"] !== "undefined") {
      if (!registrationData["last_name"].match(/^[a-zA-Z]+$/)) {
        formIsValid = false;
        errors["last_name"] = "Only letters";
      }
    }
    this.setState({ errors: errors });
    return formIsValid;
  }

  onChange = (selected) => {
    this.setState({ selected });
  };

  editedProfilesubmit() {
    let profile = this.state.profile;
    let goals = this.state.selected;
    let arr = [];

    goals.forEach((element) => {
      if (element.value) {
        let id = element.value;
        arr.push(id);
      } else {
        let id = element;
        arr.push(id);
      }
    });
    profile.instructor_goals = arr;
    let social = this.state.social;
    profile.socialmedia = social;
    profile.bio = this.state.bio;
    profile.education = this.state.education;
    if (this.handleValidation()) {
      profile.phone = this.state.ccode + "-" + this.state.phone;
      axios({
        method: "PUT",
        headers: {
          "content-type": "application/json",
        },
        data: profile,
        url: "/api/users/instructors",
      })
        .then(() => {
          console.log("Profile Updated");
          $("#exampleModal").modal("hide");
          window.location.reload();
          // this.getUser()
        })
        .catch((error) => {
          console.log(error.response.data.errors[0].message);
        });
    }
  }

  setImage(e) {
    this.setState({ image: e.target.files[0] });
  }

  uploadImage() {
    let image = this.state.image;
    if (image) {
      var bodyFormData = new FormData();
      bodyFormData.append("profile", image);
      axios({
        method: "post",
        headers: { "Content-Type": "multipart/form-data" },
        url: "/api/images/profile",
        data: bodyFormData,
      })
        .then(() => {
          console.log("image uploaded");
          this.getUser();
          window.location.reload();
        })
        .catch(() => {
          console.log("Image uploading failed, image size should be max 500kb");
        });
    } else {
      console.log("no image found");
    }
  }

  handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      this.setState({
        image: URL.createObjectURL(file),
      });
    }
  };

  handleUploadClick = () => {
    const { image } = this.state;
    this.props.handleUpload(image);
    this.props.handleClose();
  };

  render() {
    const { selected } = this.state;
    const { showModal, handleClose } = this.props;
    const { image } = this.state;
    return (
      <div className="container">
        <div className="row">
          <Sidebar />
          <div className="cardsss col-lg-4 col-md-4 col-sm-12 col-xs-12 mt-3">
            <div className="card mb-3">
              <img
                className="card-img-top card-image"
                src={this.state.avatar}
                alt="Card cap"
              />
              <div className="card-body text-center">
                <button
                  className="btn btn-primary  btn-sm"
                  data-toggle="modal"
                  data-target="#exampleModalCenter"
                >
                  Change Profile Image
                </button>
              </div>
              <div className="row">
                <div className="col  col-md-4">
                  <p className="mb-0 text-left">Name</p>
                </div>

                <div className="col ">
                  <p className="text-left">
                    {this.state.userData.first_name}{" "}
                    {this.state.userData.last_name}
                  </p>
                </div>
              </div>
              <hr className="hrprofile" />
              <div className="row">
                <div className="col  col-md-4">
                  <p className="mb-0 text-left">Email</p>
                </div>
                <div className="col ">
                  <p className="text-left"> {this.state.userData.email}</p>
                </div>
              </div>
              <hr className="hrprofile" />
              <div className="row">
                <div className="col  col-md-4">
                  <p className="mb-0 text-left">Phone</p>
                </div>
                <div className="col ">
                  {!this.state.userData.phone ||
                  this.state.userData.phone === "+000-0000000000" ? (
                    <span></span>
                  ) : (
                    <span>
                      <p className="text-left">{this.state.userData.phone}</p>
                    </span>
                  )}
                </div>
              </div>
              <hr className="hrprofile" />
            </div>
          </div>
          <div className="cardsss col-lg-6 col-md-6 col-sm-12 col-xs-12 mt-3">
            <div className="card">
              <div className="row">
                <div className="col  col-md-3">
                  <p className="mb-0 text-left">Gender</p>
                </div>
                {this.state.userData.gender === 1 ? (
                  <div className="col">
                    <p className="text-left">Male</p>
                  </div>
                ) : (
                  <></>
                )}
                {this.state.userData.gender === 2 ? (
                  <div className="col">
                    <p className="text-left">Female</p>
                  </div>
                ) : (
                  <></>
                )}
                {this.state.userData.gender === 3 ? (
                  <div className="col">
                    <p className="text-left">Other</p>
                  </div>
                ) : (
                  <></>
                )}
              </div>
              <hr className="hrprofile" />
              <div className="row">
                <div className="col  col-md-3">
                  <p className="mb-0 text-left">Bio</p>
                </div>
                <div className="col">
                  <p className="text-left"> {this.state.userData.bio}</p>
                </div>
              </div>
              <hr className="col hrprofile" />
              <div className="row">
                <div className="col  col-md-3">
                  <p className="mb-0 text-left">Education</p>
                </div>
                <div className="col ">
                  <p className="text-left"> {this.state.userData.education}</p>
                </div>
              </div>
              <hr className="col hrprofile" />
              <div className="row">
                <div className="col  col-md-3">
                  <p className="mb-0 text-left">Goals</p>
                </div>
                <div className="col">
                  {this.state.instructorGoals.map((goal) => {
                    return (
                      <p className="text-left" key={goal}>
                        {goal}
                      </p>
                    );
                  })}
                </div>
              </div>
              <hr className="col hrprofile" />
              <div className="row">
                <div className="col  col-md-3">
                  <p className="mb-0 text-left">Linkedin</p>
                </div>
                <div className="col">
                  <p className="text-left">
                    {" "}
                    {this.state.userData?.socialmedia?.linkedin}
                  </p>
                </div>
              </div>
              <hr className="col hrprofile" />
              <div className="row">
                <div className="col  col-md-3">
                  <p className="mb-0 text-left">Youtube</p>
                </div>
                <div className="col ">
                  <p className="text-left">
                    {" "}
                    {this.state.userData?.socialmedia?.yutube}
                  </p>
                </div>
              </div>
              <hr className="col hrprofile" />
              <div className="row">
                <div className="col  col-md-3">
                  <p className="mb-0 text-left">Website</p>
                </div>
                <div className="col">
                  <p className="text-left">
                    {" "}
                    {this.state.userData?.socialmedia?.website}
                  </p>
                </div>
              </div>
              <hr className="col hrprofile" />
              <div className="row">
                <div className="col  col-md-3">
                  <p className="mb-0 text-left">Twitter</p>
                </div>
                <div className="col ">
                  <p className="text-left">
                    {" "}
                    {this.state.userData?.socialmedia?.twitter}
                  </p>
                </div>
              </div>
              <br />
              <button
                type="button"
                className="btn btn-primary  btn-sm"
                onClick={() => this.editProfile()}
                data-whatever="@getbootstrap"
              >
                Edit Profile
              </button>
            </div>
          </div>
        </div>

        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Edit Profile
                </h5>
                {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button> */}
              </div>
              <div className="modal-body">
                <form className="container">
                  <div className="row">
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="recipient-name"
                          className="col-form-label"
                        >
                          First Name:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          id="recipient-name"
                          defaultValue={this.state.userData["first_name"] || ""}
                          // onChange={this.profileChange.bind(this,  "first_name")}
                        />
                      </div>
                      <span className="mb-2" style={{ color: "red" }}>
                        {this.state.errors["first_name"]}
                      </span>
                    </div>
                    <div className="col-6">
                      <div className="form-group">
                        <label
                          htmlFor="recipient-lname"
                          className="col-form-label "
                        >
                          Last Name:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          id="recipient-lname"
                          defaultValue={this.state.userData["last_name"] || ""}
                          // onChange={this.profileChange.bind(this, "last_name")}
                        />
                      </div>
                      <span className="mb-2" style={{ color: "red" }}>
                        {this.state.errors["last_name"]}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-2 ccode">
                      <div className="form-group">
                        <label htmlFor="recipient-ccode">Contry Code</label>
                        <select
                          className="form-control "
                          id="ccode"
                          name="ccode"
                          onChange={this.setContryCode}
                        >
                          <option value={this.state.ccode}>
                            {" "}
                            {this.state.ccode}
                          </option>
                          {this.state.contrycodes.countries.map((item, i) => {
                            return (
                              <option key={i} value={item.code}>
                                {item.code}-{item.name}{" "}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                    <div className="col-5 ">
                      <div className="form-group">
                        <label
                          htmlFor="recipient-phone"
                          className="col-form-label"
                        >
                          Phone:
                        </label>
                        <input
                          type="text"
                          maxLength="12"
                          className="form-control"
                          id="recipient-phone"
                          value={this.state.phone}
                          // onChange={this.profileChange.bind(this, "phone")}
                          onChange={this.setPhoneNo}
                        />
                      </div>
                      <span className="mb-2" style={{ color: "red" }}>
                        {this.state.errors["mobile"]}
                      </span>
                    </div>
                    <div className="col-5 ">
                      <div className="form-group">
                        <label
                          htmlFor="recipient-email"
                          className="col-form-label"
                        >
                          Email:
                        </label>
                        <input
                          type="email"
                          className="form-control"
                          disabled
                          id="recipient-email"
                          defaultValue={this.state.userData["email"] || ""}
                        />
                      </div>
                      <span className="mb-2" style={{ color: "red" }}>
                        {this.state.errors["email"]}
                      </span>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label
                          htmlFor="recipient-bio"
                          className="col-form-label"
                        >
                          Bio:
                        </label>
                        <textarea
                          type="text"
                          maxLength="500"
                          className="form-control"
                          id="recipient-bio"
                          defaultValue={this.state.bio || ""}
                          // onChange={this.profileChange.bind(this, "bio")}
                          onChange={this.setBio}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-12">
                      <div className="form-group">
                        <label
                          htmlFor="recipient-education"
                          className="col-form-label"
                        >
                          Education:
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="recipient-education"
                          defaultValue={this.state.education || ""}
                          onChange={this.setEducation}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-7">
                      <div className="InterestSelect">
                        <label
                          htmlFor="recipient-name"
                          className="col-form-label"
                        >
                          Subject of Interest
                        </label>
                        <DualListBox
                          alignActions="top"
                          canFilter
                          icons={{
                            moveLeft: <FaAngleLeft />,
                            moveAllLeft: <FaAngleDoubleLeft />,
                            moveRight: <FaAngleRight />,
                            moveAllRight: <FaAngleDoubleRight />,
                            moveDown: <FaAngleDown />,
                            moveUp: <FaAngleUp />,
                          }}
                          options={this.state.options}
                          selected={selected}
                          onChange={this.onChange}
                        />
                      </div>
                    </div>
                    <div className="col-5">
                      <label
                        htmlFor="recipient-name"
                        className="col-form-label"
                      >
                        Social Media:
                      </label>

                      <div className="form-group row">
                        <h6>
                          <FaGlobe />
                        </h6>
                        <div className="col">
                          <input
                            type="website"
                            className="form-control form-control-sm"
                            id="colFormLabelSm"
                            defaultValue={this.state.social["website"] || ""}
                            onChange={this.setSocial.bind(this, "website")}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <h6>
                          <FaLinkedinIn />
                        </h6>
                        <div className="col">
                          <input
                            type="linkedIn"
                            className="form-control form-control-sm"
                            id="colFormLabelSm"
                            defaultValue={this.state.social["linkedin"] || ""}
                            onChange={this.setSocial.bind(this, "linkedin")}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <h6>
                          <FaTwitter />
                        </h6>
                        <div className="col">
                          <input
                            type="twitter"
                            className="form-control form-control-sm"
                            id="colFormLabelSm"
                            defaultValue={this.state.social["twitter"] || ""}
                            onChange={this.setSocial.bind(this, "twitter")}
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <h6>
                          <FaYoutube />
                        </h6>
                        <div className="col">
                          <input
                            type="youtube"
                            className="form-control form-control-sm"
                            id="colFormLabelSm"
                            defaultValue={this.state.social["yutube"] || ""}
                            onChange={this.setSocial.bind(this, "yutube")}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary  btn-sm"
                  onClick={() => {
                    this.editedProfilesubmit();
                  }}
                >
                  Update
                </button>
              </div>
            </div>
          </div>
        </div>

        <div
          className={`modal ${showModal ? "fade show" : ""}`}
          tabIndex="-1"
          role="dialog"
        >
          <div className="modal-dialog modal-dialog-centered" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Upload Image</h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={handleClose}
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label htmlFor="exampleFormControlFile1">
                      Select image to import
                    </label>
                    <input
                      type="file"
                      onChange={this.handleImageChange}
                      className="form-control-file"
                      id="exampleFormControlFile1"
                    />
                  </div>
                </form>
                <div className="mt-1 mb-1">
                  {image && (
                    <AvatarEditor
                      image={image}
                      width={250}
                      height={250}
                      border={50}
                      color={[255, 255, 255, 0.6]}
                      scale={1.2}
                      rotate={0}
                    />
                  )}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-primary btn-sm"
                  onClick={this.handleUploadClick}
                >
                  Upload
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InstructorProfile;
