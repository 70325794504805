import React from "react";
import "./Style.css";
import { Component } from "react";
import axios from "axios";
import swal from "sweetalert";

import Loader from "react-loader-spinner";
import Sidebar from "../../Sidebar";
import DOMPurify from "dompurify";

export default class ExamReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      enrollment_id: sessionStorage.getItem("enrollment_id"),
      report: {},
      x: 0,
      y: 0,
      result_id: "",
      examDetails: {},
      questionSet: [],
      answerSet: [],
      wrongAnswered: [],
      skippedQuestions: [],
      firstSet: [],
      hours: 0,
      minutes: 0,
      seconds: 0,
    };

    this.getExam();
  }

  componentDidMount() {
    this.getResultId();
    this.setState({ loading: true });
    this.checkUser();
  }

  getResultId() {
    let search = new URLSearchParams(this.props.location.search);
    if (search) {
      let result_id = search.get("result_id");
      if (result_id) {
        this.setState({ result_id });
        this.getExamResult(result_id);
        // this.getDetailedExam(enrollment_id);
      }
    }
  }

  getExam() {
    let enrollment_id = this.state.enrollment_id;
    axios({
      method: "Get",
      url: "/api/students/results/enrollments/" + enrollment_id,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      let exam = response.data.results[0];
      let examId = exam.exam_id;
      axios({
        method: "GET",
        headers: {
          ContentType: "application/json",
        },
        url: "/api/active-exams/" + examId,
      })
        .then((response) => {
          this.setState({
            loading: false,
          });
          this.setState({ examDetails: response.data });
        })
        .catch((error) => {
          console.log(error.response.data.errors[0].message);
        });
    });
  }

  getDetailedExam(enrollment_id) {
    let wrongAnswered = this.state.wrongAnswered;
    let skippedQuestions = this.state.skippedQuestions;
    axios({
      method: "GET",
      headers: {
        ContentType: "application/json",
      },
      url: "/api/exams/enrollments/" + enrollment_id + "?key=yes",
    })
      .then((response) => {
        let questionSet = response.data.exam_questions;
        let answerSet = response.data.answer_keys;
        questionSet.forEach((element) => {
          // let flag = element.hasOwnProperty('status');
          // if (flag === false) {
          //     element.status = "Correct";
          // }
          element.status = "Correct";
          skippedQuestions.forEach((wrong) => {
            // questionSet.forEach(element => {
            if (wrong.question_number === element.question_number) {
              element.status = "Unattempted";
              // console.log(element);
            }
          });
          // });
          wrongAnswered.forEach((wrong) => {
            // questionSet.forEach(element => {
            if (wrong.question_number === element.question_number) {
              element.selected_answer = wrong.selected_answer;
              element.status = "Incorrect";
              //console.log(element);
            }
            // });
          });
          answerSet.forEach((ans) => {
            if (ans.question_number === element.question_number) {
              element.correct_answer = ans.correct_answer;
              element.reason = ans.reason;
            }
          });
        });
        this.setState({ questionSet });
        this.setState({ firstSet: questionSet });
        this.setState({ answerSet });

        const sec = parseInt(this.state.report.run_duration, 10); // convert value to number if it's string
        let hours = Math.floor(sec / 3600); // get hours
        let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
        let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
        // add 0 if value < 10; Example: 2 => 02
        if (hours < 10) {
          hours = "0" + hours;
        }
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        if (seconds < 10) {
          seconds = "0" + seconds;
        }

        this.setState({ hours: hours, minutes: minutes, seconds: seconds });
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  correctAnswers = () => {
    let newArray = [];
    let newQuestionset = this.state.firstSet;
    newQuestionset.forEach((element) => {
      if (element.status === "Correct") {
        newArray.push(element);
      }
    });
    this.setState({ questionSet: newArray });
  };

  incorrectAnswers = () => {
    let newArray = [];
    let newQuestionset = this.state.firstSet;
    newQuestionset.forEach((element) => {
      if (element.status === "Incorrect") {
        newArray.push(element);
      }
    });
    this.setState({ questionSet: newArray });
  };

  skippedAnswers = () => {
    let newArray = [];
    let newQuestionset = this.state.firstSet;
    newQuestionset.forEach((element) => {
      if (element.status === "Unattempted") {
        newArray.push(element);
      }
    });
    this.setState({ questionSet: newArray });
  };

  allAnswers = () => {
    let array = this.state.firstSet;
    this.setState({ questionSet: array });
  };

  getExamResult(result_id) {
    let enrollment_id = this.state.enrollment_id;
    axios({
      method: "Get",
      url:
        "/api/students/results/enrollments/" +
        enrollment_id +
        "?result_id=" +
        result_id,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      let report = response.data.results;
      let wrongAnswered = report.wrong_answers;
      let skippedQuestions = report.questions_skipped;
      this.setState({ report });
      this.setState({ wrongAnswered });
      this.setState({ skippedQuestions });
      let run_score = report.run_score;
      let total_score = report.total_score;
      let x = (run_score / total_score) * 100;
      this.setState({ x });
      let y = 100 - x;
      this.setState({ y });
      this.getDetailedExam(enrollment_id);
    });
  }
  studentprofile() {
    this.props.history.push("/studentprofile");
  }

  enrolledexams() {
    this.props.history.push("/enrolled-exams");
  }

  checkUser() {
    axios({
      method: "GET",
      url: "/api/users/currentuser",
    })
      .then((response) => {
        if (response.data.current_user === null) {
          swal("Please login first");
          this.props.history.push("/signin");
        } else {
          console.log("logged in");
        }
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  changeShowStatus(question) {
    let questionList = [];
    this.state.questionSet.forEach((element) => {
      if (question.question_number === element.question_number) {
        element.showHideStatus = !question.showHideStatus;
      }
      questionList.push(element);
    });
    this.setState({ questionList });
  }

  render() {
    if (this.state.loading) {
      return (
        <Loader
          className="text-center"
          type="Puff"
          color="#00BFFF"
          height={500}
          width={100}
          timeout={5000}
        />
      );
    } else {
      return (
        <div>
          <div className="container">
            <div className="report--header">
              <h6 className="text-center mt-3">
                {this.state.examDetails.exam_title}:&nbsp;Exam Review
              </h6>
            </div>
            <br></br>
            <div className="row">
              <Sidebar />
              <div className="reports-details col-lg-10 col-md-12 col-sm-12 col-xs-12 my-1">
                <div className="row ">
                  {/* <div className="col-xs-0 col-sm-0 col-md-1 "></div> */}
                  <div className="col-xs-4 col-sm-4 col-md-2 ">
                    <img
                      className="report-images"
                      src="images/aim.png"
                      alt="attempts"
                    />
                    <br />
                    Attempts
                    <br />
                    {this.state.report.attempt}
                  </div>
                  <div className="col-xs-4 col-sm-4 col-md-3 ">
                    <img
                      className="report-images"
                      src="images/bar.png"
                      alt="graph"
                    />
                    <br />
                    Marks Obtained
                    <br />
                    {this.state.report.run_score.toFixed(2)}/
                    {this.state.report.total_score}
                  </div>
                  <div className="col-xs-4 col-sm-4 col-md-2 ">
                    <img
                      className="report-images"
                      src="images/checklist.png"
                      alt="checklist"
                    />
                    <br />
                    Your Score
                    <br />
                    <i>{this.state.x.toFixed(2)}%</i>
                  </div>
                  <div className="col-xs-4 col-sm-4 col-md-2 ">
                    <img
                      className="report-images"
                      src="images/watch.png"
                      alt="watch"
                    />
                    <br />
                    Time Taken
                    <br />
                    {this.state.hours} H&nbsp;
                    {this.state.minutes} M&nbsp;
                    {this.state.seconds} S
                  </div>
                  <div className="col-xs-4 col-sm-4 col-md-3 ">
                    <img
                      className="report-images"
                      src="images/report.png"
                      alt="report"
                    />
                    <br />
                    Result
                    <br />
                    {this.state.report.status}
                  </div>
                  <div className="col-xs-0 col-sm-0 col-md-1 "></div>
                </div>
                <br></br>
                <div className="container question-review">
                  <div className="row review-filter text-center">
                    <br></br>
                    <div className="col-md-10">
                      <h6 className="text mt-2">Review Answers</h6>
                    </div>
                    <div className="col-md-2 sort-text">
                      <div className="dropdown">
                        <button
                          className="btn btn-primary dropdown-toggle"
                          type="button"
                          id="dropdownMenu2"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          Sort
                        </button>
                        <div
                          className="dropdown-menu"
                          aria-labelledby="dropdownMenu2"
                        >
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => {
                              this.correctAnswers();
                            }}
                          >
                            Correct
                          </button>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => {
                              this.incorrectAnswers();
                            }}
                          >
                            Incorrect
                          </button>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => {
                              this.skippedAnswers();
                            }}
                          >
                            Unattempted
                          </button>
                          <button
                            className="dropdown-item"
                            type="button"
                            onClick={() => {
                              this.allAnswers();
                            }}
                          >
                            All
                          </button>
                        </div>
                        {/* </div> */}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="container">
                  {this.state.questionSet.map((question, i) => {
                    return (
                      <div
                        key={i}
                        className={
                          "card text-center my-4 " +
                          (question.status === "Incorrect" ? "Incorrect" : "") +
                          (question.status === "Correct" ? "Correct" : "") +
                          (question.status === "Unattempted"
                            ? "Unattempted"
                            : "")
                        }
                      >
                        <div className="card-body">
                          {/* <blockquote className="blockquote mb-0"> */}
                          Your Answer: {question.status}
                          <br />Q No:{" "}
                          <p
                            className="d-inline"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                question.question_number
                              ),
                            }}
                          />
                          .&nbsp; &nbsp;
                          <p
                            className="d-inline"
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(question.question),
                            }}
                          />
                          {/* </blockquote> */}
                          <div className="row">
                            <div className="col md-6">
                              A:{" "}
                              <p
                                className="d-inline"
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(
                                    question.answer_choice.a
                                  ),
                                }}
                              />
                              {/* <b>{question.answer_choice.a}</b> */}
                            </div>
                            <div className="col md-6">
                              B:{" "}
                              <p
                                className="d-inline"
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(
                                    question.answer_choice.b
                                  ),
                                }}
                              />
                              {/* <b>{question.answer_choice.b}</b> */}
                            </div>
                          </div>
                          <div className="row">
                            {question.answer_choice.c && (
                              <div className="col md-6">
                                C:{" "}
                                <p
                                  className="d-inline"
                                  dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                      question.answer_choice.c
                                    ),
                                  }}
                                />
                              </div>
                            )}
                            {question.answer_choice.d && (
                              <div className="col md-6">
                                D:{" "}
                                <p
                                  className="d-inline"
                                  dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                      question.answer_choice.d
                                    ),
                                  }}
                                />
                              </div>
                            )}
                          </div>
                          <div className="row">
                            {question.answer_choice.e == null && (
                              <div className="col md-6">
                                E:{" "}
                                <p
                                  className="d-inline"
                                  dangerouslySetInnerHTML={{
                                    __html: DOMPurify.sanitize(
                                      question.answer_choice.e
                                    ),
                                  }}
                                />
                              </div>
                            )}
                          </div>
                          <div className="row">
                            {question.selected_answer ? (
                              <div className="col md-6">
                                Selected Answer:
                                <span className="badge badge-failure text-capitalize">
                                  Option {question.selected_answer}
                                </span>
                              </div>
                            ) : (
                              <></>
                            )}
                            {question.status === "Correct" ? (
                              <div className="col md-6">
                                Selected Answer:
                                <span className="badge badge-success text-capitalize">
                                  Option {question.correct_answer}
                                </span>
                              </div>
                            ) : (
                              <></>
                            )}

                            <div className="col md-6">
                              Correct Answer:{" "}
                              <span className="badge badge-pill badge-success text-capitalize">
                                Option {question.correct_answer}
                              </span>
                            </div>
                          </div>
                          {question.reason ? (
                            <div className="text-center">
                              <p>
                                <button
                                  className="btn btn-primary"
                                  type="button"
                                  data-toggle="collapse"
                                  onClick={() => {
                                    this.changeShowStatus(question);
                                  }}
                                  data-target={
                                    "#multiCollapseExample" +
                                    question.question_number
                                  }
                                  aria-expanded="false"
                                  aria-controls={"multiCollapseExample"}
                                >
                                  {question?.showHideStatus &&
                                  question?.showHideStatus === true
                                    ? "Hide"
                                    : "Show"}{" "}
                                  Explanation
                                </button>
                              </p>
                              <div
                                className="collapse multi-collapse"
                                id={
                                  "multiCollapseExample" +
                                  question.question_number
                                }
                              >
                                <div className="card h-100 card-body">
                                  <p
                                    dangerouslySetInnerHTML={{
                                      __html: DOMPurify.sanitize(
                                        question.reason
                                      ),
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                            <></>
                          )}
                          {/* <a href="#" className="btn btn-primary">Go somewhere</a> */}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    }
  }
}
