import React, { Component } from "react";
import axios from "axios";
import Loader from "react-loader-spinner";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import AppConstants from "../../../Constants/AppConstants";
import "./CategoryPage.css";
import Sidebar from "../../Sidebar";
import { Fade } from "react-awesome-reveal";

class CategoryPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userData: JSON.parse(sessionStorage.getItem("userData")),
      usersType: sessionStorage.getItem("userType"),
      isRegistered: sessionStorage.getItem("isInstructor"),
      instructorRedirect: false,
      instructorList: [],
      instructor: {},
      finalInstructors: [],
      final: [],
      examsList: [],
      loading: true,
      verified: false,
      image: AppConstants.IMG_URL,
      cat: {},
      loginStatus: false,
    };
    this._isMounted = false; // Initialize _isMounted to false in the constructor
  }
  componentDidMount() {
    // Use an instance variable to track component unmounting
    this._isMounted = true; // Set the instance variable to true when the component mounts

    // Check user asynchronously
    this.checkUser()
      .then(() => {
        if (this.state.userData) {
          let search = new URLSearchParams(this.props.location.search);
          if (search) {
            let catId = search.get("categoryId");
            if (catId) {
              return Promise.all([this.getCat(catId), this.getExams(catId)]);
            }
          }
        }
        return Promise.resolve();
      })
      .then(() => {
        if (this._isMounted) {
          if (this.state.userData) {
            this.setState({ loginStatus: true });
          } else {
            this.setState({ loginStatus: false });
          }
        }
      })
      .catch((error) => {
        if (this._isMounted) {
          console.log(error.message);
        }
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async checkUser() {
    console.log("gggggggggggggggggggggggg");
    try {
      const response = await axios.get("/api/users/currentuser");

      if (this._isMounted) {
        if (response.data.current_user === null) {
          // Check if the component is still mounted
          this._isMounted && this.props.history.push("/signin");
        } else {
          console.log(JSON.stringify(response.data));
        }
      }
    } catch (error) {
      if (this._isMounted) {
        console.log(error.response.data.errors[0].message);
      }
    }
  }

  async getCat(catId) {
    // Create a cancel token source
    const source = axios.CancelToken.source();
    axios({
      method: "GET",
      url: "/api/catagories/" + catId,
      cancelToken: source.token,
    })
      .then((response) => {
        let cat = response.data;
        this.setState({ cat });
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
    // Register the cancel token with a unique name
    this.cancelTokens.catId = source;
  }

  async getExams(catId) {
    // Create a cancel token source
    const source = axios.CancelToken.source();
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: "/api/active-exams?catagory_id=" + catId,
      cancelToken: source.token,
    })
      .then((response) => {
        this.setState({ loading: false });
        let examsList = response.data.exams;
        this.setState({ examsList });
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
    // Register the cancel token with a unique name
    this.cancelTokens.catId = source;
  }

  async getExam(exam_id) {
    this.props.history.push("/exampage?examId=" + exam_id);
  }

  // Define an object to store cancel tokens
  cancelTokens = {
    catId: null,
    examsCatId: null,
  };

  render() {
    if (this.state.loading) {
      return (
        <Loader
          className="text-center"
          type="Puff"
          color="#00BFFF"
          height={500}
          width={100}
          timeout={5000}
        />
      );
    }
    return (
      <div className="container">
        <br></br>
        <div className="row">
          <Sidebar />

          <div className="conatiner col-lg-10 col-md-10 col-sm-12 col-xs-12">
            <div className="repo-cards-div-main">
              {this.state.examsList.map((exam, i) => {
                return (
                  <div key={i} className="repo-card-div">
                    <Fade bottom duration={2000} distance="40px">
                      <div>
                        <div
                          key={i + "key"}
                          onClick={() => {
                            this.getExam(exam.exam_id);
                          }}
                        >
                          <div className="repo-name-div">
                            <object
                              data="/images/test-icon.svg"
                              width="25"
                              height="25"
                            >
                              {" "}
                            </object>
                            <p className="repo-name"> {exam.exam_title}</p>
                          </div>
                          <p className="repo-description">
                            {/* {exam.exam_subtitle} */}
                            {exam.exam_desc}
                          </p>
                          <div className="repo-details">
                            <p className="repo-duration">
                              Questions: {exam.number_of_questions}
                            </p>
                            <p className="repo-duration">
                              Time: {exam.exam_duration} Mins
                            </p>
                            <p className="repo-duration">
                              Marks: {exam.total_exam_score}
                            </p>
                            {/* Created on {repo.createdAt.split("T")[0]} */}
                          </div>
                        </div>
                        <div className="repo-details">
                          <p className="repo-creation-date">
                            <li className="author">
                              <a
                                title="Exam Creator"
                                href={
                                  "/instructor-page?instructorId=" +
                                  exam.exam_creator_id
                                }
                              >
                                {" "}
                                <object
                                  data="/images/teacher-mono.svg"
                                  width="25"
                                  height="25"
                                >
                                  {" "}
                                </object>{" "}
                                {/* {exam.exam_creator_name.first_name}{" "} */}
                              </a>
                            </li>
                          </p>
                          <p className="repo-duration">
                            Mode: {exam.exam_mode}
                          </p>
                          {exam.exam_type === "multiple_choice" ? (
                            <p className="repo-duration">Type: Objective </p>
                          ) : (
                            <p className="repo-duration">Type: Subjective </p>
                          )}
                          <p className="repo-languages ">
                            {" "}
                            <a
                              title="Your Attempt History"
                              href={
                                "/exam-history?enrollmentId=" +
                                exam.enrollment_id
                              }
                            >
                              <object
                                data="/images/exam-history-blue.svg"
                                width="25"
                                height="25"
                              ></object>
                            </a>
                          </p>
                        </div>
                      </div>
                    </Fade>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CategoryPage;

/* In this code:

We create a cancel token source for each Axios request in the getCat and getExams methods.

We set the cancel token source in the request's configuration using cancelToken: source.token.

We register each cancel token source in the cancelTokens object with unique names, such as catId and examsCatId.

In the componentWillUnmount method, we can access the cancel token sources in the cancelTokens object to cancel any 
ongoing requests associated with the component. This prevents memory leaks and unwanted state updates on an unmounted component.

I hope this helps you resolve the issue. */
