import axios from "axios";

import React, { Component } from "react";
import swal from "sweetalert";
import Sidebar from "../Sidebar";
import "./Mail.css";

export default class Mail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showMessage: [],
      newMessageflag: false,
      messagesHistory: [],
      subject: "",
      message: "",
      examId: "",
      user: {},
      str: "",
    };
  }

  componentDidMount() {
    let user = JSON.parse(sessionStorage.getItem("userData"));
    this.setState({ user });
    this.checkUser();
    let search = new URLSearchParams(this.props.location.search);

    if (search) {
      let examId = search.get("examId");
      if (examId) {
        this.getExamChat(examId);
        this.setState({ examId });
      } else {
        console.log("No chat Found");
      }
    }
  }

  checkUser() {
    axios({
      method: "GET",
      url: "/api/users/currentuser",
    })
      .then((response) => {
        if (response.data.current_user === null) {
          //    swal("Please login first")
          this.props.history.push("/signin");
        } else {
          console.log("logged in");
        }
      })
      .catch((error) => {
        swal(error.response.data.errors[0].message);
      });
  }

  getExamChat(examId) {
    this.getChat(examId);
  }

  // *************** CHAT FUNCTIONS ****************

  addMessage = (message) =>
    this.setState((state) => ({
      showMessage: [message, ...state.showMessage],
    }));

  replyChat(exam_id) {
    axios({
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      data: this.state.dataMessage,
      url: "/api/examstore/" + exam_id + "/conversations",
    })
      .then(() => {
        console.log("message sent");
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  readChat(exam_id) {
    axios({
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      data: this.setState.newMessage,
      url: "/api/examstore/" + exam_id + "/conversations",
    })
      .then(() => {
        console.log("message sent");
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  getChat(examId) {
    axios({
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
      url: "/api/examstore/" + examId + "/conversations?index=yes",
    })
      .then((response) => {
        let data = response.data.conversation;
        this.setState({ messagesHistory: data });
        if (data.length <= 0) {
          this.setState({ newMessageflag: true });
        } else {
          let show = [];
          let data = this.state.messagesHistory;
          data.forEach((element) => {
            let utcDate = element.date;
            let localDate = new Date(utcDate);
            let string = localDate.toString();
            let length = 24;
            var date = string.substring(0, length);
            let conv_id = element._id;
            let int = conv_id.toString();
            let len = 17;
            var str = int.substring(len, 24);
            this.setState({ str });
            let object = {
              // "subject": element.subject,
              no_of_messages: element.no_of_messages,
              date: date,
              conversation: element._id,
              cons: str,
              status: element.status,
            };
            show.push(object);
            this.setState({ showMessage: show });
          });
        }
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  goToMail(conv_id) {
    this.props.history.push("/mail?conv_id=" + conv_id);
  }

  setSubject = (e) => {
    this.setState({ subject: e.target.value });
  };

  setMessage = (e) => {
    this.setState({ message: e.target.value });
  };

  startChat() {
    let examId = this.state.examId;
    let user = this.state.user;
    // this.ws.send(JSON.stringify(message))
    // this.addMessage(message)
    let msg = {};
    let subject = this.state.subject;
    let sender = {
      id: user.user_id,
      email: user.email,
      first_name: user.first_name,
      last_name: user.last_name,
      type: "instructor",
    };
    let reciver = {
      type: "admin",
    };
    let message = this.state.message;
    msg.message = message;
    msg.subject = subject;
    msg.direction = "i2a";
    msg.from = sender;
    msg.to = reciver;
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      data: msg,
      url: "/api/examstore/" + examId + "/conversations",
    })
      .then(() => {
        console.log(
          "Query Submited, Administration team will be in touch with you shortly. Thank you"
        );
        window.location.reload();
      })
      .catch((error) => {
        console.log(error.response.data.errors[0].message);
      });
  }

  render() {
    return (
      <div className="container-fluid">
        <div className="row">
          <Sidebar />

          {this.state.newMessageflag === true ? (
            <div className="row noMessage text-center">
              <div className="col-4 mob-width"></div>
              <div className="col-4 mt-1 mb-1 mob-width">
                <div className="row alert alert-primary mb-1">
                  <p className="message mb-1 mt-1 mob-alert">
                    No conversation history related to the current exam
                  </p>
                </div>

                <button
                  type="button"
                  className="btn btn-primary startChat mr-1 mt-2 ml-1"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  Start Conversation
                </button>
              </div>
              <div className="col-4 mob-width"></div>
            </div>
          ) : (
            <div className="col-md-9 mx-auto">
              <div className="my-2 text-center">
                <button
                  type="button"
                  className="btn btn-primary startChat mr-1 mt-2 ml-1"
                  data-toggle="modal"
                  data-target="#exampleModal"
                >
                  Start Conversation
                </button>
              </div>

              <div className="col-md-12">
                <table className="table">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Interaction Id</th>
                      {/* <th scope="col">Subject</th> */}
                      <th scope="col">Date</th>
                      <th scope="col">Status</th>
                      <th scope="col"> Messages</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.showMessage.map((mail) => {
                      return (
                        <tr key={mail.date}>
                          <td>{mail.cons}</td>
                          {/* <td>{mail.subject}</td> */}
                          <td>{mail.date}</td>
                          <td>{mail.status}</td>
                          <td>{mail.no_of_messages}</td>
                          <td>
                            <button
                              className="btn btn-primary mr-1"
                              onClick={() => {
                                this.goToMail(mail.conversation);
                              }}
                            >
                              Read
                            </button>
                            <button className="btn btn-primary">Delete</button>
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </div>
          )}
        </div>

        {/* ********Modal********* */}

        <div
          className="modal fade"
          id="exampleModal"
          tabIndex="-1"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog" role="document">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  New message
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close"
                >
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
                <form>
                  <div className="form-group">
                    <label htmlFor="recipient-name" className="col-form-label">
                      Subject
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="subject"
                      onChange={this.setSubject.bind(this)}
                    />
                  </div>
                  <div className="form-group">
                    <label htmlFor="message-text" className="col-form-label">
                      Message:
                    </label>
                    <textarea
                      className="form-control"
                      id="message-text"
                      onChange={this.setMessage.bind(this)}
                    ></textarea>
                  </div>
                </form>
              </div>
              <div className="modal-footer">
                {/* <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button> */}
                <button
                  type="button"
                  className="btn btn-primary"
                  data-dismiss="modal"
                  onClick={() => {
                    this.startChat();
                  }}
                >
                  Send message
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
