import React, { Component } from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import "./Style.css";
import { toast } from "react-toastify";
import $ from "jquery";
import swal from "sweetalert";

export default class VerifyUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      validation_token: "",
      Redirect: false,
      user: {},
      user_id: "",
      channel: "",
    };
  }

  componentDidMount() {
    let user = JSON.parse(sessionStorage.getItem("userData"));
    let search = new URLSearchParams(this.props.location.search);
    if (search) {
      let id = search.get("id");
      let channel = search.get("channel");
      let validation_token = search.get("token");
      if (id) {
        this.VerifyUserWithLink(id, channel, validation_token);
      } else {
        this.setState({ user });
        let user_id = user.user_id;
        this.setState({ user_id });
      }
    }
  }

  VerifyUserWithLink(id, channel, validation_token) {
    if (!id) {
      id = this.state.user_id;
    }
    const verificationData = {
      validation_token: validation_token,
      user_id: id,
      channel: channel,
    };
    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: "/api/users/validations",
      data: verificationData,
    })
      .then((response) => {
        let res = response.data;
        if (res && res.message === "successfully verified") {
          this.getUser();
          const { hide } = console.log("Details Verified Successfully", {
            hideAfter: 5,
            onClick: () => {
              hide();
            },
          });
          $("#forgotpassword_modal").modal("hide");
        } else {
          toast.error("Token expired, please login again");
        }
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].message);
      });
  }

  VerifyUser = () => {
    let user_id = this.state.user_id;
    let validationToken = this.state.validation_token;
    if (!user_id) {
      let search = new URLSearchParams(this.props.location.search);
      if (search) {
        user_id = search.get("id");
        this.setState({ user_id });
      }
    }
    // Validate the validation token
    if (!validationToken || validationToken.length === 0) {
      // Prevent the form from submitting
      return;
    }
    const verificationData = {
      validation_token: this.state.validation_token,
      user_id: user_id,
      channel: "email",
    };
    axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: "/api/users/validations",
      data: verificationData,
    })
      .then((response) => {
        let res = response.data;
        if (res && res.message === "successfully verified") {
          this.getUser();
        } else {
          toast.error("Token expired, please verify with email again");
        }
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].message);
      });
  };

  setValidationToken = (e) => {
    this.setState({ validation_token: e.target.value });
  };

  getUser() {
    axios({
      method: "GET",
      url: "/api/users/profile",
    })
      .then((response) => {
        let res = response.data;
        if (res && res.user_id && res.email && res.is_email_verified) {
          sessionStorage.setItem("userData", JSON.stringify(res));
          sessionStorage.setItem("userType", "student");
          sessionStorage.setItem("isInstructor", false);
          $("#forgotpassword_modal").modal("hide");
          swal({
            title: "Thanks for email verification..!",
            icon: "success",
            buttons: true,
            dangerMode: false,
          }).then((willDelete) => {
            if (willDelete) {
              // swal("Verified Successfully", {
              //   icon: "success",
              // });
              this.setState({ Redirect: true });
              window.location.reload();
            } else {
              this.setState({ Redirect: true });
              window.location.reload();
            }
          });
        } else {
          console.log("User details not found, Please login again");
        }
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].message);
      });
  }

  notifyUser() {
    let data = {
      channel: "email",
    };
    axios({
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      url: "/api/users/tokens",
      data: data,
    })
      .then(() => {
        toast.success(
          "Please check your mail inbox, Link and token have been sent to your mail Id"
        );
      })
      .catch((error) => {
        toast.error(error.response.data.errors[0].message);
      });
  }

  render() {
    if (this.state.Redirect === true) {
      return <Redirect to={{ pathname: "/studentlanding" }} />;
    }
    return (
      <div>
        <div className="row">
          <div className="col-4"></div>
          <div className="col-4">
            <div className="card text-center cardUser mt-4">
              <div className="card-header">User verification</div>
              <div className="card-body">
                <div className="row mt-1 mb-1 userVerifyButtons">
                  <button
                    className="btn btn-primary"
                    data-toggle="modal"
                    data-target="#forgotpassword_modal"
                    onClick={() => {
                      this.notifyUser();
                    }}
                  >
                    Verify with email
                  </button>
                </div>
                <div className="row mt-1 mb-1 userVerifyButtons"></div>
              </div>
              <div className="card-footer text-muted"></div>
            </div>
          </div>
          <div className="col-4"></div>
        </div>
        <div className="mt-5 text center">
          <div
            className="modal fade"
            id="forgotpassword_modal"
            tabIndex={-1}
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h6 className="modal-title" id="exampleModalLabel">
                    please check your email for token
                  </h6>
                  <button
                    type="button"
                    className="close"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <span aria-hidden="true">×</span>
                  </button>
                </div>
                <div className="modal-body">
                  <label>Token</label>
                  <input
                    className="form-control"
                    type="email"
                    placeholder="Enter your token"
                    onChange={this.setValidationToken}
                    value={this.state.validation_token}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={this.VerifyUser}
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
