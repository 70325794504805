import React from "react";
import "./Style.css";
import { Component } from "react";
import { Link } from "react-router-dom";
import { Button } from "../../Button";
import axios from "axios";
import swal from "sweetalert";
import Loader from "react-loader-spinner";
import Moment from "moment";
import Sidebar from "../../Sidebar";

export default class ExamReport extends Component {
  constructor(props) {
    super(props);
    this.state = {
      examHistory: [],
      runinfo: {},
      loading: true,
      enrollment_id: "",
    };
  }

  componentDidMount() {
    this.checkUser();
  }

  checkUser() {
    axios({
      method: "GET",
      url: "/api/users/currentuser",
    })
      .then((response) => {
        if (response.data.current_user === null) {
          // swal("Please login first")
          this.props.history.push("/signin");
        } else {
          let search = new URLSearchParams(this.props.location.search);
          if (search) {
            let enrollmentId = search.get("enrollmentId");
            this.setState({ enrollment_id: enrollmentId });
            sessionStorage.setItem("enrollment_id", search.get("enrollmentId"));
            this.getExamHistory();
          }
        }
      })
      .catch((error) => {
        swal(error.response.data.errors[0].message);
        // console.log(error.response.data.errors[0].message);
      });
  }

  getExamHistory() {
    axios({
      method: "Get",
      url: "/api/students/results/enrollments/" + this.state.enrollment_id,
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      let examHistory = response.data.results;
      // moment.utc("2016-08-11 12:19:14").local().format("YYYY-MM-DD HH:mm:ss")
      examHistory.forEach((element) => {
        element.attempt_date = Moment.utc(element.attempt_date)
          .local()
          .format("DD-MM-YYYY HH:mm:ss");
      });
      this.setState({ examHistory });
      let paused_attempt = response.data.paused_attempt;
      if (paused_attempt && paused_attempt.runinfo) {
        let runinfo = paused_attempt.runinfo;
        runinfo.saved_date = Moment.utc(runinfo.saved_date)
          .local()
          .format("DD-MM-YYYY HH:mm:ss");
        this.setState({ runinfo });
      }
      this.setState({ loading: false });
      if (examHistory[0]) {
        axios({
          method: "GET",
          url: "/api/active-exams/" + examHistory[0].exam_id,
        }).then((response) => {
          let exam = response.data;
          this.setState({ exam });
        });
      }
    });
  }

  resumeExam(enrollment_id) {
    const { history } = this.props;
    history.push({
      pathname: `/exam`,
      search: `?enrollment=${enrollment_id}&type=resume&isPractice=true`,
      state: { isPractice: true }, // Include the state object
    });
  }

  render() {
    if (this.state.loading) {
      return (
        <Loader
          className="text-center"
          type="Puff"
          color="#00BFFF"
          height={500}
          width={100}
          timeout={5000}
        />
      );
    }
    return (
      <div>
        <div className="container screen-padding">
          <br></br>
          <div className="report--header">
            <h6>
              Exam History: {this.state.exam && this.state.exam.exam_title}
            </h6>
          </div>
          <div className="row">
            <Sidebar />
            <div className="col-md-10 table-report table-responsive">
              <div>
                {this.state.examHistory && this.state.examHistory[0] ? (
                  <Link
                    to={"/exampage?examId=" + this.state.examHistory[0].exam_id}
                    className="float-right"
                  >
                    <Button
                      className="text-right"
                      buttonSize="btn--wide"
                      buttonColor="green"
                    >
                      Re-Attempt
                    </Button>
                  </Link>
                ) : (
                  <div className="centered-message">
                    There is no exam history available. Exam History will be
                    populated after you attempt an exam
                  </div>
                )}
              </div>
              {this.state.examHistory && this.state.examHistory[0] ? (
                <div className="row mt-5">
                  <table className="container table table-hover text-center">
                    <thead>
                      <tr className="table-active">
                        {/* <th scope="col">No.</th> */}
                        <th scope="col">Attempt</th>
                        <th scope="col">Total Marks</th>
                        <th scope="col">Marks Obtained</th>
                        <th scope="col">Attempt Date</th>
                        <th scope="col">Status</th>
                        <th scope="col">Exam Style</th>
                        <th scope="col">Result</th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.examHistory.map((domain, i) => {
                        return (
                          <tr className="table-light" key={i}>
                            {/* <th scope="row">{i + 1}</th> */}
                            <td>{domain.attempt}</td>
                            <td>{domain.total_score}</td>
                            <td>{domain.run_score.toFixed(3)}</td>
                            <td>{domain.attempt_date}</td>
                            <td>{domain.status}</td>
                            <td>{domain.exam_style}</td>
                            <td>
                              <Link
                                to={
                                  "/exam-report?result_id=" + domain.result_id
                                }
                              >
                                View
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>

                  {null != this.state.runinfo && this.state.runinfo.status ? (
                    <div className="w-100">
                      <div className="report--header">
                        <h6>Paused Exam</h6>
                      </div>
                      {/* <br /> */}
                      <div className=" table-report table-responsive">
                        <table className="table table-hover text-center">
                          <thead>
                            <tr className="table-active">
                              {/* <th scope="col">No.</th> */}
                              <th scope="col-md-10">Status</th>
                              {/* <th scope="col">Runtime Id</th> */}
                              <th scope="col-md-10">Saved Date</th>
                              <th scope="col-md-10">Resume</th>
                            </tr>
                          </thead>
                          <tbody>
                            {/* {this.state.examHistory.map((domain, i) => { */}
                            {/* return ( */}
                            <tr className="table-light">
                              {/* <th scope="row">{i + 1}</th> */}
                              <td>{this.state.runinfo.status}</td>
                              {/* <td>{this.state.runinfo.runtime_id}</td> */}
                              <td>{this.state.runinfo.saved_date}</td>
                              {/* <td><Link to={'/exam-report?result_id=' + this.state.runinfo.runtime_id}>Resume</Link></td> */}

                              {/* <td onClick={() => { this.resumeExam(this.state.enrollment_id) }}>Resume</td> */}
                              <td>
                                {" "}
                                <p
                                  className="resume-exam"
                                  onClick={() => {
                                    this.resumeExam(this.state.enrollment_id);
                                  }}
                                >
                                  Resume{" "}
                                </p>
                              </td>
                            </tr>
                            {/* ) */}
                            {/* })} */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  ) : (
                    <div className="centered-message"></div>
                  )}
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
