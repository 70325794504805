export const homeObjOne = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  scrollingheadlines: true,
  addgoals: true,
  topLine: " ",
  Sheadlines: [],
  headline: "Study anywhere but, Mock them here",
  description: "Elevate Your Exam Readiness",
  buttonLabel: "Start Mocking",
  imgStart: "",
  img: "images/home1.svg",
  alt: "Home",
  userData: JSON.parse(sessionStorage.getItem("userData")),
};

export const homeObjTwo = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "",
  headline: "Learn on the go",
  description:
    "Download lessons and learn anytime, anywhere with the Unacademy learning app",
  buttonLabel: "Download App",
  imgStart: "",
  img: "images/png-5.png",
  alt: "Vault",
};

export const homeObjThree = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "EASY SETUP",
  headline: "Get courses and mock exams created only for you",
  description:
    "In detailed Courses & mock exams for all kinds of streams, deesigned as per the your learning preferences.",
  buttonLabel: "Select goals",
  imgStart: "start",
  img: "images/png-3.png",
  alt: "Vault",
};

export const homeInstructorSectionData = {
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  topLine: "",
  headline: "Conduct Mock Exam Series",
  //headline1: 'Teach what you love',
  description:
    "We provide a platform to check how well your students are prepared for the exam",
  buttonLabel: "Conduct Exam",
  imgStart: "start",
  img: "images/home2.svg",
  alt: "Vault",
  userData: JSON.parse(sessionStorage.getItem("userData")),
};
